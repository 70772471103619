import { memo } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RichText from './RichText';
import Video from './Video';
import Links from './Links';
import ImageTextMixed from './ImageTextMixed';
import { ReactComponent as Derive1 } from '../../assets/images/derive-01.svg';
import { ReactComponent as Derive2 } from '../../assets/images/derive-02.svg';
import './Cms.scss';

const Cms = memo(props => {
  if (props.blocks.length === 0) return;

  const article = (
    <article className="cms">
      {props.blocks.map((block, index) => (
        <Block {...block} key={index} />
      ))}
      {props.noDerive !== true && <Derive1 className="cms-derive cms-derive--before" />}
      {props.noDerive !== true && <Derive2 className="cms-derive cms-derive--after" />}
    </article>
  );
  return props.col ? (
    <>
      <Row className="cms-row">
        <Col {...props.col}>
          {props.children}
          {article}
        </Col>
      </Row>
    </>
  ) : (
    article
  );
});

function switchContent(type, content) {
  const is = str => type.includes(str);

  return is('ckeditor_default_table') ? (
    <RichText defaultTable content={content.field_ckeditor} />
  ) : is('ckeditor_noborder_table') ? (
    <RichText noborderTable content={content.field_ckeditor} />
  ) : is('shipin_qianrudaima') ? (
    <Video iframe={content.field_iframe_code} />
  ) : is('image_text') ? (
    <ImageTextMixed
      title={content.field_title}
      imageOn={`${content.field_image_text_type === 'left_text_right_image' ? 'right' : 'left'}`}
      contents={content.field_image_text}
    />
  ) : is('links') ? (
    <Links contents={content.field_link_content} />
  ) : (
    ''
  );
}

function Block(props) {
  return (
    <div className={'cms-block cms-block--spacing-' + props.data.field_spacing}>
      {switchContent(props.type, props.data)}
    </div>
  );
}

export default Cms;
