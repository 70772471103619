import { memo, useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Form, Row, Col } from 'react-bootstrap';
import api from '../../utils/request';
import AuthContext from '../../store/AuthContext';
import PageTitleBar from '../../components/PageTitleBar';
import alert from '../../components/alert';
import InputTogglePassword from '../../components/form/InputTogglePassword';
import InputMessageCode from '../../components/form/InputMessageCode';
import LoadButton from '../../components/LoadButton';
import InvalidFeedback from '../../components/form/InvalidFeedback';

function ForgotPassword() {
  const location = useLocation();
  const nav = useNavigate();
  const authCtx = useContext(AuthContext);
  let [submitLoading, setSubmitLoading] = useState(false);
  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    setFocus,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (location?.state?.phoneNumber) setValue('user_login_id', location.state.phoneNumber);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const cNameIsInvalid = key => (errors[key] ? 'is-invalid' : '');

  async function getSmsCode() {
    const phoneNumberIsValid = await trigger('user_login_id', { shouldFocus: true });
    if (phoneNumberIsValid) {
      const phoneNumber = getValues('user_login_id');
      const trigger_element_id = getValues('trigger_element_id');
      const msg = await api.common
        .sendVerificationCode({
          data: {
            message_gateway_type: 'sms',
            recipient: `+86 ${phoneNumber}`,
            trigger_element_id,
          },
        })
        .catch(err => {
          return err.data;
        });
      setTimeout(() => setFocus('code'), 100);
      return msg;
    }
  }

  async function onSubmit(data) {
    setSubmitLoading(true);
    await api.user
      .resetPassword({
        data,
      })
      .then(data => {
        authCtx.facthLoginInfo();
        setTimeout(() => alert.success(data.message), 200);
        nav(-1);
      })
      .catch(err => requestError(err));
    setSubmitLoading(false);
  }

  function requestError(error) {
    if (error.data) {
      error.data.error_keys.forEach(key => {
        setError(key);
        alert.danger(error.message, key);
      });
    }
  }

  function toLogin(e) {
    e.preventDefault();
    const phoneNumber = getValues('user_login_id');
    nav('/user/login', { state: { phoneNumber }, replace: true });
  }

  const repeatPasswordValidate = v => {
    let password = getValues('password');
    return password === v || '两次密码输入不一致';
  };

  return (
    <>
      <PageTitleBar title="忘记密码" />
      <Form className="mb-3" onSubmit={handleSubmit(onSubmit)}>
        <div className="d-grid gap-5">
          <input {...register('type', { value: 'mobile' })} type="hidden" />
          <Form.Group className="form-floating">
            <Form.Control
              {...register('user_login_id', {
                required: '请填写手机号',
                pattern: {
                  value: /^1[3456789]\d{9}$/,
                  message: '手机号错误',
                },
              })}
              className={cNameIsInvalid('user_login_id')}
              type="tel"
              placeholder=" "
            />
            <Form.Label>手机号</Form.Label>
            <InvalidFeedback message={errors?.user_login_id?.message} />
          </Form.Group>
          <InputMessageCode
            register={register('code', { required: '请填写验证码' })}
            className={cNameIsInvalid('code')}
            label="验证码"
            buttonText="获取验证码"
            phoneNumber=""
            countDown="60"
            buttonOnClick={getSmsCode}
          />
          <input
            {...register('trigger_element_id', { value: 'forgot_password_get_sms_code' })}
            type="hidden"
          />
          <InputTogglePassword
            register={register('password', {
              required: '请设置新密码',
              minLength: {
                value: 8,
                message: '密码长度至少8位',
              },
              pattern: {
                value: /^(?![\d]+$)(?![a-zA-Z]+$)(?![-=+_.,#*@!]+$)[\da-zA-Z-=+_.,#*@!]{8,32}$/,
                message: '数字、字母、特殊符号至少包含两种',
              },
            })}
            className={cNameIsInvalid('password')}
            label="设置新密码"
            required
          >
            <InvalidFeedback message={errors?.password?.message} />
            <Form.Text>要求长度至少8位，数字、字母、特殊符号至少包含两种</Form.Text>
          </InputTogglePassword>
          <InputTogglePassword
            register={register('repeatPassword', {
              required: '请校验重复密码',
              validate: repeatPasswordValidate,
            })}
            className={cNameIsInvalid('repeatPassword')}
            label="重复密码"
            required
          >
            <InvalidFeedback message={errors?.repeatPassword?.message} />
          </InputTogglePassword>
        </div>
        <Row className="mt-10">
          <Col md={6}>
            <div className="d-grid gap-4">
              <LoadButton loading={submitLoading} variant="primary" size="lg" type="submit">
                提交
              </LoadButton>
              <Button variant="outline-primary" size="lg" onClick={toLogin}>
                取消
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default memo(ForgotPassword);
