import { memo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ClipImage from './ClipImage';
import { useNavigate } from 'react-router';
import utils from '../utils';
import OssImg from '../components/OssImg';

import './ActivityListCom.scss';

const chnNumChar = {
  0: '零',
  1: '一',
  2: '二',
  3: '三',
  4: '四',
  5: '五',
  6: '六',
  7: '七',
  8: '八',
  9: '九',
  10: '十',
  11: '十一',
  12: '十二',
};

const formatList = (list, noYearTitle) => {
  list.map((item, index) => {
    utils.formatKeyNoValue(item, '*');
    const time = utils.formatTime(new Date(item.start_datetime * 1000));
    item.year = time.year;
    item.month = chnNumChar[time.month] + '月';
    item.day = time.day < 10 ? (item.day = '0' + time.day) : (item.day = time.day);
    item.hour = time.hour < 10 ? (item.hour = '0' + time.hour) : (item.hour = time.hour);
    item.minute =
      time.minute < 10 ? (item.minute = '0' + time.minute) : (item.minute = time.minute);
    item.hm = item.hour + ':' + item.minute;
    if (item.year && !noYearTitle && (index === 0 || item.year !== list[index - 1].year)) {
      // 有新年份 开始插入
      item.insetYear = true;
    }
    item.time = time;
  });
  return list;
};

const ActivityListCom = memo(props => {
  let hasYearTitle = props.noYearTitle;
  let list = formatList(props.list, props.noYearTitle);
  return (
    <div className="activity-list-row-wrap">
      <Row className="activity-list-row">
        {list.map((item, index) => {
          return <ActivityItem key={index} noborder={props.noYearTitle} {...item}></ActivityItem>;
        })}
        {/* {!props.noYearTitle && <hr className="m-0" />} */}
      </Row>
    </div>
  );
});

function ActivityItem(props, key, noborder) {
  utils.formatKeyNoValue(props, '*');
  const nav = useNavigate();
  return (
    <>
      {props.insetYear && (
        <Col lg={12} className="year-header-wrap full">
          <div className="year-header">{props.year}</div>
        </Col>
      )}
      <Col lg={3} className="activity-item-wrap item">
        <div className={`activity-item ${props.noborder ? 'no-border' : ''}`}>
          <div className="top">
            <div className="activity-time">
              <span className="activity-time__month">{props.month}</span>
              <span className="activity-time__day">{props.day}</span>
              <span className="activity-time__hm">{props.hm}</span>
            </div>
            <div className="poster">
              <ClipImage
                noStyle
                small
                className="ratio ratio-3x4"
                onClick={() => nav('/activity/' + props.id)}
              >
                <OssImg src={props.key_visual} optionsName="md" />
              </ClipImage>
            </div>
          </div>
          <div className="bottom">
            <div>
              {props.title && (
                <h3 className="title" onClick={() => nav('/activity/' + props.id)}>
                  {props.title}
                </h3>
              )}
              {props.description && <p className="desc">{props.description}</p>}
            </div>
            {props.id && <Link to={`/activity/${props.id}`}>了解详情</Link>}
          </div>
        </div>
      </Col>
    </>
  );
}

export default ActivityListCom;
