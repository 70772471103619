import { memo, useState, useEffect, useRef } from 'react';
import seo from '../../utils/seo';
import api from '../../utils/request';
import { useParams } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import utils from '../../utils';
import PageContent from '../../components/PageContent';
import ClipImage from '../../components/ClipImage';
import OssImg from '../../components/OssImg';
import AngleDecoration from '../../components/AngleDecoration';
import Cms from '../../components/Cms';
import Dots from '../../components/Dots';
import Badge from '../../components/Badge';
import Relevant from '../../components/Relevant';
import SectsList from '../../components/SectsList';
import SelectProduct from '../../components/SelectProduct';
import ActivityListCom from '../../components/ActivityListCom';
import PxyEntry from '../../components/PxyEntry';
import './Detail.scss';
import useGiveTicket from '../../utils/hooks/useGiveTicket';

function PropDetail(props) {
  let urlParams = useParams();
  const [id, setId] = useState(urlParams.id);
  let [data, setData] = useState({});
  const result = useGiveTicket(data);
  let [product, setProduct] = useState({});
  let [cmsBlocks, setCmsBlocks] = useState([]);
  let [relatedActivities, setRelatedActivities] = useState({ props: [] });
  let [relatedSect, setRelatedSect] = useState({ props: [] });

  useEffect(() => {
    getDetail();
    api.props.getRelatedActivities({ data: { id } }).then(data => {
      setRelatedActivities(data);
    });
    api.props.getRelatedSect({ data: { id } }).then(data => {
      setRelatedSect(data);
    });
    return () => {
      seo.back();
    };
  }, [id]);

  useEffect(() => {
    if (result) {
      getDetail();
    }
  }, [result]);

  const getDetail = async () => {
    try {
      const detail = await api.props.getDetail({ data: { id } });
      detail.prop_tags.value = `[${detail.prop_tags.value}]`;
      ['prop_type', 'prop_tags', 'image_text'].forEach(key => {
        detail[key].value = JSON.parse(detail[key].value);
      });
      utils.formatKeyNoValue(detail, '*');
      utils.formatKeyNoValue(detail.holding_tickets, '*');
      const colors = {
        limit: 'primary',
        new: 'red',
        hot: 'blue',
      };

      detail.prop_tags.forEach(tag => (tag.color = colors[tag.key]));
      setData(detail);

      setCmsBlocks(detail.image_text);
      let { seo_title, seo_description, seo_keyword } = detail;
      seo.setContent({
        title: seo_title,
        description: seo_description,
        keywords: seo_keyword,
      });
      if (detail?.sale_status?.message === '可售') {
        const productDetail = await api.product.getDetail({
          data: { id: detail?.sale_status?.product_ids[0] },
        });
        setProduct(productDetail);
      }
    } catch (err) {
      console.log(err);
      setData({ notFound: true });
    }
  };

  useEffect(() => {
    setId(urlParams.id);
  }, [urlParams.id]);

  const selectProduct = useRef();
  const holdingTickets = data.holding_tickets;
  const productVariations = product?.variations || [];
  // 服务端默认读取最后一个规格
  const lastVariation = productVariations[productVariations.length - 1] || {};
  return (
    <PageContent loaded={data.id}>
      <Row className="baseinfo-row">
        <Col lg={5}>
          <div className="baseinfo-row__poster">
            <div className="prop-kv">
              <AngleDecoration />
              <div className="prop-kv-content">
                <ClipImage noStyle>
                  <OssImg
                    src={data?.key_visual}
                    alt={data?.title}
                    optionsName="lg"
                    options={{ format: 'png' }}
                  />
                </ClipImage>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={{ span: 4, offset: 1 }}>
          <div className="baseinfo-row__info">
            <div className="props-detail_hader">
              <span className="props-detail_label">{data?.prop_type?.value}</span>
              <h3 className="props-detail_title">{data?.title}</h3>
              <div className="badge-list">
                {data.prop_tags &&
                  data.prop_tags.map(item => {
                    return <Badge key={item.key} color={item.color} text={item.value}></Badge>;
                  })}
              </div>
              <div className="mt-8">
                {data?.price_number && (
                  <div className="props-detail_price">¥{data?.price_number}</div>
                )}
                {data?.description && (
                  <div className="props-detail_desc mt-5">{data?.description}</div>
                )}
                {data.holding_tickets ? (
                  <div className="mt-7">
                    <PxyEntry
                      btnVariant="primary"
                      btnClassName="w-100"
                      id={holdingTickets.id}
                      now={holdingTickets.current_time}
                      entryTime={holdingTickets.entry_time}
                      endTime={holdingTickets.end_time}
                      renderType={lastVariation.render_type}
                      appliId={lastVariation.appli_id}
                      serverAddress={lastVariation.queue_server_address}
                      renderAddress={lastVariation.render_server_address}
                    />
                  </div>
                ) : data.sale_status?.status !== 1 ? (
                  <div>
                    <Button
                      variant="primary"
                      className="mt-7 mt-lg-5 w-100"
                      size="lg"
                      onClick={() => {
                        selectProduct.current.show();
                      }}
                    >
                      购买
                    </Button>
                    <SelectProduct
                      ref={selectProduct}
                      product={product}
                      headerTitle="购买"
                      bodyTitle="说明"
                    />
                  </div>
                ) : (
                  <Button disabled>售罄</Button>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {!!cmsBlocks?.length && (
        <>
          <Dots />
          <Cms col={{ lg: { span: 8, offset: 2 } }} blocks={cmsBlocks} />
        </>
      )}
      <Relevant show={relatedActivities.length || relatedSect.length}>
        <Relevant.Section show={relatedActivities.length}>
          <Relevant.SectionHeader title="相关活动" />
          <Relevant.SectionBody>
            <ActivityListCom list={relatedActivities} noYearTitle={true} />
          </Relevant.SectionBody>
        </Relevant.Section>
        <Relevant.Section show={relatedSect.length}>
          <Relevant.SectionHeader title="相关门派" />
          <Relevant.SectionBody>
            <SectsList list={relatedSect} />
          </Relevant.SectionBody>
        </Relevant.Section>
      </Relevant>
    </PageContent>
  );
}

export default memo(PropDetail);
