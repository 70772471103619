let baseURL = {
  origin: '/drupal/',
  lang: 'zh-cn',
  prefix: '/como_one_page_api/',
};

let apiConfig = {
  global: {
    getSiteInfo: {
      url: 'get_site_info',
    },
  },
  common: {
    sendVerificationCode: {
      url: 'common/send_verification_code',
      method: 'post',
    },
    getGeo: {
      url: 'common/get_geo_tree',
    },
    ossSign: {
      url: 'como-storage/como-oss-sign',
    },
    getCsrfToken: {
      url: 'common/get_csrf_token',
    },
    getPxyData: {
      url: 'common/get_pxy_data',
    },
    getPixelData: {
      url: 'common/get_pixel_data',
      method: 'get',
    },
    parseCookie: {
      url: 'common/get_user_info',
      method: 'get',
    },
  },
  user: {
    login: {
      url: 'user/login',
      method: 'post',
    },
    loginInfo: {
      url: 'user/login_info',
    },
    logout: {
      url: 'user/logout',
    },
    register: {
      url: 'user/register',
      method: 'post',
    },
    resetPassword: {
      url: 'user/reset_pass',
      method: 'post',
    },
    checkUnique: {
      url: 'user/check_unique',
      method: 'post',
    },
    getOrders: {
      url: 'user/get_orders',
    },
    getOrder: {
      url: 'user/get_order',
      method: 'post',
    },
    getOrderDetail: {
      url: 'user/get_order_detail',
    },
    updateProfile: {
      url: 'user/update_profile',
      method: 'post',
    },
    getUpdateToken: {
      url: 'user/get_update_token',
      method: 'post',
    },
    getMyVirtualProps: {
      url: 'user/get_my_virtual_props',
    },
    mailSubscription: {
      url: 'mail_subscription/add',
      method: 'post',
    },
  },
  activity: {
    getList: {
      url: 'activity/get_list',
    },
    getDetail: {
      url: 'activity/get_detail',
    },
    getRelatedProps: {
      url: 'activity/get_related_props',
    },
    getRelatedSects: {
      url: 'activity/get_related_sects',
    },
  },
  sect: {
    getList: {
      url: 'sect/get_list',
    },
    getDetail: {
      url: 'sect/get_detail',
    },
    getRelatedProps: {
      url: 'sect/get_related_props',
    },
    getRelatedActivities: {
      url: 'sect/get_related_activities',
    },
  },
  props: {
    getList: {
      url: 'props/get_list',
    },
    getDetail: {
      url: 'props/get_detail',
    },
    getRelatedActivities: {
      url: 'props/get_related_activities',
    },
    getRelatedSect: {
      url: 'props/get_related_sect',
    },
  },
  product: {
    getDetail: {
      url: 'product/get_detail',
    },
  },
  order: {
    add: {
      url: 'order/add',
      method: 'post',
    },
    getCartInfo: {
      url: 'order/get_cart_info',
      method: 'post',
    },
    checkCoupon: {
      url: 'order/check_coupon',
      method: 'post',
    },
    submitCart: {
      url: 'order/submit_cart',
      method: 'post',
    },
    getPayment: {
      url: 'order/get_payment',
      method: 'post',
    },
    checkPaymentCompleted: {
      method: 'post',
      url: 'wechat/pay/check_payment_completed',
    },
    checkRemoteStateAction: {
      method: 'post',
      url: 'wechat/pay/check_remote_state_action',
    },
    cancel: {
      method: 'post',
      url: 'order/cancel',
    },
    exchange_by_coupon_code: {
      method: 'post',
      url: 'order/exchange_by_coupon_code',
    },
  },
  addressBook: {
    getList: {
      url: 'address_book/get_list',
    },
    getDetail: {
      url: 'address_book/get_detail',
    },
    add: {
      url: 'address_book/add',
      method: 'post',
    },
    update: {
      url: 'address_book/update',
      method: 'post',
    },
    remove: {
      url: 'address_book/remove',
      method: 'post',
    },
  },
  wechat: {
    generateQrCode: {
      url: 'wechat/official_account/generate_qr_code',
    },
    checkLogin: {
      url: 'wechat/official_account/check_login',
    },
    getUserInfo: {
      url: 'wechat/official_account/callback',
    },
    getOauthUrl: {
      url: 'wechat/open_platform/get_oauth_url',
    },
    checkLoginOpenPlatform: {
      url: 'wechat/open_platform/check_login',
    },
  },
  page: {
    getHome: {
      url: 'homepage/get_content',
    },
    getDetail: {
      url: 'page/get_detail',
    },
  },
};

export { baseURL, apiConfig };
