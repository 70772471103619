import { memo, useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form, Button, Row, Col, Modal } from 'react-bootstrap';
import moment from 'moment';
import api from '../../utils/request';
import AuthContext from '../../store/AuthContext';
import alert from '../../components/alert';
import PageContent from '../../components/PageContent';
import PageTitleBar from '../../components/PageTitleBar';
import InputMessageCode from '../../components/form/InputMessageCode';
import LoadButton from '../../components/LoadButton';
import InvalidFeedback from '../../components/form/InvalidFeedback';
import ItemQuantity from '../../components/ItemQuantity';

function ECode() {
  const authCtx = useContext(AuthContext);
  const nav = useNavigate();
  let [submitLoading, setSubmitLoading] = useState(false);
  let [showResultModal, setShowResultModal] = useState(false);
  let [newProps, setNewProps] = useState([]);
  const {
    register,
    handleSubmit,
    getValues,
    trigger,
    setFocus,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  useEffect(() => {}, []);

  async function getSmsCode() {
    const phoneNumber = getValues('user_login_id');
    const trigger_element_id = getValues('trigger_element_id');
    const coupon_code = await trigger('coupon_code', { shouldFocus: true });
    if (coupon_code) {
      const msg = await api.common
        .sendVerificationCode({
          data: {
            message_gateway_type: 'sms',
            recipient: `+86 ${phoneNumber}`,
            trigger_element_id,
          },
        })
        .catch(err => {
          return err.data;
        });
      setTimeout(() => setFocus('coupon_code'), 100);
      return msg;
    }
  }

  const cNameIsInvalid = key => (errors[key] ? 'is-invalid' : '');

  async function onSubmit(data) {
    setSubmitLoading(true);
    api.order
      .exchange_by_coupon_code({
        data,
      })
      .then(data => {
        setSubmitLoading(false);
        setShowResultModal(true);
        setNewProps([data.pv_info]);
      })
      .catch(err => {
        setSubmitLoading(false);
        requestError(err);
      });
  }

  function requestError(error) {
    if (error.data) {
      error.data.error_keys.forEach(key => {
        setError(key);
        alert.danger(error.message, key);
      });
    }
  }

  function hideModal() {
    reset();
    setShowResultModal(false);
    setTimeout(() => {
      clearErrors();
      reset();
    }, 300);
  }

  /* eslint-disable jsx-a11y/anchor-is-valid */

  return (
    <PageContent>
      <PageTitleBar title="兑换中心" />
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="d-grid gap-5">
          <Form.Floating>
            <Form.Control
              {...register('coupon_code', { required: '请填写兑换码' })}
              className={cNameIsInvalid('coupon_code')}
              placeholder=" "
            />
            <Form.Label>兑换码</Form.Label>
            <InvalidFeedback message={errors?.coupon_code?.message} />
          </Form.Floating>
          <Form.Floating>
            <div className="form-control">{authCtx?.userInfo?._mobile}</div>
            <Form.Label>手机号</Form.Label>
          </Form.Floating>
          <InputMessageCode
            register={register('code', { required: '请填写验证码' })}
            className={cNameIsInvalid('code')}
            label="验证码"
            buttonText="获取验证码"
            phoneNumber=""
            countDown="60"
            buttonOnClick={getSmsCode}
            reset={showResultModal}
          />
          <input
            {...register('user_login_id', { value: authCtx?.userInfo.mobile })}
            type="hidden"
          />
          <input
            {...register('trigger_element_id', { value: 'coupon_code_get_sms_code' })}
            type="hidden"
          />
        </div>
        <Row className="my-10">
          <Col sm={6}>
            <div className="d-grid gap-4">
              <LoadButton loading={submitLoading} size="lg" type="submit">
                兑换
              </LoadButton>
            </div>
          </Col>
        </Row>
        {/* <hr className="my-10" /> */}
        <div className="cms-item cms-item-richtext mt-15">
          <h5>兑换码是什么，如何获取？</h5>
          <ul>
            <li>兑换码可用于兑换商城实物或虚拟商品。</li>
            <li>兑换码在有效期内兑换后即刻核销，不可重复使用。</li>
            <li>
              关注官网、
              <a
                className="link"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  document.querySelector('.app-footer__follow-us__wechat')?.click();
                }}
              >
                微信公众号
              </a>
              、
              <a
                className="link"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  document.querySelector('.app-footer__follow-us__weibo')?.click();
                }}
              >
                官方微博
              </a>
              ，了解获取兑换码最新活动。
            </li>
          </ul>
          <h5>为什么我的兑换码无法使用？</h5>
          <ul>
            <li>
              目前我们单个活动仅可使用一张门票入场，因此如果您账户内已经拥有某活动门票类道具，是无法兑换该活动的门票。
            </li>
            <li>无法兑换已经结束活动的门票类道具。</li>
          </ul>
        </div>
      </Form>

      <Modal onHide={hideModal} show={showResultModal} size="md" centered>
        <Modal.Header>
          <Modal.Title>兑换成功</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>恭喜您兑换成功如下道具：</p>
          <div className="bg-light p-4">
            <div className="item-quantity-group">
              {newProps.map((prop, idx) => {
                const { kv, label, entry_time, end_time } = prop;
                let [startDate, startTime] = moment(entry_time * 1000)
                  .format('YYYY.MM.DD|HH:mm:ss')
                  .split('|');
                return (
                  <ItemQuantity
                    imgSrc={kv}
                    attributes={[label, startDate, startTime, 'x 1']}
                    key={idx}
                  />
                );
              })}
            </div>
          </div>
          <div className="grid pt-6">
            <Button
              className="g-col-12 g-col-sm-6 order-sm-last"
              size="lg"
              onClick={() => {
                hideModal();
                nav('/user/warehouse');
              }}
            >
              去仓库查看
            </Button>
            <Button className="g-col-12 g-col-sm-6" onClick={hideModal} variant="outline-primary">
              关闭
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </PageContent>
  );
}

export default memo(ECode);
