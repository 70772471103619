import { memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './ListItemText.scss';

function ListItemText({
  title,
  titleAfter,
  description,
  linkTo,
  linkToText = '了解详情',
  prop_tags,
  ...props
}) {
  const nav = useNavigate();
  prop_tags ? (prop_tags = JSON.parse(`[${prop_tags}]`)) : (prop_tags = []);
  function navLinkTo() {
    if (linkTo) nav(linkTo);
  }

  return (
    <div className="list-item-text flex-fill">
      <h5 onClick={navLinkTo}>
        {title}
        {titleAfter}
      </h5>
      {(description || linkTo) && (
        <>
          <div className="flex-fill">
            {description && <p className="max-line-ellipsis-5">{description}</p>}
          </div>
          {linkTo && <Link to={linkTo}>{linkToText}</Link>}
        </>
      )}
    </div>
  );
}

export default memo(ListItemText);
