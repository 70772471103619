import { memo } from 'react';
import './PageTitleBar.scss';

function PageTitleBar({ title = '页面标题', leftEl, rightEl }) {
  return (
    <div className="d-flex mb-8 page-title-bar">
      <div className="page-title-bar__left flex-grow-1">
        <h3 className="mb-0">{title}</h3>
        {leftEl}
      </div>
      {rightEl && <div className="page-title-bar__right">{rightEl}</div>}
    </div>
  );
}

export default memo(PageTitleBar);
