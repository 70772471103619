import { memo } from 'react';
import './AppMain.scss';

const AppMain = memo(({ container = true, children }) => (
  <main className="app-main">
    {/* {container ? <div className="app-main-container container-xxl">{children}</div> : children} */}
    <div className={container ? 'app-main-container container-xxl' : ''}>{children}</div>
  </main>
));

export default AppMain;
