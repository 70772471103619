import { useState, useEffect, useRef } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { AliveScope, useAliveController } from 'react-activation';
import api from './utils/request';
import { AppHeader, AppMain, AppFooter } from './layout';
import AppUseRouter from './routers/AppUseRouter';
import { AuthContextProvider } from './store/AuthContext';
import WechatContext, { WechatContextProvider } from './store/WechatContext';
import './App.scss';

const App = () => {
  let [data, setData] = useState({});
  const [wxOpenPlatformAppId, setWxOpenPlatformAppId] = useState(
    localStorage.getItem('wxOpenPlatformAppId'),
  );
  const [wxOfficialAccountAppId, setWxOfficialAccountAppId] = useState(
    localStorage.getItem('wxOfficialAccountAppId'),
  );
  const footerScriptRef = useRef();

  useEffect(() => {
    api.global.getSiteInfo().then(data => {
      setData(data);
      document.title = data?.site_name ?? '新武林';

      const newWxOpenPlatformAppId = data.wechat_open_platform_appid;
      const newWxOfficialAccountAppId = data.wechat_official_account_appid;
      const newFreeActivityUrl = data.free_activity_url;
      if (newWxOfficialAccountAppId && wxOfficialAccountAppId !== newWxOfficialAccountAppId) {
        localStorage.setItem('wxOfficialAccountAppId', newWxOfficialAccountAppId);
        localStorage.removeItem('wxOpenId');
        setWxOfficialAccountAppId(newWxOfficialAccountAppId);
      }
      if (newWxOpenPlatformAppId && wxOfficialAccountAppId !== newWxOpenPlatformAppId) {
        localStorage.setItem('wxOpenPlatformAppId', newWxOpenPlatformAppId);
        localStorage.removeItem('wxOpenId');
        setWxOpenPlatformAppId(newWxOpenPlatformAppId);
      }
      if (newFreeActivityUrl) {
        localStorage.setItem('freeActivityUrl', newFreeActivityUrl);
      }

      const footerScriptNode = document.createRange().createContextualFragment(data.footer_script);
      footerScriptRef.current.appendChild(footerScriptNode);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthContextProvider>
      <BrowserRouter>
        <WechatContextProvider
          wxOpenPlatformAppId={wxOpenPlatformAppId}
          wxOfficialAccountAppId={wxOfficialAccountAppId}
        >
          <Frame data={data} />
        </WechatContextProvider>
      </BrowserRouter>
      <div className="footer-script" ref={footerScriptRef}></div>
    </AuthContextProvider>
  );
};

const Frame = ({ data }) => {
  const location = useLocation();
  let [frame, setFrame] = useState(!window.location.pathname.includes('/play'));
  // const wechatCtx = useContext(WechatContext);

  useEffect(() => {
    if (location.pathname.includes('/play')) setFrame(false);
    else setFrame(true);
  }, [location.pathname]);

  return (
    <AliveScope>
      <div className="App">
        {frame && <AppHeader menu={data.main_menu} />}
        <AppMain container={frame}>
          <AppUseRouter />
        </AppMain>
        {frame && <AppFooter menu={data.footer_menu} />}
      </div>
    </AliveScope>
  );
};

export default App;
