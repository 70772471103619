import { memo, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PageTitleBar from '../../components/PageTitleBar';
import PageContent from '../../components/PageContent';
import api from '../../utils/request';
import FilterTypeNav from '../../components/FilterTypeNav';
import MarkerList from '../../components/markerList';
import ListNoData from '../../components/ListNoData';
import InfiniteScroll from 'react-infinite-scroller';

function Warehouse(props) {
  let [prop_type, setCategory] = useState('');
  let [page, setPage] = useState(0);
  let [data, setData] = useState({});
  let [prop_list, setList] = useState({});
  let [hasMoreItems, sethasMoreItems] = useState(true);
  let [propsListFilter, setPropsListFilter] = useState({});

  useEffect(() => {
    api.user
      .getMyVirtualProps({ data: { prop_type, page } })
      .then(detail => {
        setData(detail);
        sethasMoreItems(hasMoreItems => {
          const result = detail.rows.length ? true : false;
          return result;
        });
        setList(prop_list => {
          const newList = page === 0 ? detail.rows : prop_list.concat(detail.rows);
          return newList;
        });
      })
      .catch(err => {
        setData({ notFound: true });
      });
  }, [page, prop_type]);

  const switchCategory = category => {
    category === '*' ? (category = '') : (category = category);
    setPage(0);
    setCategory(category);
  };

  const loadFunc = () => {
    if (hasMoreItems) {
      setPage(page + 1);
      sethasMoreItems(false);
    }
  };

  return (
    <PageContent loaded={data.rows}>
      <PageTitleBar title="仓库" />
      <div>
        {Object.keys(data.prop_types || {}).length > 0 && (
          <FilterTypeNav
            nav={data.prop_types}
            switchCategory={switchCategory}
            setFilter={setPropsListFilter}
          />
        )}
        <InfiniteScroll
          pageStart={0}
          loadMore={loadFunc}
          hasMore={hasMoreItems}
          loader={
            <div className="loader" key={0}>
              Loading ...
            </div>
          }
        >
          <>
            {prop_list?.length ? (
              <MarkerList list={prop_list}></MarkerList>
            ) : (
              <ListNoData text="目前还未持有道具。" action={<Link to="/props">立即添加</Link>} />
            )}
          </>
        </InfiniteScroll>
      </div>
    </PageContent>
  );
}

export default memo(Warehouse);
