import { memo } from 'react';
import './Loading.scss';

const Loading = memo(props => {
  return (
    <div className="bounce-loading">
      <div className="bounce-loading__bounceball"></div>
      <div className="bounce-loading__text">NOW LOADING</div>
    </div>
  );
});

export default Loading;
