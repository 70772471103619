import { memo, useState, useContext } from 'react';
import { Form, Button, InputGroup, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import api from '../../utils/request';
import AuthContext from '../../store/AuthContext';
import WechatContext from '../../store/WechatContext';
import alert from '../../components/alert';
import PageTitleBar from '../../components/PageTitleBar';
import LoadButton from '../../components/LoadButton';
import InputTogglePassword from '../../components/form/InputTogglePassword';
import InputMessageCode from '../../components/form/InputMessageCode';
import InvalidFeedback from '../../components/form/InvalidFeedback';
import WxLogin from '../../components/WxLogin';

const updateModalTitle = {
  mobile: '绑定新手机',
  pass: '设置新密码',
  bindWeChat: '微信绑定',
  unbindWeChat: '解除绑定',
  nickname: '编辑昵称',
  name: '编辑姓名',
  email: '编辑邮箱',
};

function Profile() {
  let [showUserAuthModal, setShowUserAuthModal] = useState(false);
  let [showUpdateModal, setShowUpdateModal] = useState(false);
  let [updateField, setUpdateField] = useState('');
  const authCtx = useContext(AuthContext);
  const wechatCtx = useContext(WechatContext);
  // 微信注册且没有绑定手机号
  const isWechatLogin = authCtx?.userInfo?.wechat_binding && !authCtx?.userInfo?._mobile;

  if (!authCtx.isLoggedIn) return;
  const handleClickModify = ({ target }) => {
    const { modify } = target.dataset;
    setUpdateField(modify);
    if (
      modify === 'bindWeChat' ||
      modify === 'unbindWeChat' ||
      modify === 'nickname' ||
      (modify === 'mobile' && isWechatLogin)
    ) {
      setShowUpdateModal(true);
    } else if (authCtx.isInputAuth === false) {
      setShowUserAuthModal(true);
    } else setShowUpdateModal(true);
  };

  const authPassed = () => {
    authCtx.setIsInputAuth(true);
    setShowUserAuthModal(false);
    setShowUpdateModal(true);
  };

  return (
    <>
      <PageTitleBar title="个人信息" />
      <div className="d-grid gap-5">
        <InputGroup className="input-group--lock">
          <Form.Floating>
            <div className="form-control">{authCtx?.userInfo?._mobile}</div>
            <Form.Label>手机号</Form.Label>
          </Form.Floating>
          <Button onClick={handleClickModify} data-modify="mobile" variant="link">
            {isWechatLogin ? '绑定手机号' : '修改手机号'}
          </Button>
        </InputGroup>
        {/* fixme 微信用户无法修改密码 */}
        {!isWechatLogin ? (
          <InputGroup className="input-group--lock">
            <Form.Floating>
              <div className="form-control">修改密码以保护你的账户</div>
              <Form.Label>密码</Form.Label>
            </Form.Floating>
            <Button onClick={handleClickModify} data-modify="pass" variant="link">
              修改密码
            </Button>
          </InputGroup>
        ) : null}
        <InputGroup className="input-group--lock">
          <Form.Floating>
            <div className="form-control">
              {authCtx?.userInfo?.wechat_binding
                ? '已绑定，可扫码登录'
                : '未绑定，完成绑定后可扫码登录'}
            </div>
            <Form.Label>绑定微信</Form.Label>
          </Form.Floating>
          {authCtx?.userInfo?.wechat_binding ? (
            <Button
              className={wechatCtx.isWechat ? '' : 'd-none d-sm-block'}
              onClick={handleClickModify}
              data-modify="unbindWeChat"
              variant="link"
            >
              解除绑定
            </Button>
          ) : (
            <Button
              className={wechatCtx.isWechat ? '' : 'd-none d-sm-block'}
              onClick={handleClickModify}
              data-modify="bindWeChat"
              variant="link"
            >
              绑定
            </Button>
          )}
        </InputGroup>
        <InputGroup className="input-group--lock">
          <Form.Floating>
            <div className="form-control">{authCtx?.userInfo?.nickname}</div>
            <Form.Label>昵称</Form.Label>
          </Form.Floating>
          <Button onClick={handleClickModify} data-modify="nickname" variant="link">
            编辑昵称
          </Button>
        </InputGroup>
        <InputGroup className="input-group--lock">
          <Form.Floating>
            <div className="form-control">
              {authCtx?.userInfo?.last_name + authCtx?.userInfo?.first_name}
            </div>
            <Form.Label>姓名</Form.Label>
          </Form.Floating>
          <Button onClick={handleClickModify} data-modify="name" variant="link">
            编辑姓名
          </Button>
        </InputGroup>
        <InputGroup className="input-group--lock">
          <Form.Floating>
            <div className="form-control">{authCtx?.userInfo?.mail}</div>
            <Form.Label>邮箱</Form.Label>
          </Form.Floating>
          <Button onClick={handleClickModify} data-modify="email" variant="link">
            编辑邮箱
          </Button>
        </InputGroup>
      </div>
      <UserAuthModal
        showState={[showUserAuthModal, setShowUserAuthModal]}
        afterSuccess={authPassed}
      />
      <UpdateModal
        showState={[showUpdateModal, setShowUpdateModal]}
        fieldState={[updateField, setUpdateField]}
      />
    </>
  );
}

const UserAuthModal = memo(({ showState, afterSuccess }) => {
  const authCtx = useContext(AuthContext);
  let [authType, setAuthType] = useState('password');
  let [step, setStep] = useState(1);
  let [submitLoading, setSubmitLoading] = useState(false);
  let [showUserAuthModal, setShowUserAuthModal] = showState;
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const cNameIsInvalid = key => (errors[key] ? 'is-invalid' : '');

  async function getSmsCode() {
    const phoneNumber = getValues('user_login_id');
    const trigger_element_id = getValues('trigger_element_id');
    const msg = await api.common
      .sendVerificationCode({
        data: {
          message_gateway_type: 'sms',
          recipient: `+86 ${phoneNumber}`,
          trigger_element_id,
        },
      })
      .catch(err => {
        return err.data;
      });
    setTimeout(() => setFocus('code'), 100);
    return msg;
  }

  async function onSubmit(data) {
    setSubmitLoading(true);
    await api.user
      .login({
        data,
      })
      .then(data => {
        afterSuccess && afterSuccess();
        setTimeout(() => {
          setStep(1);
          reset();
        }, 300);
      })
      .catch(err => {
        requestError(err);
        if (data.login_way === 'password') {
          setError('password');
        }
      });
    setSubmitLoading(false);
  }

  function requestError(error) {
    if (error.data) {
      error.data.error_keys.forEach(key => {
        setError(key);
        alert.danger(error.message, key);
      });
    }
  }

  return (
    <Modal
      onHide={() => {
        setShowUserAuthModal(false);
      }}
      show={showUserAuthModal}
      size="sm"
      centered
    >
      <Modal.Header>
        <Modal.Title>身份验证</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {step === 1 && (
          <div className="d-grid gap-5">
            <Form.Select
              defaultValue={authType}
              onChange={({ target }) => {
                setAuthType(target.value);
                setValue('login_way', target.value);
              }}
            >
              <option disabled>选择验证方式</option>
              <option value="password">密码验证</option>
              <option value="code">短信验证</option>
            </Form.Select>
            <Button onClick={() => setStep(2)}>下一步</Button>
          </div>
        )}
        {step === 2 && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <input {...register('login_way', { value: authType })} type="hidden" />
            <input {...register('type', { value: 'mobile' })} type="hidden" />
            <input
              {...register('user_login_id', { value: authCtx?.userInfo.mobile })}
              type="hidden"
            />
            <div className="d-grid gap-5">
              {authType === 'password' && (
                <InputTogglePassword
                  register={register('password', {
                    required: '请填写登录密码',
                  })}
                  autoComplete="current-password"
                  className={cNameIsInvalid('password')}
                  label="密码"
                >
                  <InvalidFeedback message={errors?.password?.message} />
                </InputTogglePassword>
              )}
              {authType === 'code' && (
                <>
                  <Form.Floating className="pe-none">
                    <Form.Control type="tel" defaultValue={authCtx?.userInfo._mobile} />
                    <Form.Label>手机号</Form.Label>
                  </Form.Floating>
                  <InputMessageCode
                    register={register('code', { required: '请填写验证码' })}
                    className={cNameIsInvalid('code')}
                    label="验证码"
                    buttonText="获取验证码"
                    phoneNumber=""
                    countDown="60"
                    buttonOnClick={getSmsCode}
                  />
                  <input
                    {...register('trigger_element_id', { value: 'login_get_sms_code' })}
                    type="hidden"
                  />
                </>
              )}

              <div className="d-grid gap-4">
                <LoadButton loading={submitLoading} variant="primary" size="lg" type="submit">
                  确定
                </LoadButton>
                <Button onClick={() => setStep(1)} variant="outline-primary">
                  修改验证方式
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
});

const UpdateModal = memo(({ showState, fieldState }) => {
  const authCtx = useContext(AuthContext);
  let [showUpdateModal, setShowUpdateModal] = showState;
  let [updateField] = fieldState;
  let [submitLoading, setSubmitLoading] = useState(false);
  let defaultValues = {
    nickname: authCtx?.userInfo?.nickname,
    first_name: authCtx?.userInfo?.first_name,
    last_name: authCtx?.userInfo?.last_name,
    email: authCtx?.userInfo?.mail,
  };
  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    setError,
    setFocus,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
  });
  const cNameIsInvalid = key => (errors[key] ? 'is-invalid' : '');

  async function onSubmit(data) {
    setSubmitLoading(true);
    data.update_field = updateField.includes('WeChat') ? 'wechat_open_platform' : updateField;
    await api.user
      .updateProfile({ data })
      .then(() => {
        authCtx.facthLoginInfo();
        alert.success(`成功${updateModalTitle[updateField]}`, updateField);
        setShowUpdateModal(false);
        setTimeout(() => {
          ['code', 'new_pass', 'old_pass'].forEach(key => {
            data[key] = '';
          });
          reset(data);
        }, 300);
      })
      .catch(err => {
        console.log(err);
        requestError(err);
      });
    setSubmitLoading(false);
  }

  function requestError(error) {
    if (error.data) {
      error.data.error_keys.forEach(key => {
        setError(key);
        alert.danger(error.message, key);
      });
    }
  }

  function hideModal() {
    setShowUpdateModal(false);
    setTimeout(() => {
      clearErrors();
      reset();
    }, 300);
  }

  async function getSmsCode() {
    const phoneNumberIsValid = await trigger('user_login_id', { shouldFocus: true });
    if (phoneNumberIsValid) {
      const phoneNumber = getValues('user_login_id');
      const trigger_element_id = getValues('trigger_element_id');
      if (authCtx.userInfo.mobile === phoneNumber) {
        setError('user_login_id', { type: 'custom', message: '当前帐号已绑定该手机号' });
      } else {
        const canRegister = await api.user
          .checkUnique({
            data: {
              type: 'mobile',
              user_login_id: phoneNumber,
            },
          })
          .catch(err => {
            requestError(err);
            return false;
          });
        if (canRegister) {
          const msg = await api.common
            .sendVerificationCode({
              data: {
                message_gateway_type: 'sms',
                recipient: `+86 ${phoneNumber}`,
                trigger_element_id,
              },
            })
            .catch(err => {
              return err.data;
            });
          setTimeout(() => setFocus('code'), 100);
          return msg;
        }
      }
    }
  }

  const modalParams = {
    onHide: hideModal,
    show: showUpdateModal,
    size: updateField === 'bindWeChat' ? 'sm' : 'md',
    centered: true,
  };

  return (
    <Modal {...modalParams}>
      <Modal.Header>
        <Modal.Title>{updateModalTitle[updateField]}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {updateField === 'bindWeChat' ? (
            // <BindWeChatCode bindingSucceeded={hideModal} />
            <WxLogin
              redirectUri
              nonEmbedded
              succeeded={() => {
                hideModal();
                alert.success(`成功${updateModalTitle[updateField]}`, updateField);
              }}
              placeholder="绑定微信号"
            />
          ) : (
            <div className="d-grid gap-5">
              {updateField === 'mobile' && (
                <>
                  <Form.Floating>
                    <Form.Control
                      {...register('user_login_id', {
                        required: '请填写手机号',
                        pattern: {
                          value: /^1[3456789]\d{9}$/,
                          message: '手机号错误',
                        },
                      })}
                      className={cNameIsInvalid('user_login_id')}
                      type="tel"
                      placeholder=" "
                    />
                    <Form.Label>手机号</Form.Label>
                    <InvalidFeedback message={errors?.user_login_id?.message} />
                  </Form.Floating>
                  <InputMessageCode
                    register={register('code', { required: '请填写验证码' })}
                    className={cNameIsInvalid('code')}
                    label="验证码"
                    buttonText="获取验证码"
                    phoneNumber=""
                    countDown="60"
                    buttonOnClick={getSmsCode}
                  />
                  <input
                    {...register('trigger_element_id', { value: 'change_phone_get_sms_code' })}
                    type="hidden"
                  />
                </>
              )}
              {updateField === 'pass' && (
                <>
                  <InputTogglePassword
                    register={register('old_pass', {
                      // required: '请填写登录密码',
                    })}
                    autoComplete="current-password"
                    className={cNameIsInvalid('old_pass')}
                    label="当前密码"
                  >
                    <InvalidFeedback message={errors?.old_pass?.message} />
                  </InputTogglePassword>
                  <InputTogglePassword
                    register={register('new_pass', {
                      required: '请设置新密码',
                      minLength: {
                        value: 8,
                        message: '密码长度至少8位',
                      },
                      pattern: {
                        value:
                          /^(?![\d]+$)(?![a-zA-Z]+$)(?![-=+_.,#*@!]+$)[\da-zA-Z-=+_.,#*@!]{8,32}$/,
                        message: '数字、字母、特殊符号至少包含两种',
                      },
                    })}
                    autoComplete="new-password"
                    className={cNameIsInvalid('new_pass')}
                    label="新密码"
                  >
                    <InvalidFeedback message={errors?.new_pass?.message} />
                    <Form.Text>要求长度至少8位，数字、字母、特殊符号至少包含两种</Form.Text>
                  </InputTogglePassword>
                </>
              )}
              {updateField === 'bindWeChat' && <p>绑定微信。</p>}
              {updateField === 'unbindWeChat' && (
                <p>确定要解除微信绑定么，你可以随时重新绑定微信。</p>
              )}
              {updateField === 'nickname' && (
                <Form.Floating>
                  <Form.Control
                    {...register('nickname', {
                      required: '请填写昵称',
                      minLength: 1,
                      maxLength: 30,
                    })}
                    className={cNameIsInvalid('nickname')}
                  />
                  <Form.Label>昵称</Form.Label>
                  <InvalidFeedback message={errors?.nickname?.message} />
                </Form.Floating>
              )}
              {updateField === 'name' && (
                <div className="grid gap-5" style={{ '--bs-columns': 2 }}>
                  <Form.Floating>
                    <Form.Control
                      {...register('last_name', { minLength: 1, maxLength: 30 })}
                      className={cNameIsInvalid('last_name')}
                      name="last_name"
                      placeholder=" "
                    />
                    <Form.Label>姓</Form.Label>
                    <InvalidFeedback message={errors?.last_name?.message} />
                  </Form.Floating>
                  <Form.Floating>
                    <Form.Control
                      {...register('first_name', { minLength: 1, maxLength: 30 })}
                      className={cNameIsInvalid('first_name')}
                      name="first_name"
                      placeholder=" "
                    />
                    <Form.Label>名</Form.Label>
                    <InvalidFeedback message={errors?.first_name?.message} />
                  </Form.Floating>
                </div>
              )}
              {updateField === 'email' && (
                <>
                  <Form.Floating>
                    <Form.Control
                      {...register('email', {
                        pattern: {
                          value:
                            /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/,
                          message: '邮箱格式错误',
                        },
                      })}
                      className={cNameIsInvalid('email')}
                      type="email"
                      autoComplete="on"
                      placeholder=" "
                    />
                    <Form.Label>邮箱</Form.Label>
                    <InvalidFeedback message={errors?.email?.message} />
                  </Form.Floating>
                </>
              )}
              <div className="grid pt-1">
                <LoadButton
                  className={
                    modalParams.size === 'sm' ? 'g-col-12' : 'g-col-12 g-col-sm-6 order-sm-last'
                  }
                  loading={submitLoading}
                  variant="primary"
                  size="lg"
                  type="submit"
                >
                  {updateField === 'unbindWeChat' ? '确定解绑' : '确定'}
                </LoadButton>
                <Button
                  className={modalParams.size === 'sm' ? 'g-col-12' : 'g-col-12 g-col-sm-6'}
                  onClick={hideModal}
                  variant="outline-primary"
                >
                  取消
                </Button>
              </div>
            </div>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
});

// const BindWeChatCode = memo(({ bindingSucceeded }) => {
//   let [sessionId, setSessionId] = useState('');
//   let [wechatQrCode, setWechatQrCode] = useState('');
//   const authCtx = useContext(AuthContext);

//   useEffect(() => {
//     api.wechat.generateQrCode().then(data => {
//       let { session_id, uri } = data;
//       setSessionId(session_id);
//       setWechatQrCode(uri);
//     });
//   }, []);

//   useEffect(() => {
//     let timer, timeout;
//     if (authCtx.userInfo.wechat_binding === false) {
//       timeout = setTimeout(() => {
//         timer = setInterval(() => {
//           api.wechat
//             .checkLogin({ data: { session_id: sessionId } })
//             .then(data => {
//               clearInterval(timer);
//               authCtx.facthLoginInfo();
//               setTimeout(() => alert.success(data.message), 200);
//               bindingSucceeded && bindingSucceeded();
//               console.log('绑定成功', data);
//             })
//             .catch(err => {
//               console.log('绑定失败', err);
//             });
//         }, 1000);
//       }, 2000);
//     }
//     return () => {
//       clearTimeout(timeout);
//       clearInterval(timer);
//     };
//   }, [sessionId, authCtx]); // eslint-disable-line react-hooks/exhaustive-deps

//   return <ImageCode type="qr-code" src={wechatQrCode} />;
// });

export default memo(Profile);
