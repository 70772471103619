// import { util } from 'prettier';
import { memo } from 'react';
import { useNavigate } from 'react-router';
// import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
// import NavbarToggle from 'react-bootstrap/esm/NavbarToggle';
import utils from '../utils';
import AngleDecoration from './AngleDecoration';
import OssImg from './OssImg';
import './orderItem.scss';

const orderItem = memo(props => {
  const nav = useNavigate();
  function formatStatus(key = 'completed') {
    const data = {
      completed: '已完成',
      draft: '待付款',
      canceled: '已取消',
    };
    return data[key];
  }

  return (
    <div className="order-item" onClick={() => nav('/user/order/' + props.order?.order_id?.value)}>
      <div className="order-item__header">
        <div className="info">
          {props.order?.order_number?.value && (
            <div className="order-id">#{props.order?.order_number?.value}</div>
          )}
          <div className="order-time">
            {utils.DateFormat(new Date(props.order?.created?.value * 1000), 'yyyy.MM.dd hh:mm')}
          </div>
          <div className="order-price">
            <span className="price-text">¥{props.order?.total_price?.value}</span>
            <span className="order-status">{formatStatus(props.order?.state?.value)}</span>
          </div>
        </div>
        {props.order.state?.value === 'draft' && (
          <div className="control">
            <Button
              variant="link"
              size="sm"
              onClick={e => {
                e.stopPropagation();
                props.cancelOrder(props.order?.order_id?.value);
              }}
            >
              取消订单
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={e => {
                e.stopPropagation();
                nav('/buy/Pay/' + props.order?.order_id?.value);
              }}
            >
              去付款
            </Button>
          </div>
        )}
      </div>
      <div className="order-item__content">
        <div className="order-item__content-product-list">
          {props.order?.items?.value.length &&
            props.order?.items?.value.map((item, index) => {
              utils.formatKeyNoValue(item, '*');
              return <OrderProdItem key={index} {...item}></OrderProdItem>;
            })}
        </div>
      </div>
    </div>
  );
});

function OrderProdItem(props) {
  utils.formatKeyNoValue(props, '*');
  const { title, kv, created, quantity, unit_price } = props;
  return (
    <div className="order-product-item">
      <div className="order-product-item__image">
        <AngleDecoration size="medium" />
        <div className="pic">
          {/* <img src={kv} /> */}
          <OssImg src={kv} optionsName="sm" />
        </div>
      </div>
      <div className="order-product-item__info">
        <p>{title}</p>
        <p>{utils.DateFormat(new Date(created * 1000), 'yyyy.MM.dd hh:mm')}</p>
        <p>x {quantity}</p>
      </div>
    </div>
  );
}

export default orderItem;
