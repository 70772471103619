import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAliveController } from 'react-activation';
import PageTitleBar from '../../../components/PageTitleBar';
import AddressForm from '../../../components/AddressForm';

function AddAddress() {
  const nav = useNavigate();
  const { dropScope } = useAliveController();

  function handleSubmitAfter() {
    dropScope('Address').then(_ => {
      setTimeout(() => {
        nav('/user/address/');
      }, 100);
    });
  }

  return (
    <>
      <PageTitleBar title="新增收件地址" />
      <AddressForm
        formType="add"
        onSubmitAfter={handleSubmitAfter}
        onCancel={() => nav('/user/address/')}
      />
    </>
  );
}

export default memo(AddAddress);
