import { useEffect, useRef } from 'react';
import { getImgOssSrc } from '../OssImg';
import './RichText.scss';

function RichText(props) {
  let richtext = useRef();
  useEffect(() => {
    const tables = richtext.current.querySelectorAll('table');
    const imgs = richtext.current.querySelectorAll('img');

    if (tables.length) {
      if (props.defaultTable) {
        tables.forEach(table => {
          table.className = 'table table-border';
          table.outerHTML = `<div class="table-responsive">${table.outerHTML}</div>`;
        });
      } else if (props.noborderTable) {
        tables.forEach(table => {
          table.className = 'table--noborder';
        });
      }
    }
    if (imgs.length) {
      imgs.forEach(img => {
        img.src && (img.src = getImgOssSrc(img.src, 'xl'));
      });
    }
  }, [props]);

  return (
    <div
      className={'cms-item cms-item-richtext'}
      dangerouslySetInnerHTML={{ __html: props.content }}
      ref={richtext}
    ></div>
  );
}

export default RichText;
