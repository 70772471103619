import { memo, useState, useEffect, useMemo } from 'react';
import utils from '../utils';

const CountDown = memo(
  ({
    countDownTime = 0,
    prefixText = '距入场 ',
    suffixText = '',
    className = '',
    children,
    ...props
  }) => {
    const [time, setTime] = useState(countDownTime > 0 ? countDownTime : 0);

    //倒计时
    useEffect(() => {
      let timer;
      if (time && time > 0 && time < 86400) {
        timer = setTimeout(() => {
          setTime(time => time - 1);
        }, 1000);
      }
      return () => {
        clearTimeout(timer);
      };
    }, [time]);

    const countDownText = useMemo(() => utils.computedCountDown(time), [time]);

    return time ? (
      <span
        className={className}
        style={{
          textDecoration: 'none',
        }}
      >
        {prefixText + countDownText + suffixText}
      </span>
    ) : (
      children
    );
  },
);

export default CountDown;
