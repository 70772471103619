import { memo } from 'react';
import AngleDecoration from './AngleDecoration';
import OssImg from './OssImg';
import utils from '../utils';

const OrderProdItem = memo(props => {
  return (
    <div>
      {props.list.map((item, index) => {
        utils.formatKeyNoValue(item, '*');
        return <ProdItem key={index} {...item}></ProdItem>;
      })}
    </div>
  );
});

function ProdItem(props) {
  utils.formatKeyNoValue(props, '*');
  const { kv, title, created, quantity, unit_price } = props;
  return (
    <div className="order-detail__prod-item">
      <div className="left">
        <div className="image">
          <AngleDecoration size="medium" />
          <div className="pic">
            <OssImg src={kv} optionsName="sm" />
          </div>
        </div>
        <div className="info">
          <p>{title}</p>
          <p>{utils.DateFormat(new Date(created * 1000), 'yyyy.MM.dd hh:mm')}</p>
        </div>
      </div>
      <div className="right">
        <p>¥ {unit_price}</p>
        <p>x {quantity}</p>
      </div>
    </div>
  );
}

export default OrderProdItem;
