import React, { memo, useState, useEffect, useRef, useImperativeHandle } from 'react';
import { isMobile, isDesktop } from 'react-device-detect';
import { Offcanvas } from 'react-bootstrap';
import api from '../utils/request';
import utils from '../utils';
import useOnClickOutside from '../utils/hooks/useOnClickOutside';
import './Cascader.scss';

function Cascader({
  fieldLength = 4,
  placeholders = ['选择省份/自治区', '选择城市/地区', '选择区县', '选择配送区域'],
  defaultValue = [],
  onRef,
  onSelectEnd,
}) {
  let [show, setShow] = useState(false);
  let [requesting, setRequesting] = useState(false);
  let [showLevel, setShowLevel] = useState(
    defaultValue.length < fieldLength ? defaultValue.length : fieldLength - 1,
  );
  let [mutiOptions, setMutiOptions] = useState(Array(fieldLength));
  let [selectedValue, setSelectedValue] = useState(Array(fieldLength));
  const hide = () => setShow(false);
  const cascaderRef = useRef();

  useOnClickOutside(cascaderRef, hide);

  useEffect(() => {
    api.common.getGeo().then(data => {
      updateMutiOptions(0, data);
    });
    if (defaultValue.length !== 0) {
      setSelectedValue(defaultValue);
      for (let i = 1; i < defaultValue.length; i++) {
        api.common.getGeo({ data: { tid: defaultValue[i - 1].tid } }).then(data => {
          updateMutiOptions(i, data);
        });
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(onRef, () => {
    return {
      show: () => setShow(true),
      hide,
    };
  });

  function updateMutiOptions(idx, update) {
    if (idx !== undefined && update) {
      setMutiOptions(arr => {
        let newArr = [...arr];
        newArr[idx] = update;
        return newArr;
      });
    }
  }

  function handleItemClick(e) {
    let { level, tid, label } = e.target.dataset;
    if (!level || !tid) return;
    level = level * 1;

    if (level < fieldLength) {
      setRequesting(true);
      api.common.getGeo({ data: { tid } }).then(data => {
        changeSelectedValue(level, tid, label);
        updateMutiOptions(level, data);
        setShowLevel(level);
        setRequesting(false);
      });
    } else {
      changeSelectedValue(level, tid, label).then(selectedValue => {
        hide();
        onSelectEnd && onSelectEnd(selectedValue);
      });
    }
  }

  function changeSelectedValue(level, tid, label) {
    return new Promise((resolve, reject) => {
      setSelectedValue(arr => {
        let newArr = [...arr].map((val, idx) => {
          if (level - 1 === idx) return { tid, label };
          else if (level - 1 > idx) return val;
          return null;
        });
        resolve(newArr);
        return newArr;
      });
    });
  }

  const cascaderClickDom = (
    <div className="cascader-click-area" onClick={() => setShow(true)}></div>
  );

  const cascaderContent = (
    <div
      ref={cascaderRef}
      className={utils.cssBEM('cascader', { isMobile, isDesktop, requesting })}
    >
      <div className="cascader-nav">
        {selectedValue
          ?.filter(v => v)
          .map((val, idx) => (
            <span
              className={'cascader-nav-item' + (showLevel === idx ? ' active' : '')}
              onClick={() => setShowLevel(idx)}
              key={idx}
            >
              {val?.label}
            </span>
          ))}
        {showLevel === selectedValue?.filter(v => v).length && (
          <span className="cascader-nav-placeholder active">{placeholders[showLevel]}</span>
        )}
      </div>
      {mutiOptions?.map((options, idx) => (
        <CascaderLevel
          hidden={showLevel !== idx}
          key={idx}
          level={idx + 1}
          options={options}
          onClick={handleItemClick}
        />
      ))}
    </div>
  );

  const responsiveCascaderContent = isMobile ? (
    <Offcanvas show={show} placement="bottom">
      {cascaderContent}
    </Offcanvas>
  ) : (
    show && cascaderContent
  );

  return (
    <>
      {cascaderClickDom}
      {responsiveCascaderContent}
    </>
  );
}

const CascaderLevel = memo(({ options, level, onClick, hidden }) => (
  <div className="cascader-level" hidden={hidden}>
    {options?.map((item, idx) => {
      return (
        <div
          className="cascader-level-item"
          key={idx}
          data-level={level}
          data-tid={item.tid}
          data-label={item.label}
          onClick={onClick}
        >
          {item.label}
        </div>
      );
    })}
  </div>
));

export default memo(Cascader);
