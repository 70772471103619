import { memo } from 'react';
import './IconButton.scss';

import Icon from './Icon';

function IconButton({ className, ...props }) {
  const cName = className ? 'icon-button ' + className : 'icon-button';
  return (
    <button className={cName} type="button" {...props} tabIndex="-1">
      <Icon {...props} />
    </button>
  );
}

export default memo(IconButton);
