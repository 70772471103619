import AngleDecoration from './AngleDecoration';
import utils from '../utils';
import './LogoList.scss';

function LogoList(props) {
  return (
    <div className="logo-list">
      <AngleDecoration size="small" />
      {props.logo && (
        <div className="logo-list-item">
          <div className="inner">
            <img src={props.logo} alt="" />
          </div>
        </div>
      )}
      {props.architecture && (
        <div className="logo-list-item">
          <div className="inner">
            <img src={props.architecture} alt="" />
          </div>
        </div>
      )}
    </div>
  );
}

export default LogoList;
