import { memo, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../../utils/request';
import utils from '../../../utils';
import IconButton from '../../../components/IconButton';
import PageTitleBar from '../../../components/PageTitleBar';
import ListNoData from '../../../components/ListNoData';
import Badge from '../../../components/Badge';
import LocationText from '../../../components/LocationText';

function AddressList() {
  let [addressList, setAddressList] = useState({});
  const nav = useNavigate();

  useEffect(() => {
    api.addressBook.getList().then(data => {
      data.rows.forEach(item => {
        utils.formatKeyNoValue(item, '*');
        item.is_default *= 1;
      });
      setAddressList(data);
    });
  }, []);

  function navToDetail(state) {
    if (state.location) {
      state = { ...state, ...state.location };
      delete state.location;
    }
    nav(state.id, { state });
  }

  return (
    <>
      <PageTitleBar
        title="地址簿"
        rightEl={<IconButton icon="plus" fontSize="24px" onClick={() => nav('add')} />}
      />
      {addressList.rows && (
        <div className="d-grid gap-5">
          {addressList.rows?.length ? (
            addressList.rows.map(item => (
              <div className="card d-flex" key={item.id}>
                <div className="flex-grow-1">
                  <div className="d-flex align-items-center mb-1">
                    <span className="h5 mb-0 me-4">{item.to_name}</span>
                    {!!item.is_default && <Badge text="默认" />}
                  </div>
                  <div>
                    <div>{item.mobile}</div>
                    <LocationText {...item.location} />
                  </div>
                </div>
                <div className="d-flex align-items-center pe-1">
                  <IconButton
                    icon="arrow_right"
                    fontSize="24px"
                    onClick={() => navToDetail(item)}
                  />
                </div>
              </div>
            ))
          ) : (
            <ListNoData text="您还未设置收货地址。" action={<Link to="add">立即添加</Link>} />
          )}
        </div>
      )}
    </>
  );
}

export default memo(AddressList);
