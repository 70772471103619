import { memo, useState, useEffect, useRef, useMemo, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { isMobile } from 'react-device-detect';
import { Row, Col, Button } from 'react-bootstrap';
import { QRCodeSVG } from 'qrcode.react';
import api from '../../utils/request';
import PageContent from '../../components/PageContent';
import PageTitleBar from '../../components/PageTitleBar';
import LoadButton from '../../components/LoadButton';
import ImageCode from '../../components/ImageCode';
import WechatContext from '../../store/WechatContext';
import './Pay.scss';

function Pay() {
  const urlParams = useParams();
  const location = useLocation();
  const nav = useNavigate();
  const wechatCtx = useContext(WechatContext);
  const order_id = location?.state?.order_id || urlParams.orderId;

  let [payData, setPayData] = useState({});
  let [time, setTime] = useState(null); //倒计时时间
  let timeRef = useRef(); //设置延时器
  let [paymentStatus, setPaymentStatus] = useState('');

  useEffect(() => {
    if (order_id) {
      api.order
        .getPayment({
          data: {
            order_id,
            open_id: wechatCtx.wxOpenId,
          },
        })
        .then(data => {
          setPayData(data);
          if (data['payment_type'] === 'free') {
            setPaymentStatus('success');
          } else {
            setTime(data.refresh_time * 1);
          }
        })
        .catch(err => {
          window.alert(JSON.stringify(err));
          // nav(-1);
        });
    } else {
      nav(-1);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //倒计时
  useEffect(() => {
    if (time && time > 0) {
      timeRef.current = setTimeout(() => {
        setTime(time => time - 1);
        let allowRequest =
          paymentStatus === '' && timeRef.apiAwait !== true && payData.payment_plugin !== 'h5pay';
        if (allowRequest) {
          timeRef.apiAwait = true;
          api.order
            .checkPaymentCompleted({
              data: {
                payment: payData.payment,
              },
            })
            .then(({ status }) => {
              timeRef.apiAwait = false;
              if (status === 'success') setPaymentStatus(status);
            })
            .catch(err => {
              console.log(err);
            });
        }
      }, 1000);
    } else if (time && time <= 0) {
      nav(-1);
    }
    return () => {
      clearTimeout(timeRef.current);
    };
  }, [time]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCountDown = useMemo(() => {
    let m = parseInt((time / 60) % 60);
    let s = parseInt(time % 60);
    if (m < 10) m = '0' + m;
    if (s < 10) s = '0' + s;
    let M = m > 0 ? `${m} 分` : '';
    let S = `${s} 秒`;

    return [M, S].join(' ');
  }, [time]);

  const confirmPayment = () => {
    api.order
      .checkPaymentCompleted({
        data: {
          payment: payData.payment,
        },
      })
      .then(({ status }) => {
        setPaymentStatus(status);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const toOrderDetail = () => {
    nav('/user/order/' + order_id);
  };

  return (
    <PageContent
      loaded={payData.payment || paymentStatus === 'success'}
      className="page-pay"
      paddingTop
      paddingBotton
    >
      <Row>
        {!paymentStatus && payData.payment && (
          <Col className="text-center" xl={{ span: 6, offset: 3 }} lg={{ span: 8, offset: 2 }}>
            <PageTitleBar title="微信支付" />
            <p>
              {payData.commerce_message}
              <br />
              您还有 <strong>{getCountDown}</strong> 完成付款
              <br />
              超时订单自动关闭
            </p>
            <p>
              <strong>支付金额：¥{payData.price}</strong>
            </p>
            {isMobile ? (
              <MobilePay data={payData} />
            ) : (
              payData.payment_plugin.includes('qrcode') && (
                <ImageCode
                  className="my-10 mx-auto"
                  type="qr-code"
                  asImg={
                    payData.remote_state && (
                      <QRCodeSVG
                        size="210"
                        value={payData.remote_state}
                        style={{ padding: '8px' }}
                      />
                    )
                  }
                />
              )
            )}
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <div className="d-grid">
                  <LoadButton size="lg" onClick={confirmPayment}>
                    我已付款
                  </LoadButton>
                </div>
              </Col>
            </Row>
          </Col>
        )}

        {paymentStatus === 'success' && (
          <Col className="text-center" xl={{ span: 6, offset: 3 }} lg={{ span: 8, offset: 2 }}>
            <PageTitleBar title="支付成功" />
            <p>
              您可在“<strong>我的-订单</strong>”中查找订单，
              <br />
              或在“<strong>仓库</strong>”中查找相关门票。
            </p>
            <p>
              订单号：<strong>{payData.order_number}</strong>
            </p>
            <Row className="mt-10">
              <Col md={{ span: 6, offset: 3 }}>
                <div className="d-grid gap-5">
                  <Button variant="outline-primary" size="lg" onClick={toOrderDetail}>
                    查看订单
                  </Button>
                  <Button
                    variant="outline-primary"
                    size="lg"
                    onClick={() => {
                      nav('/user/warehouse');
                    }}
                  >
                    前往仓库
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        )}

        {paymentStatus === 'fail' && (
          <Col className="text-center" xl={{ span: 6, offset: 3 }} lg={{ span: 8, offset: 2 }}>
            <PageTitleBar title="支付失败" />
            <p>
              请重新进行支付，
              <br />
              或在“我的-订单”中继续完成支付。
            </p>
            <p>
              如您已经完成支付，请联系客服。
              <br />
              客服电话：+86 21 11111111
              <br />
              客服邮箱：XXXX@XXXX.com
            </p>
            <p>
              订单号：<strong>{payData.order_number}</strong>
            </p>
            <Row className="mt-10">
              <Col md={{ span: 6, offset: 3 }}>
                <div className="d-grid gap-5">
                  <Button variant="outline-primary" size="lg" onClick={() => setPaymentStatus('')}>
                    重新支付
                  </Button>
                  <Button variant="outline-primary" size="lg" onClick={toOrderDetail}>
                    查看订单
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </PageContent>
  );
}

const MobilePay = memo(({ data }) => {
  const urlParams = useParams();
  const location = useLocation();
  let { payment_plugin, remote_state } = data;
  console.log(data);

  useEffect(callPay);

  function callPay() {
    if (payment_plugin === 'jsapipay') {
      let wxPayParams = JSON.parse(remote_state);
      if (window.WeixinJSBridge) {
        window.WeixinJSBridge.invoke('getBrandWCPayRequest', wxPayParams, function (res) {
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          }
        });
      }
    } else if (payment_plugin === 'h5pay') {
      if (data.payment_redirect) {
        let newHref = `${data.payment_redirect}&redirect_url=${encodeURIComponent(
          window.location.href + (urlParams.orderId ? '' : '/' + location?.state?.order_id),
        )}`;
        window.location.href = newHref;
      }
    }
  }

  return (
    <div className="d-grid mt-10 mb-5">
      <Button variant="outline-primary" size="lg" onClick={callPay}>
        继续支付
      </Button>
    </div>
  );
});

export default memo(Pay);
