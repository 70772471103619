import { useEffect } from 'react';

function useOnClickOutside(ref, handler, evType) {
  const events = ['mousedown', 'touchstart', evType];

  useEffect(() => {
    const listener = ev => {
      if (!ref.current || ref.current.contains(ev.target)) {
        return;
      }
      handler(ev);
    };
    events.forEach(ev => document.addEventListener(ev, listener));

    return () => {
      events.forEach(ev => document.removeEventListener(ev, listener));
    };
  }, [ref, handler]); // eslint-disable-line react-hooks/exhaustive-deps
}

export default useOnClickOutside;
