import { memo, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import PageTitleBar from '../../components/PageTitleBar';
import PageContent from '../../components/PageContent';
import OrderProdItem from '../../components/OrderProdItem';
import api from '../../utils/request';
import utils from '../../utils/index';
import './OrderDetail.scss';

function OrderList() {
  const urlParams = useParams();
  const nav = useNavigate();
  const [order_id, setId] = useState(urlParams.id);
  const [reflash, setReflash] = useState(1);
  let [data, setData] = useState({});

  useEffect(() => {
    api.user
      .getOrderDetail({ data: { order_id } })
      .then(detail => {
        utils.formatKeyNoValue(detail, '*');
        detail.stateText = formatStatus(detail.state);
        detail.order_time = utils.DateFormat(new Date(detail.created * 1000), 'yyyy.MM.dd hh:mm');
        detail.address =
          detail.billing_profile?.location_geo_level_1?.label +
          detail.billing_profile?.location_geo_level_2?.label +
          detail.billing_profile?.location_geo_level_3?.label +
          detail.billing_profile?.location_address;
        setData(detail);
      })
      .catch(err => {
        setData({ notFound: true });
      });
  }, [order_id, reflash]);

  useEffect(() => {
    setId(urlParams.id);
  }, [urlParams.id]);

  const formatStatus = state => {
    return {
      completed: '已完成',
      draft: '待付款',
      canceled: '已取消',
    }[state];
  };

  const cancelOrder = order_id => {
    if (order_id) {
      api.order.cancel({ data: { order_id } }).then(res => {
        setReflash(reflash + 1);
      });
    }
  };

  const getTitleBarControl = state => {
    if (state === 'draft') {
      return (
        <div className="control">
          <Button variant="link" size="sm" onClick={() => cancelOrder(data.order_id)}>
            取消订单
          </Button>
          <Button size="sm" onClick={() => nav(`/buy/pay/${data.order_id}`)}>
            去付款
          </Button>
        </div>
      );
    }
  };

  return (
    <PageContent loaded={data.order_id}>
      <PageTitleBar title={data.stateText} rightEl={getTitleBarControl(data.state)} />
      <div className="order-detail-msg">
        <Row className="baseinfo-row">
          <Col lg={6}>
            {data.order_id && (
              <div className="order-detail-msg__item">
                <h4>订单号：</h4>
                <p>#{data.order_id}</p>
              </div>
            )}
            {data.order_time && (
              <div className="order-detail-msg__item">
                <h4>订单日期：</h4>
                <p>{data.order_time}</p>
              </div>
            )}
          </Col>
          <Col lg={6}>
            <div>
              {data.billing_profile && (
                <div className="order-detail-msg__item">
                  <h4>收货地址：</h4>
                  <p>{data.billing_profile?.to_name}</p>
                  <p>{data.billing_profile?.mobile}</p>
                  <p>{data.address}</p>
                </div>
              )}
              {data.billing_profile?.courier_number && (
                <div className="order-detail-msg__item">
                  <h4>快递单号：</h4>
                  <p>{data.billing_profile?.courier_number}</p>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <hr />
      <div className="order-detail-content">
        <h3 className="order-detail-content__header">商品清单</h3>
        {data.items && <OrderProdItem list={data.items}></OrderProdItem>}
      </div>
      <hr />
      <div className="order-detail-footer">
        <div className="total">
          <p>小计:¥{data.order_total_summary?.subtotal}</p>
          {data.order_total_summary?.adjustments.length ? (
            <div>
              {data.order_total_summary?.adjustments.length &&
                data.order_total_summary?.adjustments.map(item => {
                  return (
                    <p>
                      {item.label}:-¥{Math.abs(item.amount)}
                    </p>
                  );
                })}
            </div>
          ) : (
            ''
          )}
          <p className="bold">总计：¥{data.total_price}</p>
        </div>
      </div>
    </PageContent>
  );
}

export default memo(OrderList);
