import React, { useState, useEffect } from 'react';
import api from '../utils/request';
import utils from '../utils';

const AuthContext = React.createContext({
  isLoggedIn: false,
});

const AuthContextProvider = props => {
  let [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')));
  let [isLoggedIn, setIsLoggedIn] = useState(!!userInfo);
  let [isInputAuth, setIsInputAuth] = useState(false);
  let [code] = useState(new URL(window.location).searchParams.get('code'));

  useEffect(() => {
    if (!code) facthLoginInfo();
  }, [code]); // eslint-disable-line react-hooks/exhaustive-deps

  function facthLoginInfo() {
    api.user
      .loginInfo()
      .then(user => {
        if (user.nickname) {
          setIsLoggedIn(true);
          utils.formatKeyNoValue(user, '*');
          user.wechat_binding = user.wechat_binding > 0;
          user._mobile = user.mobile
            ? user.mobile.substring(0, 3) + ' **** ' + user.mobile.substr(user.mobile.length - 4)
            : '';
          setUserInfo(user);
          localStorage.setItem('userInfo', JSON.stringify(user));
        } else {
          logout(false);
        }
      })
      .catch(err => {
        logout(false);
      });
  }

  function logout(request = true) {
    setIsLoggedIn(false);
    setIsInputAuth(false);
    setUserInfo(null);
    localStorage.removeItem('userInfo');
    request && api.user.logout();
  }

  const contextValue = {
    isLoggedIn,
    isInputAuth,
    setIsInputAuth,
    userInfo,
    facthLoginInfo,
    logout,
  };

  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;
export { AuthContextProvider };
