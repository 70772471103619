import { memo, useState, useEffect } from 'react';
import seo from '../../utils/seo';
import api from '../../utils/request';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import utils from '../../utils';
import PageContent from '../../components/PageContent';
import ClipImage from '../../components/ClipImage';
import OssImg from '../../components/OssImg';
import Cms from '../../components/Cms';
import Dots from '../../components/Dots';
import Relevant from '../../components/Relevant';
import FilterTypeNav from '../../components/FilterTypeNav';
import PropsList from '../../components/PropsList';
import ActivityListCom from '../../components/ActivityListCom';
import Badge from '../../components/Badge';
import LogoList from '../../components/LogoList';
import './Detail.scss';

function SectDetail(props) {
  let urlParams = useParams();
  const [id, setId] = useState(urlParams.id);
  let [data, setData] = useState({});
  let [cmsBlocks, setCmsBlocks] = useState([]);
  let [relatedProps, setRelatedProps] = useState({ props: [] });
  let [relatedActivities, setRelatedActivities] = useState({});
  let [propsListFilter, setPropsListFilter] = useState({});

  useEffect(() => {
    api.sect
      .getDetail({ data: { id } })
      .then(detail => {
        setData(detail);
        setCmsBlocks(JSON.parse(detail.image_text.value));
        let { seo_title, seo_description, seo_keyword } = detail;
        seo.setContent({
          title: seo_title.value,
          description: seo_description.value,
          keywords: seo_keyword.value,
        });
      })
      .catch(err => {
        setData({ notFound: true });
      });
    api.sect.getRelatedProps({ data: { id } }).then(relatedProps => {
      relatedProps.props.forEach(item => {
        utils.formatKeyNoValue(item, '*');
      });
      setRelatedProps(relatedProps);
    });
    api.sect.getRelatedActivities({ data: { id } }).then(relatedActivities => {
      setRelatedActivities(relatedActivities);
    });
    return () => {
      console.log('seo.back()');
      seo.back();
    };
  }, [id]);

  useEffect(() => {
    setId(urlParams.id);
  }, [urlParams.id]);

  return (
    <PageContent loaded={data.id}>
      <Row className="baseinfo-row">
        <Col lg={5}>
          <div className="baseinfo-row__poster">
            <ClipImage noStyle>
              <OssImg
                src={data?.key_visual?.value}
                alt={data?.title?.value}
                optionsName="lg"
                options={{ format: 'png' }}
              />
            </ClipImage>
          </div>
        </Col>
        <Col lg={{ span: 4, offset: 1 }}>
          <div className="baseinfo-row__info">
            <div className="sect_hader">
              <h3>{data?.title?.value}</h3>
              <div className="badge-list">
                {data.notes?.value?.length ? (
                  data.notes.value.map((item, idx) => (
                    <Badge color="black" text={item} key={idx}></Badge>
                  ))
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <table className="table--info">
              <tbody>
                <tr>
                  <th>掌门人：</th>
                  <td dangerouslySetInnerHTML={{ __html: data?.headmaster?.value }}></td>
                </tr>
                <tr>
                  <th>门派曲目：</th>
                  <td dangerouslySetInnerHTML={{ __html: data?.sect_tracks?.value }}></td>
                </tr>
                <tr>
                  <th>门派位置：</th>
                  <td dangerouslySetInnerHTML={{ __html: data?.sect_location?.value }}></td>
                </tr>
                <tr>
                  <th>门派招式：</th>
                  <td dangerouslySetInnerHTML={{ __html: data?.sect_moves?.value }}></td>
                </tr>
                <tr>
                  <th>门派乐器：</th>
                  <td dangerouslySetInnerHTML={{ __html: data?.menpaiyueqi?.value }}></td>
                </tr>
              </tbody>
            </table>
            <div className="mt-8">
              <LogoList
                logo={data?.logo?.value}
                architecture={data?.architecture?.value}
              ></LogoList>
            </div>
          </div>
        </Col>
      </Row>
      {!!cmsBlocks?.length && (
        <>
          <Dots />
          <Cms col={{ lg: { span: 8, offset: 2 } }} blocks={cmsBlocks} />
        </>
      )}
      <Relevant show={relatedProps.props.length || relatedActivities.length}>
        <Relevant.Section show={relatedActivities.length}>
          <Relevant.SectionHeader title="相关活动" />
          <Relevant.SectionBody>
            <ActivityListCom list={relatedActivities} noYearTitle={true} />
          </Relevant.SectionBody>
        </Relevant.Section>
        <Relevant.Section show={relatedProps.props.length}>
          <Relevant.SectionHeader title="相关卡牌" />
          <Relevant.SectionBody>
            <FilterTypeNav nav={relatedProps.prop_types} setFilter={setPropsListFilter} />
            <PropsList list={relatedProps.props} filter={propsListFilter} />
          </Relevant.SectionBody>
        </Relevant.Section>
      </Relevant>
    </PageContent>
  );
}

export default memo(SectDetail);
