import { memo, useEffect } from 'react';
import Loading from './Loading';
import utils from '../utils';
import './PageContent.scss';

const PageContent = props => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let loaded = props.hasOwnProperty('loaded') ? props.loaded : true;
  let { paddingTop: pt, paddingBotton: pb } = props;
  let cName = utils.cssBEM('page-content', {
    pt,
    pb,
  });
  if (props.className) cName += ' ' + props.className;

  return loaded ? <div className={cName}>{props.children}</div> : <Loading />;
};

export default memo(PageContent);
