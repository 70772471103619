import { memo, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PageTitleBar from '../../components/PageTitleBar';
import PageContent from '../../components/PageContent';
import api from '../../utils/request';
import OrderItem from '../../components/orderItem';
import utils from '../../utils/index';
import ListNoData from '../../components/ListNoData';
import InfiniteScroll from 'react-infinite-scroller';

function OrderList(props) {
  let [data, setData] = useState({});
  let [page, setPage] = useState(0);
  let [isMore, setIsMore] = useState(true);
  const [reflash, setReflash] = useState(1);
  let [order_list, setList] = useState([]);
  useEffect(() => {
    api.user
      .getOrders({ data: { page } })
      .then(detail => {
        setData(detail);
        setIsMore(isMore => {
          const result = detail.rows.length ? true : false;
          return result;
        });
        setList(order_list => {
          let newList;
          if (page === 0) {
            newList = [...detail.rows];
          } else {
            newList = order_list.concat(detail.rows);
          }
          return newList;
        });
      })
      .catch(err => {
        setData({ notFound: true });
      });
  }, [page, reflash]);

  const cancelOrder = order_id => {
    if (order_id) {
      api.order.cancel({ data: { order_id } }).then(res => {
        setPage(0);
        setReflash(reflash + 1);
      });
    }
  };

  const loadFunc = () => {
    if (isMore) {
      setPage(page + 1);
      setIsMore(false);
    }
  };

  return (
    <PageContent loaded={data.rows}>
      <PageTitleBar title="我的订单" />
      <InfiniteScroll
        pageStart={0}
        loadMore={loadFunc}
        hasMore={isMore}
        loader={
          <div className="loader" key={0}>
            Loading ...
          </div>
        }
      >
        <>
          {order_list?.length ? (
            <OrderListCom list={order_list}></OrderListCom>
          ) : (
            <ListNoData text="目前无订单。" action={<Link to="/activity">立即下单</Link>} />
          )}
        </>
      </InfiniteScroll>
    </PageContent>
  );

  function OrderListCom(props) {
    return (
      <div>
        {props.list.map((item, index) => {
          return <OrderItem key={index} order={item} cancelOrder={cancelOrder}></OrderItem>;
        })}
      </div>
    );
  }
}

export default memo(OrderList);
