import { memo, useEffect, useContext } from 'react';
import queryString from 'query-string';
import api from '../utils/request';
import WechatContext from '../store/WechatContext';
import AngleDecoration from './AngleDecoration';
import './WxLogin.scss';

const WxLogin = ({
  className,
  redirectUri = window.location.href,
  // redirectUri = 'http://neowulin.com/',
  nonEmbedded = false,
  placeholder = '微信登录中',
  succeeded,
  fail,
}) => {
  const wechatCtx = useContext(WechatContext);
  let outClassName = '';
  if (className) outClassName = ` ${className}`;

  useEffect(() => {
    if (wechatCtx.isWechat) {
      // 微信浏览器
      if (wechatCtx.wxUnionId) {
        wechatCtx
          .wxUnionIdLogin()
          .then(() => {
            succeeded && succeeded();
          })
          .catch(() => {
            fail && fail();
          });
      } else {
        // 微信端，无unionId 去开放平台授权拿code
        const newHref = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
          wechatCtx.wxOfficialAccountAppId
        }&redirect_uri=${encodeURIComponent(
          // removeUrlSearchParams(window.location.href),
          removeUrlSearchParams(redirectUri),
        )}&response_type=code&scope=snsapi_userinfo&state=#wechat_redirect`;
        window.location.href = newHref;
      }
    } else {
      // 非微信浏览器
      api.wechat.getOauthUrl().then(({ uri }) => {
        const urlSearchParams = uri.substring(uri.indexOf('?'));
        if (!urlSearchParams) return;
        const parsed = queryString.parse(urlSearchParams);
        if (redirectUri === true) {
          parsed.redirect_uri = removeUrlSearchParams(redirectUri);
          // parsed.redirect_uri = removeUrlSearchParams(window.location.href);
        } else if (typeof redirectUri === 'string') {
          parsed.redirect_uri = redirectUri.replace(/^https:\/\/www\./, 'https://');
          console.log(parsed.redirect_uri, 'parsed.redirect_uri');
        }
        if (nonEmbedded) {
          let newHrefParams = queryString.stringify(parsed);
          window.location.href = `https://open.weixin.qq.com/connect/qrconnect?${newHrefParams}`;
        } else {
          new window.WxLogin({
            ...parsed,
            id: 'wx-login-content',
          });
        }
      });
    }
  }, [nonEmbedded, redirectUri]); // eslint-disable-line react-hooks/exhaustive-deps

  const removeUrlSearchParams = url => {
    let newUrl = new URL(url);
    newUrl.search = '';
    return newUrl.href;
  };

  return nonEmbedded || wechatCtx.isWechat ? (
    <p>{placeholder}</p>
  ) : (
    <div className={'wx-login' + outClassName}>
      <AngleDecoration />
      <div id="wx-login-content"></div>
    </div>
  );
};

export default memo(WxLogin);
