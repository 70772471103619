import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../store/AuthContext';

function LandingPage() {
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const freeActivityUrl = localStorage.getItem('freeActivityUrl') || '/activity';
  const redirectUri = new URL(window.location.href).origin + freeActivityUrl;
  // const redirectUri = 'https://neowulin.com' + freeActivityUrl;
  useEffect(() => {
    if (isLoggedIn) {
      navigate(freeActivityUrl);
    } else {
      navigate('/user/login', {
        state: { redirectUri, freeActivityUrl, loginMethod: 2 },
      });
    }
  }, []);
}

export default LandingPage;
