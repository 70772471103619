import { memo, useState, useEffect, useCallback, useMemo, useRef, useContext } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import seo from '../utils/seo';
import AuthContext from '../store/AuthContext';
import { ReactComponent as Logo } from '../assets/images/logo-text_beta.svg';
import Icon from '../components/Icon';
import Dots from '../components/Dots';
import './AppHeader.scss';
import { set } from 'react-hook-form';

const detectLinkClass = url => (url.includes('nolink') ? 'pe-none' : '');

const Brand = memo(props => (
  <Link className="app-header-brand" to="/">
    <h1>{props.title}</h1>
    <Logo />
  </Link>
));

const Nav = memo(props => {
  const cName = 'app-header-nav';
  const [navData, setNavData] = useState(props.data);
  const authCtx = useContext(AuthContext);
  const handleMouseEnter = useCallback(i => {
    if (!isMobile) {
      setNavData(navData => {
        navData[i].visibleSub = true;
        return [...navData];
      });
    }
  }, []);
  const handleMouseLeave = useCallback(i => {
    setNavData(navData => {
      navData[i].visibleSub = false;
      return [...navData];
    });
  }, []);
  const handleClick = useCallback(i => {
    if (isMobile) {
      setNavData(navData => {
        navData[i].visibleSub = !navData[i].visibleSub;
        return [...navData];
      });
    }
  }, []);
  return (
    <ul className={cName}>
      {navData.map((item, i) => (
        <NavItem
          {...item}
          key={i}
          onMouseEnter={() => handleMouseEnter(i)}
          onMouseLeave={() => handleMouseLeave(i)}
          onClick={() => handleClick(i)}
        />
      ))}
      {authCtx.userInfo ? (
        <li className={`${cName}__item`}>
          <NavLink
            to="/user"
            style={({ isActive }) => {
              isActive && setSeo('账户');
            }}
          >
            <span className="d-sm-none">账户</span>
            <span className="d-none d-sm-flex">{authCtx.userInfo.nickname}</span>
          </NavLink>
        </li>
      ) : (
        <li className={`${cName}__item ${cName}__item--user`}>
          <NavLink
            to="/user"
            style={({ isActive }) => {
              isActive && setSeo('登录 / 注册');
            }}
          >
            <>
              <span className="d-sm-none">登录 / 注册</span>
              <span className="d-none d-sm-inline">登录</span>
            </>
          </NavLink>
        </li>
      )}
      {/* <li className={`${cName}__item ${cName}__item--user`}>
        <NavLink to="/user">
          <Icon icon="key" fontSize="24px" />
          {authCtx.userInfo ? (
            <span className="nickname text-truncate">{authCtx.userInfo.nickname}</span>
          ) : (
            <>
              <span className="d-sm-none">登录 / 注册</span>
              <span className="d-none d-sm-inline">登录</span>
            </>
          )}
        </NavLink>
      </li> */}
    </ul>
  );
});

const SubNav = memo(props => {
  const cName = 'app-header-subnav';
  const subnav = useRef();
  const stateClassName = useMemo(() => {
    if (props.visibleSub === true) {
      return `${cName}--show`;
    } else if (props.visibleSub === false) {
      setTimeout(() => {
        subnav.current?.classList.remove(`${cName}--hide`);
      }, 300);
      return `${cName}--hide`;
    } else return '';
  }, [props.visibleSub]);
  if (props.data.length && props.data.length > 0) {
    return (
      <ul className={cName + ' ' + stateClassName} ref={subnav}>
        {props.data.map((item, i) => (
          <NavItem inSubNav {...item} key={i} />
        ))}
      </ul>
    );
  }
});

const setSeo = function (title) {
  seo.setContent({
    title: title,
  });
};

const NavItem = memo(props => {
  const { url, title, subtree, inSubNav, icon, visibleSub } = props;
  const { pathname } = useLocation();
  const customActive = url => {
    if (url.includes('/user')) return '';
    if (url.substring(0, 5) === pathname.substring(0, 5)) return 'active';
  };
  if (inSubNav) {
    return (
      <li className="app-header-nav__item">
        <NavLink to={url} className={detectLinkClass(url)}>
          {icon && <Icon icon={icon} fontSize="24px" />}
          {title}
        </NavLink>
      </li>
    );
  } else if (subtree?.length > 0) {
    return (
      <li
        className="app-header-nav__item"
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        onClick={props.onClick}
      >
        <NavLink
          to={url}
          className={() => detectLinkClass(url) + customActive(url)}
          style={({ isActive }) => {
            isActive && setSeo(title);
          }}
        >
          {title}
        </NavLink>
        <SubNav data={subtree} visibleSub={visibleSub} />
      </li>
    );
  } else {
    return (
      <li className="app-header-nav__item">
        <NavLink
          to={url}
          className={detectLinkClass(url) + customActive(url)}
          style={({ isActive }) => {
            isActive && setSeo(title);
          }}
        >
          {title}
        </NavLink>
      </li>
    );
  }
});

const Toggler = memo(props => (
  <button className="app-header-toggler" type="button" {...props}>
    <span className="app-header-toggler__line"></span>
    <span className="app-header-toggler__line"></span>
    <span className="app-header-toggler__line"></span>
  </button>
));

const AppHeader = memo(function (props) {
  const location = useLocation();
  let [navData, setNavData] = useState(null);
  let [mobileOpenNav, setMobileOpenNav] = useState(false);
  const cName = 'app-header';
  const cNameState = useMemo(
    () => (mobileOpenNav ? cName + '--open-nav' : cName + '--close-nav'),
    [mobileOpenNav],
  );

  useEffect(() => {
    mobileOpenNav && togglerClick();
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.menu) setNavData(props.menu);
  }, [props.menu]);

  const togglerClick = useCallback(() => {
    if (mobileOpenNav) document.body.classList.remove('scroll-lock');
    else document.body.classList.add('scroll-lock');
    setMobileOpenNav(v => !v);
  }, [mobileOpenNav]);

  return (
    <header className={cName + ' ' + cNameState}>
      <nav className="app-header-container container-xxl">
        <div className="app-header-container__left">
          <Brand title="新武林" />
        </div>
        <div className="app-header-container__right">
          {navData && <Nav data={navData} />}
          <Toggler onClick={togglerClick} />
        </div>
      </nav>
      <Dots />
    </header>
  );
});

export default AppHeader;
