import { memo } from 'react';
import './ClipImage.scss';

const ClipImage = memo(({ children, noStyle, src, title, alt, small, onClick, ...props }) => {
  const cName = 'clip-image';
  let cTypeName = props.className || '';
  if (small) cTypeName += ' clip-image--sm';
  if (noStyle) cTypeName += ' clip-image--no-style';

  return (
    <div
      className={`${cName} ${cTypeName}`}
      onClick={() => {
        onClick && onClick();
      }}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
    >
      {noStyle ? '' : <div className={`${cName}__top`}></div>}
      {children ? children : <img src={src} alt={alt} title={title} />}
      {noStyle ? '' : <div className={`${cName}__bottom`}></div>}
    </div>
  );

  /*
  if (props.children) {
    return (
      <div
        className={`${cName} ${cTypeName}`}
        onClick={() => {
          props.onClick && props.onClick();
        }}
        style={{ cursor: props.onClick ? 'pointer' : 'default' }}
      >
        {props.children}
      </div>
    );
  } else {
    let { src, title, alt, onClick } = props;
    return (
      <img
        className={`${cName} ${cTypeName}`}
        src={src}
        alt={alt}
        title={title}
        onClick={() => {
          onClick && onClick();
        }}
        style={{ cursor: onClick ? 'pointer' : 'default' }}
      />
    );
  }
  */
});

export default ClipImage;
