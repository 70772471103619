import { memo } from 'react';
import utils from '../utils';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import PxyEntry from './PxyEntry';
import './markerList.scss';

const markerList = memo(props => {
  return (
    <div className="marker-list">
      {props.list.map(item => {
        utils.formatKeyNoValue(item, '*');
        const { id } = item;
        return <MarkerListItem key={id} {...item}></MarkerListItem>;
      })}
    </div>
  );
});

function getMarkerTimeShow(entry_time, end_time, current_time) {
  let time_obj;
  let time_text;
  let time_status;
  let show_time_text;
  entry_time = Number(entry_time * 1000);
  end_time = Number(end_time * 1000);
  current_time = Number(current_time * 1000);
  if (entry_time > current_time) {
    // 活动还未开始
    time_obj = utils.DateDifference(current_time, entry_time);
    time_status = 0;
  } else if (entry_time < current_time && end_time > current_time) {
    // 活动进行中
    time_text = '现在入场';
    time_status = 1;
  } else if (end_time < current_time) {
    // 活动已结束
    time_text = '已结束';
    time_status = -1;
  }
  if (time_status === 0) {
    time_text = `距入场 ${time_obj.days} 天 ${time_obj.hours} 小时 ${time_obj.minutes} 分钟`;
  }
  show_time_text = {
    time_text: time_text,
    time_status: time_status,
  };
  return show_time_text;
}

function MarkerListItem(props) {
  utils.formatKeyNoValue(props, '*');
  const nav = useNavigate();
  const {
    id,
    nid,
    name,
    description,
    prop_type,
    kv,
    order_number,
    entry_time,
    end_time,
    current_time,
    field_type,
    field_appli_id,
    field_queue_server_address,
    field_render_server_address,
  } = props;
  return (
    <div className="marker-list-item">
      <div className="marker-list-item__content">
        <div className="marker-list-item__kv">
          <div className="pic">
            <img src={kv} alt="" onClick={() => nav('/prop/' + nid)} />
          </div>
        </div>
        <div className="marker-list-item__info">
          <div className="marker-list-item__top">
            <h4 className="marker-label">{prop_type}</h4>
            <h3 className="markeer-name" onClick={() => nav('/prop/' + nid)}>
              {name}
            </h3>
            {description ? <p className="marker-desc">{description}</p> : ''}
            <div className="order-msg">
              <p className="order-msg-info">来自订单: #{order_number}</p>
              {description ? <p className="marker-desc-mobile">{description}</p> : ''}
              {nid ? <Link to={`/prop/${nid}`}>道具详情</Link> : ''}
            </div>
          </div>
          <div className="marker-list-item__bottom">
            <PxyEntry
              btnVariant="primary"
              id={id}
              renderType={field_type}
              appliId={field_appli_id}
              serverAddress={field_queue_server_address}
              renderAddress={field_render_server_address}
              now={current_time}
              entryTime={entry_time}
              endTime={end_time}
              endText={<div className="time">已结束</div>}
              countDownClassName="time"
            />
            {/* {getMarkerTimeShow(entry_time, end_time, current_time).time_status === 1 ? (
              <Button variant="primary" onClick={() => nav('/play/' + id)}>
                {getMarkerTimeShow(entry_time, end_time, current_time).time_text}
              </Button>
            ) : (
              <div className="time">
                {getMarkerTimeShow(entry_time, end_time, current_time).time_text}
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default markerList;
