import { memo } from 'react';
import AngleDecoration from './AngleDecoration';
import OssImg from './OssImg';
import './ItemQuantity.scss';

function ItemQuantity({ imgSrc, attributes }) {
  const cName = 'item-quantity';

  if (imgSrc || attributes) {
    return (
      <div className={cName}>
        {imgSrc && (
          <div className={`${cName}__media`}>
            <div className="ratio ratio-3x4">
              <AngleDecoration size="medium" />
              <div className="p-2 d-flex align-items-center">
                <div className="ratio ratio-1x1">
                  <OssImg src={imgSrc} optionsName="sm" />
                </div>
              </div>
            </div>
          </div>
        )}
        {attributes && (
          <div className={`${cName}__info`}>
            {Array.isArray(attributes)
              ? attributes.map((item, idx) => <div key={idx}>{item}</div>)
              : ''}
          </div>
        )}
      </div>
    );
  }
}

export default memo(ItemQuantity);
