import { memo, useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import KeepAlive from 'react-activation';
import { Button, Form, Row, Col } from 'react-bootstrap';
import api from '../../utils/request';
import AuthContext from '../../store/AuthContext';
import WechatContext from '../../store/WechatContext';
import PageTitleBar from '../../components/PageTitleBar';
import alert from '../../components/alert';
import InputTogglePassword from '../../components/form/InputTogglePassword';
import InputMessageCode from '../../components/form/InputMessageCode';
import LoadButton from '../../components/LoadButton';
import InvalidFeedback from '../../components/form/InvalidFeedback';
import WxLogin from '../../components/WxLogin';

const loginMethod = ['password', 'code', 'wechat'];

function UserLogin() {
  const { state } = useLocation();
  const nav = useNavigate();
  const navType = useNavigationType();
  const authCtx = useContext(AuthContext);
  const wechatCtx = useContext(WechatContext);
  let [submitLoading, setSubmitLoading] = useState(false);
  let [loginMethodIndex, setLoginMethodIndex] = useState(state?.loginMethod || 0);
  let [sessionId, setSessionId] = useState('');
  let [wechatQrCode, setWechatQrCode] = useState('');
  const loginMethodIs = type => loginMethod[loginMethodIndex] === type;
  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    setFocus,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (state?.phoneNumber) setValue('user_login_id', state.phoneNumber);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setValue('login_way', loginMethod[loginMethodIndex]);
    if (loginMethod[loginMethodIndex] === 'wechat') {
      if (!wechatQrCode) {
        // api.wechat.generateQrCode().then(data => {
        //   let { session_id, uri } = data;
        //   setWechatQrCode(uri);
        //   setSessionId(session_id);
        // });
      }
    }
  }, [loginMethodIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   let timer, timeout;
  //   if (loginMethod[loginMethodIndex] === 'wechat') {
  //     timeout = setTimeout(() => {
  //       timer = setInterval(() => {
  //         api.wechat
  //           .checkLogin({ data: { session_id: sessionId } })
  //           .then(data => {
  //             clearInterval(timer);
  //             authCtx.facthLoginInfo();
  //             setTimeout(() => alert.success(data.message), 200);
  //             nav(-1);
  //             authCtx.setIsInputAuth(true);
  //             console.log('登录成功', data);
  //           })
  //           .catch(err => {
  //             console.log('登录失败', err);
  //           });
  //       }, 1000);
  //     }, 2000);
  //   }
  //   return () => {
  //     clearTimeout(timeout);
  //     clearInterval(timer);
  //   };
  // }, [loginMethodIndex, sessionId]); // eslint-disable-line react-hooks/exhaustive-deps

  const cNameIsInvalid = key => (errors[key] ? 'is-invalid' : '');

  async function getSmsCode() {
    const phoneNumberIsValid = await trigger('user_login_id', { shouldFocus: true });
    if (phoneNumberIsValid) {
      const phoneNumber = getValues('user_login_id');
      const trigger_element_id = getValues('trigger_element_id');
      const msg = await api.common
        .sendVerificationCode({
          data: {
            message_gateway_type: 'sms',
            recipient: `+86 ${phoneNumber}`,
            trigger_element_id,
          },
        })
        .catch(err => {
          return err.data;
        });
      setTimeout(() => setFocus('code'), 100);
      return msg;
    }
  }

  async function onSubmit(data) {
    setSubmitLoading(true);
    await api.user
      .login({
        data,
      })
      .then(data => {
        authCtx.facthLoginInfo();
        setTimeout(() => alert.success(data.message), 200);
        if (navType === 'PUSH') nav(-1);
      })
      .catch(err => requestError(err));
    setSubmitLoading(false);
  }

  function requestError(error) {
    if (error.data) {
      error.data.error_keys.forEach(key => {
        setError(key);
        alert.danger(error.message, key);
      });
    }
  }

  function toRegister(e) {
    e.preventDefault();
    const phoneNumber = getValues('user_login_id');
    nav('/user/register', { state: { phoneNumber }, replace: true });
  }

  return (
    <>
      <PageTitleBar title="登录" />
      {(loginMethodIs('password') || loginMethodIs('code')) && (
        <Form className="mb-3" onSubmit={handleSubmit(onSubmit)}>
          <input {...register('login_way')} type="hidden" />
          <input {...register('type', { value: 'mobile' })} type="hidden" />
          {/* <KeepAlive cacheKey="phoneNumber"> */}
          <Form.Floating className="mb-5">
            <Form.Control
              {...register('user_login_id', {
                required: '请填写手机号',
                pattern: {
                  value: /^1[3456789]\d{9}$/,
                  message: '手机号错误',
                },
              })}
              className={cNameIsInvalid('user_login_id')}
              type="tel"
              autoComplete="phone"
              placeholder=" "
            />
            <Form.Label>手机号</Form.Label>
            <InvalidFeedback message={errors?.user_login_id?.message} />
          </Form.Floating>
          {/* </KeepAlive> */}
          {loginMethodIs('password') ? (
            // <KeepAlive cacheKey="login-method_password">
            <>
              <InputTogglePassword
                register={register('password', {
                  required: '请填写登录密码',
                })}
                autoComplete="current-password"
                className={cNameIsInvalid('password')}
                label="密码"
              >
                <InvalidFeedback message={errors?.password?.message} />
              </InputTogglePassword>
              <div className="text-end mt-2">
                <Link to="/user/forgot-password">忘记密码</Link>
              </div>
            </>
          ) : (
            // </KeepAlive>
            loginMethodIs('code') && (
              <KeepAlive cacheKey="login-method_code">
                <InputMessageCode
                  register={register('code', { required: '请填写验证码' })}
                  className={cNameIsInvalid('code')}
                  label="验证码"
                  buttonText="获取验证码"
                  phoneNumber=""
                  countDown="60"
                  buttonOnClick={getSmsCode}
                />
                <input
                  {...register('trigger_element_id', { value: 'login_get_sms_code' })}
                  type="hidden"
                />
              </KeepAlive>
            )
          )}
          <Row className="mt-10">
            <Col md={6}>
              <div className="d-grid">
                <LoadButton loading={submitLoading} variant="primary" size="lg" type="submit">
                  登录
                </LoadButton>
              </div>
            </Col>
          </Row>
        </Form>
      )}
      {loginMethodIs('wechat') && (
        <Row>
          <Col md={6}>
            {/* <ImageCode className="mb-8" type="qr-code" src={wechatQrCode} /> */}
            <WxLogin
              succeeded={() => {
                nav(state?.freeActivityUrl);
              }}
              redirectUri={state?.redirectUri}
              className="mb-3"
              fail={() => setLoginMethodIndex(0)}
            />
          </Col>
        </Row>
      )}
      <div>
        还不是会员？
        <Link to="/user/register" onClick={toRegister}>
          立即注册
        </Link>
      </div>
      <hr />
      <Row>
        <Col md={6}>
          <div className="d-grid gap-3">
            <Button
              className={loginMethodIs('password') ? 'd-none' : ''}
              variant="outline-primary"
              size="lg"
              onClick={() => setLoginMethodIndex(0)}
            >
              密码登录
            </Button>
            <Button
              className={loginMethodIs('code') ? 'd-none' : ''}
              variant="outline-primary"
              size="lg"
              onClick={() => setLoginMethodIndex(1)}
            >
              短信验证码登录
            </Button>
            <Button
              className={
                loginMethodIs('wechat') ? 'd-none' : wechatCtx.isWechat ? '' : 'd-none d-sm-block'
              }
              variant="outline-primary"
              size="lg"
              onClick={() => setLoginMethodIndex(2)}
            >
              微信登陆
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default memo(UserLogin);
