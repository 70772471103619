function getScrollbarWidth() {
  let oDiv = document.createElement('div');
  oDiv.style.cssText =
    'position:absolute;box-sizing:content-box;width:100px;height:100px;overflow:hidden;';
  let noScroll = document.body.appendChild(oDiv).clientWidth;
  oDiv.style.overflowY = 'scroll';
  let scroll = oDiv.clientWidth;
  oDiv.remove();
  // return noScroll - scroll;
  return noScroll === scroll ? 0 : 6;
}

const scrollbarWidth = (() => getScrollbarWidth())();

function getCssVar(cssVar, el) {
  el = el || document.documentElement;
  let cssVarVal = getComputedStyle(el).getPropertyValue(cssVar);
  if (cssVarVal) {
    return cssVarVal;
  }
  return false;
}

function setCss(el, styles) {
  for (let [key, val] of Object.entries(styles)) {
    if (key && val) el.style.setProperty(key, val);
  }
  return el;
}

function uuid() {
  let temp_url = URL.createObjectURL(new Blob());
  let uuid = temp_url.toString();
  URL.revokeObjectURL(temp_url);
  return uuid.substring(uuid.lastIndexOf('/') + 1);
}

function deepCopy(target, ignore) {
  let res = target instanceof Array ? [] : {};
  for (let key in target) {
    let isIgnore = false;
    if (ignore) isIgnore = ignore(key, target[key]);
    if (isIgnore) continue;
    res[key] = target[key] instanceof Object ? deepCopy(target[key], ignore) : target[key];
  }
  return res;
}

function formatStrToObj(str, splitMark1 = ';', splitMark2 = ':') {
  let reslut = {};
  str.split(splitMark1).forEach(item => {
    if (item !== false) {
      let [key, val] = item.trim().split(splitMark2);
      reslut[key] = val.trim();
    }
  });
  return reslut;
}

function formatObjToStr(obj, fn) {
  let result = '';
  Object.keys(obj).forEach(key => {
    result += fn(key, obj[key]);
  });
  return result;
}

function getType(value) {
  return Object.prototype.toString.call(value).slice(8, -1).toLocaleLowerCase();
}

function isObj(data) {
  return getType(data) === 'object';
}

function formatKeyNoValue(data, searchKeys = ['id', 'tid', 'uuid', 'vid'], useValueKey = 'value') {
  for (let key in data) {
    let valIsObj = isObj(data[key]);
    if (searchKeys === '*' && valIsObj) {
      if (data[key][useValueKey] !== undefined) data[key] = data[key][useValueKey];
      // try {
      //   data[key] = data[key][useValueKey];
      // } catch (err) {}
    } else if (searchKeys.includes(key) && valIsObj) {
      data[key] = data[key][useValueKey];
    } else {
      typeof data[key] === 'object' && formatKeyNoValue(data[key], searchKeys, useValueKey);
    }
  }
}

function array_get($array, $key, $default) {
  if ($key === undefined) {
    return $array;
  }
  if ($key in $array) {
    return $array[$key];
  }
  let $rtn = $array;
  $key.split('.').some($segment => {
    if ($array[$segment]) {
      $array = $array[$segment];
      $rtn = $array;
      return true;
    } else {
      $rtn = $default;
      return false;
    }
  });

  return $rtn;
}

function array_set($array, $key, $value) {
  if (!$key) return;
  var $keyArr = $key.split('.');
  for (var i = 0; i < $keyArr.length - 1; i++) {
    if ($array.hasOwnProperty($keyArr[i])) {
      $array = $array[$keyArr[i]];
    } else {
      for (var k = $keyArr.length - 1; i <= k; k--) {
        var w = $value;
        $value = {};
        $value[$keyArr[k]] = w;
      }
      $array[$keyArr[i]] = $value[$keyArr[i]];

      return;
    }
  }
  $array[$keyArr[i]] = $value;
}

function arrayFind(array, key, val, children = 'children') {
  if (!Array.isArray(array)) return;
  let result = array.find(v => v[key] === val);
  if (result) return result;
  for (let item of array) {
    if (item[children]) {
      result = arrayFind(item[children], key, val, children);
    }
    if (result) break;
  }

  return result;
}

function arraySlice(array, sliceNum) {
  const len = array.length;
  if (!len || !sliceNum || sliceNum < 1) {
    return [];
  }
  let index = 0; //用来表示切割元素的范围start
  let resIndex = 0; //用来递增表示输出数组的下标
  let result = new Array(Math.ceil(len / sliceNum));
  while (index < len) {
    //循环过程中设置result[0]和result[1]的值。该值根据array.slice切割得到。
    result[resIndex++] = array.slice(index, (index += sliceNum));
  }

  return result;
}

/**
 * 对象转url参数
 * @param {*} isPrefix,是否自动加上"?"
 */
function queryParams(data = {}, isPrefix = true, arrayFormat = 'comma', valuePath = 'value') {
  let prefix = isPrefix ? '?' : '';
  let _result = [];
  if (['indices', 'brackets', 'repeat', 'comma'].indexOf(arrayFormat) === -1)
    arrayFormat = 'brackets';
  for (let key in data) {
    let value = data[key];
    if (isObj(value)) {
      value = array_get(value, valuePath);
    }
    // 去掉为空的参数
    if (['', undefined, null].indexOf(value) >= 0) {
      continue;
    }
    // 如果值为数组，另行处理
    if (value.constructor === Array) {
      // e.g. {ids: [1, 2, 3]}
      switch (arrayFormat) {
        case 'indices':
          // 结果: ids[0]=1&ids[1]=2&ids[2]=3
          for (let i = 0; i < value.length; i++) {
            _result.push(key + '[' + i + ']=' + value[i]);
          }
          break;
        case 'brackets':
          // 结果: ids[]=1&ids[]=2&ids[]=3
          value.forEach(_value => {
            _result.push(key + '[]=' + _value);
          });
          break;
        case 'repeat':
          // 结果: ids=1&ids=2&ids=3
          value.forEach(_value => {
            _result.push(key + '=' + _value);
          });
          break;
        case 'comma':
          // 结果: ids=1,2,3
          let commaStr = '';
          value.forEach(_value => {
            commaStr += (commaStr ? ',' : '') + _value;
          });
          _result.push(key + '=' + commaStr);
          break;
        default:
          value.forEach(_value => {
            _result.push(key + '[]=' + _value);
          });
      }
    } else {
      _result.push(key + '=' + value);
    }
  }
  return _result.length ? prefix + _result.join('&') : '';
}

function cssBEM(blockAndElement = '', modifier = null, addOriginal = true) {
  function formatCName(cNameArr) {
    addOriginal && cNameArr.unshift(blockAndElement);
    return cNameArr.join(' ');
  }

  if (!blockAndElement) {
    return '';
  } else if (isObj(modifier)) {
    let cNameArr = Object.entries(modifier)
      .map(([key, val]) => (val ? `${blockAndElement}--${key}` : false))
      .filter(v => v);
    return formatCName(cNameArr);
  } else if (Array.isArray(modifier)) {
    let cNameArr = modifier.map(val => (val ? `${blockAndElement}--${val}` : false)).filter(v => v);
    return formatCName(cNameArr);
  } else if (typeof modifier == 'string') {
    let cNameArr = [blockAndElement + modifier];
    return formatCName(cNameArr);
  } else {
    return addOriginal ? blockAndElement : '';
  }
}

function data2FormData(obj) {
  let fData = new FormData();
  for (let key in obj) {
    fData.append(key, obj[key]);
  }
  return fData;
}

function DateDifference(faultDate, completeTime) {
  var stime = new Date(faultDate).getTime();
  var etime = new Date(completeTime).getTime();
  var usedTime = etime - stime; //两个时间戳相差的毫秒数
  var year = Math.floor(usedTime / (86400 * 365 * 1000));
  var month = Math.floor(usedTime / (86400 * 30 * 1000));
  var days = Math.floor(usedTime / (24 * 3600 * 1000));
  //计算出小时数
  var leave1 = usedTime % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
  var hours = Math.floor(leave1 / (3600 * 1000));
  //计算相差分钟数
  var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
  var minutes = Math.floor(leave2 / (60 * 1000));
  // var time = days + "天"+hours+"时"+minutes+"分";
  var time = {
    year: year,
    month: month,
    days: days,
    hours: hours,
    minutes: minutes,
  };
  return time;
}

function DateFormat(Date, fmt) {
  //author: meizz
  var o = {
    'M+': Date.getMonth() + 1, //月份
    'd+': Date.getDate(), //日
    'h+': Date.getHours(), //小时
    'm+': Date.getMinutes(), //分
    's+': Date.getSeconds(), //秒
    'q+': Math.floor((Date.getMonth() + 3) / 3), //季度
    S: Date.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (Date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (var k in o)
    if (new RegExp('(' + k + ')').test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length),
      );
  return fmt;
}

function formatTime(date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  return {
    year: year,
    month: month,
    day: day,
    hour: hour,
    minute: minute,
    second: second,
  };
}

function computedCountDown(time) {
  let d = parseInt(time / 60 / 60 / 24);
  let h = parseInt((time / 60 / 60) % 24);
  let m = parseInt((time / 60) % 60);
  let s = parseInt(time % 60);
  if (m < 10) m = '0' + m;
  if (s < 10) s = '0' + s;
  let D = d > 0 ? `${d} 天` : '';
  let H = h > 0 ? `${h} 小时` : '';
  let M = m > 0 ? `${m} 分钟` : '';
  let S = `${s} 秒`;

  return {
    d,
    h,
    m,
    s,
    toString() {
      if (d > 1) return D;
      else if (d) return [D, H].join(' ');
      else if (h) return [H, M].join(' ');
      else return [M, S].join(' ');
    },
  };
}

const eventOptimization = {
  debounce(fn, delay = 250) {
    let timeout;

    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    };
  },
  throttle(fn, interval = 250) {
    let lastTime = 0;

    return function (...args) {
      let now = Date.now();
      if (now - lastTime >= interval) {
        lastTime = now;
        fn.apply(this, args);
      }
    };
  },
  betterThrottle(fn, interval = 250) {
    let timeout = null;
    let lastTime = 0;

    return function (...args) {
      clearTimeout(timeout);
      let now = Date.now();
      let _interval = now - lastTime;

      if (_interval >= interval) {
        lastTime = now;
        fn.apply(this, args);
      } else {
        let _lastTime = lastTime;
        timeout = setTimeout(() => {
          if (_lastTime === lastTime) {
            lastTime = Date.now();
            fn.apply(this, args);
          }
        }, interval - _interval);
      }
    };
  },
  optimisticUpdate(delay = 250) {
    let dataArr = [],
      commitData,
      timeout;

    return async function ({ oValue, commit, success, fail }) {
      dataArr.push(oValue);
      clearTimeout(timeout);
      try {
        if (delay) {
          commitData = await new Promise((resolve, reject) => {
            timeout = setTimeout(() => {
              commit()
                .then(result => {
                  resolve(result);
                })
                .catch(_ => {
                  reject();
                });
            }, delay);
          });
        } else {
          commitData = await commit();
        }
        dataArr.length = 0;
        success(commitData);
      } catch (err) {
        fail(dataArr[0]);
        dataArr.length = 0;
      }
    };
  },
};
const transformDate = timestamp => {
  timestamp *= 1;
  if (window.isNaN(timestamp)) return '';
  const len = timestamp.toString().length;
  return new Date(len === 10 ? timestamp * 1000 : timestamp);
};
const utils = {
  getCssVar,
  setCss,
  uuid,
  scrollbarWidth,
  formatStrToObj,
  formatObjToStr,
  deepCopy,
  getType,
  isObj,
  formatKeyNoValue,
  array_get,
  array_set,
  arrayFind,
  arraySlice,
  queryParams,
  cssBEM,
  data2FormData,
  DateDifference,
  DateFormat,
  formatTime,
  computedCountDown,
  transformDate,
};

export default utils;

export { eventOptimization };
