import { memo, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useActivate } from 'react-activation';
import { Row, Col } from 'react-bootstrap';
import { Pagination, Autoplay, Lazy } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import moment from 'moment';
import seo from '../utils/seo';
import api from '../utils/request';
import PageContent from '../components/PageContent';
import Dots from '../components/Dots';
import ClipImage from '../components/ClipImage';
import ClipVideo from '../components/ClipVideo';
import OssImg, { getImgOssSrc } from '../components/OssImg';
import './Home.scss';

function Home() {
  const [data, setData] = useState([]);
  const [seoData, setSeoData] = useState(null);
  useEffect(() => {
    api.page.getHome().then(data => {
      if (typeof data?.content?.value === 'string') {
        let homeData = JSON.parse(data.content.value);
        setData(homeData);
      }
      let { seo_title, seo_description, seo_keyword } = data;
      const seoSetParams = {
        title: seo_title.value,
        description: seo_description.value,
        keywords: seo_keyword.value,
      };
      setSeoData(seoSetParams);
      seo.setContent(seoSetParams);
    });
  }, []);

  useActivate(() => {
    seo.setContent(seoData);
  });

  return (
    <PageContent loaded={data.length > 0}>
      {data.map((item, idx) => {
        return <HomeSection {...item} isLast={data.length === idx + 1} key={idx} />;
      })}
    </PageContent>
  );
}

const HomeSection = memo(({ data, type, isLast }) => {
  let children;
  if (type === 'swipers_homepage') children = <HomeSwiper data={data} />;
  else if (type === 'two_columns_homepage') children = <HomeTwoColumns data={data} />;
  else if (type === 'big_media_homepage') children = <HomeBigMedia data={data} />;
  else if (type === 'friendship_link') children = <HomeFriendship data={data} />;

  return (
    <>
      <section className={`home-section${type === 'friendship_link' ? ' relevant-sections' : ''}`}>
        {children}
      </section>
      {!isLast && <Dots />}
    </>
  );
});

const HomeSwiper = memo(({ data }) => {
  const nav = useNavigate();
  const { field_item = [] } = data;

  return (
    <div className="home-section__swiper">
      <Swiper
        modules={[Pagination, Autoplay, Lazy]}
        spaceBetween={20}
        pagination={{ clickable: true }}
        autoplay={{ delay: 6000 }}
        lazy={{ loadPrevNext: true }}
        loop
      >
        {field_item.map(({ data }, idx) => {
          const {
            field_description,
            field_image,
            field_node,
            field_tag,
            field_time_desc,
            field_title,
          } = data;
          let navTo = getNavTo(field_node);
          return (
            <SwiperSlide key={idx}>
              <ClipImage
                onClick={() => {
                  navTo && nav(navTo);
                }}
              >
                <img
                  data-src={getImgOssSrc(field_image, 'home.swiper')}
                  className="swiper-lazy"
                  alt=""
                />
              </ClipImage>
              <div className="swiper__overlay-on-screen">
                <Row>
                  <Col md={8}>
                    {field_tag && <strong className="d-block">{field_tag}</strong>}
                    {field_title && <h1 className="m-0">{field_title}</h1>}
                    {field_time_desc && (
                      <strong className="d-block mt-3">
                        {moment(field_time_desc * 1000).format('YYYY.MM.DD ddd HH:mm')}
                      </strong>
                    )}
                    <div className="d-none d-lg-block">
                      {field_description && <div className="mt-6">{field_description}</div>}
                      {navTo && (
                        <Link
                          to={navTo}
                          className="btn btn-light btn-lg d-block d-md-inline-block mt-8"
                          style={{ paddingLeft: '60px', paddingRight: '60px' }}
                        >
                          了解详情
                        </Link>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
});
const HomeTwoColumns = memo(({ data: { field_title, field_item = [] } }) => {
  const nav = useNavigate();

  return (
    <>
      {field_title && <h4>{field_title}</h4>}
      <Row className="home-section__two-columns">
        {field_item.length &&
          field_item.map((item, idx) => {
            const { field_description, field_image, field_node, field_title } = item.data;
            const navTo = getNavTo(field_node);

            return (
              <Col key={idx} md={6}>
                <div className="home-section__two-columns-item">
                  {/* <ClipImage
                    src={field_image}
                    onClick={() => {
                      nav(navTo);
                    }}
                  /> */}
                  <ClipImage
                    noStyle
                    onClick={() => {
                      nav(navTo);
                    }}
                  >
                    <OssImg src={field_image} optionsName="home.two-columns" />
                  </ClipImage>
                  <div className="d-grid gap-2 mt-7">
                    <h4 className="mb-0">{field_title}</h4>
                    <div>{field_description}</div>
                    <Link to={navTo}>了解更多</Link>
                  </div>
                </div>
              </Col>
            );
          })}
      </Row>
    </>
  );
});
const HomeBigMedia = memo(({ data }) => {
  const nav = useNavigate();
  let { field_ckeditor, field_media, field_iframe_code, field_node, field_title } = data;
  let navTo = getNavTo(field_node);

  return (
    <Row className="home-section__big-media">
      <Col lg={8} className="home-section__big-media__media">
        {field_media && <ClipVideo src={field_media} />}
        {field_iframe_code && (
          <div
            className="ratio ratio-16x9"
            dangerouslySetInnerHTML={{ __html: field_iframe_code }}
          ></div>
        )}
      </Col>
      <Col lg={4} className="home-section__big-media__info">
        {field_title && (
          <h3
            onClick={() => {
              nav(navTo);
            }}
          >
            {field_title}
          </h3>
        )}
        {field_ckeditor && (
          <div
            className="cms-item-richtext"
            dangerouslySetInnerHTML={{ __html: field_ckeditor }}
          ></div>
        )}
        {navTo && (
          <Row className="mt-7">
            <Col md={6}>
              <Link to={navTo} className="btn btn-primary btn-lg d-block">
                了解详情
              </Link>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
});
const HomeFriendship = memo(({ data }) => {
  return (
    <div className="home-section__friendship">
      {data.field_image_link.map((item, idx) => {
        return (
          <div className="home-section__friendship-item" key={idx}>
            {/* <img src={item.data.field_image} alt="" /> */}
            <OssImg src={item.data.field_image} optionsName="home.friendship" />
          </div>
        );
      })}
    </div>
  );
});

function getNavTo(field_node) {
  let navTo;
  if (!field_node) return '';
  if (field_node.type && field_node.id) {
    navTo = `/${field_node.type}/${field_node.id}`;
  }
  return navTo;
}

export default memo(Home);
