import './Video.scss';

function Video(props) {
  if (props.iframe)
    return (
      <div
        className={'cms-item cms-item-video ratio ratio-16x9'}
        dangerouslySetInnerHTML={{ __html: props.iframe }}
      ></div>
    );
}

export default Video;
