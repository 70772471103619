import { memo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import AuthContext from '../store/AuthContext';
import './LoadButton.scss';

const LoadButton = memo(({ loginRequired = false, onClick, className, ...props }) => {
  const authCtx = useContext(AuthContext);
  const nav = useNavigate();
  let btnProps = { ...props };
  delete btnProps.loading;
  let handleClick = () => {
    if (loginRequired) {
      authCtx.isLoggedIn ? tirggerClick() : nav('/user/login');
    } else tirggerClick();
  };
  let tirggerClick = () => {
    onClick && onClick();
  };
  return (
    <Button
      className={(className ? className : '') + (props.loading ? ' btn--loading active' : '')}
      {...btnProps}
      onClick={handleClick}
    >
      {props.loading === true && (
        <span className="btn__spinner">
          <Spinner className="spinner" animation="border" size="sm" />
        </span>
      )}
      <span className="btn__text">{props.children}</span>
    </Button>
  );
});

export default LoadButton;
