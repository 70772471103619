class SEO {
  constructor() {
    this.title = document.title;
    this.description = document.querySelector('meta[name="description"]');
    this.keywords = document.querySelector('meta[name="keywords"]');
    this._rawData = this.getContent();
    this._history = [];
  }
  getContent() {
    let { title, description, keywords } = this;
    return { title, description: description.content, keywords: keywords.content };
  }
  setContent(obj, saveHistory = true) {
    // saveHistory && this._history.push(this.getContent());
    for (const [key, val] of Object.entries(obj)) {
      if (key === 'title') {
        if (!val) break;
        if (val === this.title) break;
        if (val.includes('|')) document.title = val;
        else document.title = `${val} | 新武林`;
      } else if (this[key]) {
        this[key].content = val;
      }
    }
  }
  back() {
    // if (this._history.length) this.setContent(this._history.pop(), false);
    // else this.backOriginal();
  }
  backOriginal() {
    // this.setContent(this._rawData, false);
  }
}

const seo = new SEO();

export default seo;
