import '../assets/fonts/icon/iconfont.css';
import './Icon.css';

function Icon(props) {
  let { icon, fontSize } = props;
  const className = `iconfont icon-${icon}`;
  const style = {
    fontSize,
  };
  return <i className={className} style={style}></i>;
}

export default Icon;
