import { memo, useState, useEffect } from 'react';
import { useNavigate, useNavigationType, useLocation, useParams } from 'react-router';
import { useAliveController } from 'react-activation';
import { Modal, Button } from 'react-bootstrap';
import api from '../../../utils/request';
import PageTitleBar from '../../../components/PageTitleBar';
import IconButton from '../../../components/IconButton';
import AddressForm from '../../../components/AddressForm';
import alert from '../../../components/alert';

function UpdateAddress() {
  const urlParams = useParams();
  const nav = useNavigate();
  const navType = useNavigationType();
  const location = useLocation();
  let [id] = useState(urlParams.id);
  let [formDdefaultValues, setFormDdefaultValues] = useState({});
  const { dropScope } = useAliveController();

  useEffect(() => {
    if (location.state && navType === 'PUSH') {
      setFormDdefaultValues(location.state);
    } else {
      api.addressBook.getDetail({ data: { id } }).then(data => {
        data.is_default *= 1;
        setFormDdefaultValues(data);
      });
    }
  }, [id, location.state, navType]);

  function handleSubmitAfter() {
    dropScope('Address').then(_ => {
      setTimeout(() => {
        nav('/user/address/');
      }, 100);
    });
  }

  function handleDelete() {
    api.addressBook.remove({ data: { id } }).then(() => {
      alert.success('收件地址已删除');
      confirmClose();
      handleSubmitAfter();
    });
  }

  const [showConfirm, setShowConfirm] = useState(false);
  const confirmShow = () => setShowConfirm(true);
  const confirmClose = () => setShowConfirm(false);

  return (
    <>
      <PageTitleBar
        title="编辑收件地址"
        rightEl={<IconButton icon="delete" fontSize="24px" onClick={confirmShow} />}
      />
      {formDdefaultValues.id && (
        <AddressForm
          formType="update"
          onSubmitAfter={handleSubmitAfter}
          onCancel={handleSubmitAfter}
          defaultValues={formDdefaultValues}
        />
      )}
      <Modal show={showConfirm} onHide={confirmClose} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>删除收件地址</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-grid gap-4">
            <Button onClick={handleDelete}>确定</Button>
            <Button onClick={confirmClose} variant="outline-primary">
              取消
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default memo(UpdateAddress);
