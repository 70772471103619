import { memo, useMemo, useContext } from 'react';
import { NavLink, useOutlet } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import AuthContext from '../../store/AuthContext';
import PageContent from '../../components/PageContent';
import { ReactComponent as DefaultAvatar } from '../../assets/images/default-avatar.svg';
import './Layout.scss';

function Layout() {
  const outlet = useOutlet();
  const authCtx = useContext(AuthContext);

  let createdTime = useMemo(() => {
    if (!authCtx?.userInfo?.created) return '';
    else return moment(authCtx.userInfo.created * 1000).format('YYYY.MM.DD');
  }, [authCtx.userInfo]);

  return authCtx.isLoggedIn ? (
    <Row className="layout-row--2-columns">
      <Col className="layout-row__col" lg={3}>
        <div className="user-base-info">
          <div className="user-base-info__picture">
            {authCtx?.userInfo?.userPicture ? (
              <img src={authCtx.userInfo.userPicture} alt="" />
            ) : (
              <DefaultAvatar />
            )}
          </div>
          <div className="user-base-info__text">
            <h5>{authCtx?.userInfo?.nickname}</h5>
            <p>注册于 {createdTime}</p>
            <Button onClick={authCtx.logout} variant="outline-primary" size="sm">
              退出登录
            </Button>
          </div>
        </div>
        <nav className="user-nav">
          <NavLink className="user-nav-item" to="/user/warehouse">
            仓库
          </NavLink>
          <NavLink className="user-nav-item" to="/user/orders">
            我的订单
          </NavLink>
          <NavLink className="user-nav-item" to="/user/ecode">
            兑换中心
          </NavLink>
          <NavLink className="user-nav-item" to="/user/address">
            地址簿
          </NavLink>
          <NavLink className="user-nav-item" to="/user/profile">
            个人信息
          </NavLink>
        </nav>
      </Col>
      <Col lg={{ span: 7, offset: 1 }} xl={6}>
        <PageContent paddingTop paddingBotton>
          {outlet}
        </PageContent>
      </Col>
    </Row>
  ) : (
    <PageContent paddingTop paddingBotton>
      <Row>
        <Col md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
          {outlet}
        </Col>
      </Row>
    </PageContent>
  );
}

export default memo(Layout);
