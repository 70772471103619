import { memo } from 'react';
import { isTablet, isDesktop } from 'react-device-detect';

function isSupportWebp() {
  try {
    return (
      document.createElement('canvas').toDataURL('image/webp', 0.5).indexOf('data:image/webp') === 0
    );
  } catch (err) {
    return false;
  }
}

function getImageSizeWithDevice([phone_2x, pad_2x, desktop], aspectRatio) {
  // 普通桌面电脑使用Desktop，Retina屏直接使用Pad@2x尺寸
  let w, h;
  if (isDesktop && window.devicePixelRatio === 1) {
    w = desktop;
  } else if (window.devicePixelRatio === 2) {
    w = pad_2x;
  } else {
    w = isTablet ? pad_2x : phone_2x;
  }
  if (aspectRatio) h = Math.round(w / aspectRatio);
  return h ? { w, h } : { m: null, w };
}

const defaultOptions = { quality: 85, format: isSupportWebp() ? 'webp' : 'jpg' };
const imgCommonSize = {
  xs: aspectRatio => getImageSizeWithDevice([120, 120, 60], aspectRatio),
  sm: aspectRatio => getImageSizeWithDevice([240, 240, 120], aspectRatio),
  md: aspectRatio => getImageSizeWithDevice([750, 540, 350], aspectRatio),
  lg: aspectRatio => getImageSizeWithDevice([750, 1170, 750], aspectRatio),
  xl: aspectRatio => getImageSizeWithDevice([750, 1600, 1100], aspectRatio),
  xxl: aspectRatio => getImageSizeWithDevice([1000, 2000, 1600], aspectRatio),
};

function objToSring(obj) {
  let str = '';
  for (const key of Object.keys(obj)) {
    obj[key] && (str += `,${key}_${obj[key]}`);
  }

  return str;
}

let optionsInPage = {
  xs: {
    resize: imgCommonSize.xs(),
    format: 'png',
  },
  sm: {
    resize: imgCommonSize.sm(),
  },
  md: {
    resize: imgCommonSize.md(),
  },
  lg: {
    resize: imgCommonSize.lg(),
  },
  xl: {
    resize: imgCommonSize.xl(),
  },
  xxl: {
    resize: imgCommonSize.xxl(),
  },
  'home.swiper': {
    resize: imgCommonSize.xxl(16 / 9),
    sharpen: true,
  },
  'home.two-columns': {
    resize: imgCommonSize.lg(3 / 2),
  },
  'home.friendship': {
    resize: { w: 400, m: 'mfit' },
    format: 'png',
  },
};

class OssImgFormat {
  constructor(url) {
    this.url = url + '?x-oss-process=image';
  }
  format(type) {
    if (type) {
      if (type === 'png' && isSupportWebp()) type = 'webp';
      this.url += `/format,${type}`;
    }
    return this;
  }
  quality(quality) {
    if (quality) this.url += `/quality,${quality}`;
    return this;
  }
  sharpen() {
    this.url += '/sharpen,1';
  }
  resize(opt) {
    opt = Object.assign({ m: 'fill' }, opt);
    this.url += `/resize${objToSring(opt)}`;
    return this;
  }
}

function getImgOssSrc(src, optionsName, options) {
  if (src.includes('?x-oss-process=image')) return src;
  if (optionsName && optionsInPage[optionsName]) {
    options = Object.assign({ ...defaultOptions }, optionsInPage[optionsName], options);
  } else {
    options = Object.assign({ ...defaultOptions }, options);
  }
  let imgOssSrc = new OssImgFormat(src);
  let isGif = src.endsWith('gif');
  if (isGif) {
    delete options.format;
    return src;
  }
  for (const [fnName, params] of Object.entries(options)) {
    imgOssSrc[fnName] && imgOssSrc[fnName](params);
  }
  return imgOssSrc.url;
}

const OssImg = ({ src, optionsName, options, ...imgProps }) => {
  if (!src) return;
  else return <img src={getImgOssSrc(src, optionsName, options)} {...imgProps} />; // eslint-disable-line jsx-a11y/alt-text
};

export default memo(OssImg);
export { getImgOssSrc };
