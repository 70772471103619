import { memo } from 'react';
import Nav from 'react-bootstrap/Nav';

const FilterTypeNav = memo(props => {
  function handleSelect(key) {
    props.setFilter(data => {
      return {
        ...data,
        type: key,
      };
    });
  }

  return (
    <Nav
      className={props.className}
      variant="pills"
      defaultActiveKey="*"
      onSelect={props.switchCategory ? props.switchCategory : handleSelect}
    >
      {props.switch ? '' : <Nav.Link eventKey="*">全部</Nav.Link>}
      {Object.entries(props.nav).map(([key, title]) => (
        <Nav.Link key={key} eventKey={key}>
          {title}
        </Nav.Link>
      ))}
    </Nav>
  );
});

export default FilterTypeNav;
