import { memo } from 'react';
import utils from '../utils';

function Badge({ text, color = 'primary', children, onClick, className }) {
  return (
    <span
      className={utils.cssBEM('badge', [color]) + (className ? ' ' + className : '')}
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
    >
      {text}
      {children}
    </span>
  );
}

export default memo(Badge);
