import { memo } from 'react';

function ListNoData({ text, action }) {
  return (
    <div>
      {text} {action && <strong>{action}</strong>}
    </div>
  );
}

export default memo(ListNoData);
