import { useState, useEffect } from 'react';

function InvalidFeedback(props) {
  let [message, setMessage] = useState(props.message);
  let [visible, setVisible] = useState(false);
  let [cName, setCName] = useState('invalid-feedback');
  useEffect(() => {
    if (props.message) {
      setVisible(true);
      setMessage(props.message);
      setTimeout(() => {
        setCName('invalid-feedback show');
      }, 50);
    } else {
      setCName('invalid-feedback');
      setTimeout(() => {
        setVisible(false);
        setMessage(props.message);
      }, 300);
    }
  }, [props.message]);

  return visible && <div className={cName}>{message}</div>;
}

export default InvalidFeedback;
