import { memo } from 'react';
import './AngleDecoration.scss';

const AngleDecoration = memo(({ size = 'large', style }) => (
  <div className={`angle-decorate ${size}`} style={style}>
    <div className="angle-decorate__top"></div>
    <div className="angle-decorate__bottom"></div>
  </div>
));

export default AngleDecoration;
