import { memo } from 'react';
import utils from '../utils';
import ListItemText from './ListItemText';
import OssImg from './OssImg';
import './SectsList.scss';

const SectsList = memo(props => (
  <ul className="grid sects-list list-unstyled">
    {props.list.map(item => {
      utils.formatKeyNoValue(item, '*');
      const { id, logo, title, description } = item;
      return (
        <li className="sects-list-item" key={id}>
          <div className="sects-list-item__image">
            {/* <img src={logo} alt="" /> */}
            <OssImg src={logo} optionsName="xs" />
          </div>
          <ListItemText {...{ title, description, linkTo: '/sect/' + id }} />
        </li>
      );
    })}
  </ul>
));

export default SectsList;
