import { useContext, useEffect, useState } from 'react';
import api from '../../utils/request';
import WechatContext from '../../store/WechatContext';
import alert from '../../components/alert';
import AuthContext from '../../store/AuthContext';
import utils from '../index';
import { useNavigate } from 'react-router-dom';
function useGiveTicket(activityData) {
  const navigate = useNavigate();
  const wechatCtx = useContext(WechatContext);
  const { isLoggedIn } = useContext(AuthContext);
  const [result, setResult] = useState(false);
  const freeActivityUrl = localStorage.getItem('freeActivityUrl') || '/activity';
  useEffect(() => {
    const data = JSON.parse(JSON.stringify(activityData));
    utils.formatKeyNoValue(data, '*');
    // 赠送门票条件 1. 活动存在免费门票 2. 用户登录 3. 用户未领取免费门票 4. 当前时间在免费时间内
    if (!data || Object.keys(data).length === 0) {
      console.log('no activity data');
      return;
    }
    let { holding_tickets, field_free_end_time, field_free_start_time, field_free_tickets } = data;
    if (!field_free_tickets || field_free_tickets.length === 0) {
      console.log('no free tickets');
      return;
    }
    if (!isLoggedIn) {
      console.log('not logged in');
      return;
    }
    if (holding_tickets && Object.keys(holding_tickets).length !== 0) {
      console.log('already have tickets');
      return;
    }
    const now = new Date().getTime();
    const start = new Date(field_free_start_time).getTime();
    const end = new Date(field_free_end_time).getTime();
    if (now < start || now > end) {
      console.log('not in free time');
      return;
    }
    autoGive(field_free_tickets);
  }, [activityData]);

  // 自动赠送门票
  const autoGive = async freeTickets => {
    try {
      const addInfo = await api.order.add({
        data: {
          pv_id: freeTickets,
        },
      });
      const cart_id = addInfo.order;
      const cartInfo = await api.order.getCartInfo({ data: { cart_id } });
      let payment_method = cartInfo?.payment_options[0]?.key;
      await api.order.submitCart({
        data: {
          cart_id,
          payment_method,
        },
      });
      await api.order.getPayment({
        data: { order_id: cart_id, open_id: wechatCtx.wxOpenId },
      });
      alert.success('恭喜！您已成功获得门票。详情请查看您的个人中心。');
      setResult(true);
    } catch (err) {
      alert.danger('门票赠送失败: ' + err.message);
      // setResult(false);
    }
  };
  return result;
}

export default useGiveTicket;
