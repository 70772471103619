import { useState, useEffect, useMemo } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import alert from '../../components/alert';

const buttonStyle = { width: '8rem', minWidth: '33%', paddingLeft: '0', paddingRight: '0' };

function InputMessageCode(props) {
  const [max] = useState(props.countDown * 1 || 60);
  const [countDown, setCountDown] = useState(max);
  const [disabledInput, setDisabledInput] = useState(true);
  const [disabledSend, setDisabledSend] = useState(false);
  const countDownText = useMemo(() => `${countDown} 秒后重新获取`, [countDown]);

  useEffect(() => {
    let timer;
    if (countDown === max) {
      return;
    } else if (countDown < 1) {
      setCountDown(max);
      setDisabledSend(false);
    } else {
      timer = setTimeout(() => {
        setCountDown(c => c - 1);
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [countDown, max]);

  useEffect(() => {
    setCountDown(0);
    setDisabledInput(true);
  }, [props.reset]);

  function handleCountDown() {
    props
      .buttonOnClick()
      .then(data => {
        if (data.result === false || data.result === undefined) return;
        else if (data.result === true || data.result?.includes('succeed')) {
          alert.success('已发送短信，请留意查看', 'send_sms_code');
          setCountDown(count => count - 1);
          setDisabledInput(false);
          setDisabledSend(true);
        } else if (data.result.includes('fail')) {
          setCountDown(10);
          setDisabledSend(true);
          alert.danger(data.result, 'send_sms_code');
        }
      })
      .catch(err => {});
  }

  return (
    <InputGroup>
      <Form.Floating className="form-floating">
        <Form.Control
          {...props.register}
          className={props.className}
          type="text"
          placeholder=" "
          disabled={disabledInput}
          autoComplete="off"
        />
        <Form.Label required={props.required}>{props.label}</Form.Label>
      </Form.Floating>
      <Button
        style={buttonStyle}
        className="ms-3"
        variant="outline-primary"
        size="lg"
        onClick={handleCountDown}
        disabled={disabledSend}
      >
        {disabledSend ? countDownText : props.buttonText}
      </Button>
    </InputGroup>
  );
}

export default InputMessageCode;
