import { memo, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Form, InputGroup, Modal } from 'react-bootstrap';
import api from '../utils/request';
import alert from '../components/alert';
import Dots from '../components/Dots';
import IconButton from '../components/IconButton';
import Icon from '../components/Icon';
import ImageCode from '../components/ImageCode';
import { ReactComponent as LogoGraphical } from '../assets/images/logo-graphical.svg';
import { ReactComponent as LogoOxyz3 } from '../assets/images/oxyz3.svg';
import { ReactComponent as LogoComo } from '../assets/images/como.svg';
import wechatQrCode from '../assets/images/wechat_code.png';
import './AppFooter.scss';

const Line = memo(() => (
  <div className="app-footer__line">
    {Array(6)
      .fill()
      .map((v, i) => (
        <i key={i}></i>
      ))}
  </div>
));

const Top = memo(({ left, right, children }) => (
  <div className="app-footer__top">
    {left && right ? (
      <Row>
        <Col xs={12} md lg={4}>
          {left}
        </Col>
        <Col xs={12} md lg={8}>
          {right}
        </Col>
      </Row>
    ) : (
      <div className="d-flex justify-content-center">{children}</div>
    )}
  </div>
));

const Middle = memo(({ left, right, children }) => (
  <div className="app-footer__middle">
    {left && right ? (
      <Row>
        <Col xs={12} md={12} lg={{ span: 4 }}>
          {left}
        </Col>
        <Col xs={12} md={12} lg={{ span: 7, offset: 1 }} className="app-footer__nav">
          {right}
        </Col>
      </Row>
    ) : (
      ''
    )}
  </div>
));

const Bottom = memo(props => (
  <div className="app-footer__bottom">
    <Row>
      <Col xs={12} md>
        &#169; {props.year} OXYZ3 版权所有。
        <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
          浙ICP备{props.icp.number}号
        </a>{' '}
        <span className="d-none d-md-inline">/</span>{' '}
        <span className="d-block d-md-inline">隐私政策 / 服务条款</span>
      </Col>
      <Col xs={12} md={4} className="app-footer__bottom__developers">
        设计 / 开发
        <a href="https://www.comonetwork.com" target="_blank" rel="noopener noreferrer">
          <LogoComo className="app-footer__bottom__como" />
        </a>
      </Col>
    </Row>
    {props.children}
  </div>
));

const Subscription = memo(() => {
  const inputRef = useRef();
  const handleClick = () => {
    const email = inputRef?.current?.value;
    if (email) {
      if (
        /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/.test(
          inputRef.current.value,
        )
      ) {
        api.user
          .mailSubscription({
            data: {
              email,
            },
          })
          .then(data => {
            alert.success(`${email}订阅成功`);
            inputRef.current.value = '';
          })
          .catch(() => {
            alert.danger(`该邮箱订阅已经`, 'subscription');
            inputRef.current.value = '';
          });
      } else {
        alert.danger('邮箱格式错误', 'subscription');
        inputRef.current.focus();
      }
    } else {
      alert.danger('请输入需要订阅的邮箱', 'subscription');
      inputRef?.current.focus();
    }
  };

  return (
    <div className="app-footer__subscription">
      <div className="h5">邮件订阅</div>
      <p>收获一手新武林资讯</p>
      <InputGroup>
        <Form.Control placeholder="输入您的邮箱地址" ref={inputRef} />
        <Button className="btn--wide" onClick={handleClick}>
          订阅
        </Button>
      </InputGroup>
    </div>
  );
});

const FollowUs = memo(() => {
  let [show, setShow] = useState(false);
  let baseParams = { fontSize: '36px' };

  return (
    <div className="app-footer__follow-us">
      <Modal
        onHide={() => {
          setShow(false);
        }}
        show={show}
        size="sm"
        centered
      >
        <Modal.Header>
          <Modal.Title>关注微信公众号</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageCode type="qr-code" src={wechatQrCode} />
        </Modal.Body>
      </Modal>
      <IconButton
        className="app-footer__follow-us__wechat"
        onClick={() => {
          setShow(true);
        }}
        icon="wechat_circlefill"
        {...baseParams}
      />
      <a
        className="app-footer__follow-us__weibo icon-button"
        href="https://weibo.com/u/7742363070"
        target="_blank"
        rel="noreferrer"
      >
        <Icon icon="weibo_circlefill" {...baseParams} />
      </a>
      <a
        className="app-footer__follow-us__bilibili icon-button"
        href="https://space.bilibili.com/1340851698"
        target="_blank"
        rel="noreferrer"
      >
        <Icon icon="bilibili_circlefill" {...baseParams} />
      </a>
    </div>
  );
});

const Info = memo(() => (
  <div className="app-footer__info">
    <div>
      <LogoGraphical className="app-footer__logo-xinwulin" />
      <p>
        “新武林”是一个国风+科幻的虚拟音乐江湖，一个集演出、展览、社交和类游戏体验的虚拟世界。在新武林的世界中，音乐人将拥有独属的数字应用新场景，让那些天马行空的想法更多元地展现。玩家也将享受类游戏化的漫游、观演、互动、社交新体验。新武林将融合音乐、艺术与时尚，开启次世代全新娱乐模式。
      </p>
    </div>
    <div>
      <LogoOxyz3 className="app-footer__logo-oxyz3" />
      <p>
        OXYZ3，是擅长构建“虚拟世界观”体系，致力于探索虚拟演出无限可能的新锐数字内容创作公司。打通了艺术创意、音乐、技术制作和全流程执行，融合了视听美学和前沿科技，为创作者和玩家提供虚拟世界中的音乐互动新场景，以及可持续性的虚实结合新玩法。
      </p>
    </div>
  </div>
));

const Nav = memo(props => {
  let { column1, column2, column3, column4 } = props;
  const columns = [column1, column2, column3, column4];
  return (
    <div className="app-footer__nav">
      <Row>
        {columns.map((column, i) => {
          return (
            <Col className="col" xs={6} md={3} key={i}>
              {column.map((group, i) => (
                <NavGroup {...group} key={i} />
              ))}
            </Col>
          );
        })}
      </Row>
    </div>
  );
});
const NavGroup = memo(props => (
  <div className="app-footer__nav__group">
    <div className="h5">{props.title}</div>
    <ul className="list-unstyled">
      {props.subtree.map(({ url, title }, i) => (
        <li key={i}>
          <Link to={url} className={url.includes('nolink') ? 'pe-none' : ''}>
            {title}
          </Link>
        </li>
      ))}
    </ul>
  </div>
));

const getNavInitData = () => {
  let obj = {};
  for (let i = 1; i <= 4; i++) {
    obj['column' + i] = [];
  }
  return obj;
};
const AppFooter = memo(props => {
  let [year] = useState(() => {
    let year = new Date().getFullYear();
    return year > 2021 ? year : 2022;
  });

  let [navData, setNavData] = useState(null);

  useEffect(() => {
    if (props.menu) {
      let data = getNavInitData();
      props.menu?.forEach(item => {
        let { block } = item;
        data['column' + block]?.push(item);
      });
      setNavData(data);
    }
  }, [props.menu]);

  return (
    <footer className="app-footer">
      <Dots color="#000" />
      <Line />
      <div className="app-footer-container container-xxl">
        {/* <Top left={<Subscription />} right={<FollowUs />} /> */}
        <Top>
          <FollowUs />
        </Top>
        <Middle
          left={
            <>
              <Info />
            </>
          }
          right={navData && <Nav {...navData} />}
        />
        <Bottom year={year} icp={{ number: '2022025146' }} />
      </div>
    </footer>
  );
});

export default AppFooter;
