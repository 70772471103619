import './Links.scss';
import { Button } from 'react-bootstrap';
import Icon from '../../components/Icon';

function getIcon(type) {
  let icon_style = {
    icon: '',
    fontSize: 20,
  };
  switch (type) {
    case 'attachment':
      icon_style.icon = 'download';
      return icon_style;
    case 'email':
      icon_style.icon = 'mail';
      return icon_style;
    case 'link':
      icon_style.icon = 'arrow_right';
      return icon_style;
    default:
      break;
  }
}
const link_style = {
  display: 'inline-flex',
  alignItems: 'center',
};
function Links(props) {
  return (
    <div>
      {props.contents.map((item, index) => (
        <div className="cms-link" key={index}>
          <Button variant="outline-primary" style={link_style}>
            <Icon {...getIcon(item.type)} />
            {item.type === 'attachment' && (
              <a
                className="cms-link-a"
                href={item.data?.field_attachment}
                download={item.data?.field_title}
              >
                {item.data?.field_title}
              </a>
            )}
            {item.type === 'email' && (
              <a className="cms-link-a" href={`mailto:${item.data?.field_email}`}>
                {item.data?.field_title}
              </a>
            )}
            {item.type === 'link' && (
              <a className="cms-link-a" href={item.data?.field_link?.uri}>
                {item.data?.field_link?.title}
              </a>
            )}
          </Button>
        </div>
      ))}
    </div>
  );
}

export default Links;
