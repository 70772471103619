import { memo } from 'react';
import { useNavigate } from 'react-router';
import ListItemText from './ListItemText';
import AngleDecoration from './AngleDecoration';
import Badge from './Badge';
import OssImg from './OssImg';
import './PropsList.scss';

const PropsList = memo(props => {
  return (
    <ul className="grid grid-custom grid-custom-4 props-list list-unstyled">
      {props.list.map(item => {
        const { id, key_visual, title, description, prop_type, prop_tags, sale_status } = item;
        const itemDisplay = () => {
          if (!props.filter.type || props.filter.type === '*') return true;
          return props.filter.type === prop_type;
        };
        return (
          <PropsListItem key={id} display={itemDisplay()} linkTo={'/prop/' + id}>
            <div
              className={`props-list-item__image${
                sale_status?.status === 1 ? ' image-disabled' : ''
              }`}
            >
              <OssImg src={key_visual} optionsName="md" options={{ format: 'png' }} />
            </div>
            <ListItemText
              {...{
                title,
                description,
                titleAfter:
                  sale_status?.status === 1 ? (
                    <Badge className="ms-1" text="售罄" color="block" />
                  ) : (
                    ''
                  ),
              }}
            />
            {prop_tags && (
              <div className="badge-group">
                {formatPropTags(prop_tags).map((item, idx) => (
                  <Badge {...item} key={idx} />
                ))}
              </div>
            )}
          </PropsListItem>
        );
      })}
    </ul>
  );
});

function formatPropTags(data) {
  let reslut = data ? JSON.parse(`[${data}]`) : [];
  reslut = reslut.map(item => {
    return {
      color: getColor(item.key),
      text: item.value,
    };
  });
  return reslut;
}

function getColor(key = 'limit') {
  return {
    limit: 'primary',
    new: 'red',
    hot: 'blue',
  }[key];
}

const PropsListItem = memo(({ display, linkTo, children }) => {
  const nav = useNavigate();
  const styles = {
    cursor: 'pointer',
  };
  return (
    <li
      className={`props-list-item${display ? '' : ' d-none'}`}
      onClick={() => nav(linkTo)}
      style={styles}
    >
      <AngleDecoration />
      {children}
    </li>
  );
});

export default PropsList;
