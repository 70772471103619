import { memo, useState, useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import api from '../../utils/request';
import Dots from '../../components/Dots';
import OssImg from '../../components/OssImg';
import ClipImage from '../../components/ClipImage';
import SectMainList from '../../components/SectMainList';
import PageContent from '../../components/PageContent';

function SectList(props) {
  let [page, setPage] = useState(0);
  let [data, setData] = useState({});
  let [isMore, setIsMore] = useState(true);
  let [sectlist, setList] = useState({});

  useEffect(() => {
    api.sect
      .getList({ data: { page } })
      .then(listObj => {
        setData(listObj);
        setIsMore(isMore => {
          const result = listObj.rows.length ? true : false;
          return result;
        });
        setList(sectlist => {
          const newList = page === 0 ? listObj.rows : sectlist.concat(listObj.rows);
          return newList;
        });
      })
      .catch(err => {
        setData({ notFound: true });
      });
  }, [page]);

  const loadFunc = () => {
    if (isMore) {
      setPage(page + 1);
      setIsMore(false);
    }
  };

  return (
    <PageContent loaded={data.basic_info}>
      <Row className="baseinfo-row">
        <Col lg={12}>
          <div className="baseinfo-row__poster no-border">
            <ClipImage className="ratio ratio-16x9">
              <OssImg src={data.basic_info?.key_visual} optionsName="home.swiper" />
            </ClipImage>
          </div>
        </Col>
      </Row>
      <Dots />
      <InfiniteScroll
        pageStart={0}
        loadMore={loadFunc}
        hasMore={isMore}
        loader={
          <div className="loader" key={0}>
            Loading ...
          </div>
        }
      >
        <>{sectlist.length && <SectMainList list={sectlist}></SectMainList>}</>
      </InfiniteScroll>
    </PageContent>
  );
}

export default memo(SectList);
