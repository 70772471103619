import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import PageContent from '../components/PageContent';

const NotFound = memo(props => {
  return (
    <PageContent loaded paddingTop paddingBottom>
      <Row>
        <Col className="text-center" md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
          <h2 className="mb-7">{props.title ?? '404'}</h2>
          <p>非常抱歉，这个页面已经离开地球了</p>
          <Row className="mt-10">
            <Col className="text-center d-grid" md={{ span: 6, offset: 3 }}>
              <Link className="btn btn-primary btn-lg" to="/">
                回到首页
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </PageContent>
  );
});

export default NotFound;
