import { memo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import seo from '../utils/seo';
import api from '../utils/request';
import PageContent from '../components/PageContent';
import PageTitleBar from '../components/PageTitleBar';
import Cms from '../components/Cms';
import NotFound from './NotFound';

function Page() {
  let urlParams = useParams();
  const [id, setId] = useState(urlParams.id);
  let [data, setData] = useState({});
  let [cmsBlocks, setCmsBlocks] = useState([]);

  useEffect(() => {
    api.page
      .getDetail({ data: { id } })
      .then(detail => {
        setData(detail);
        setCmsBlocks(JSON.parse(detail.image_text.value));
        let { seo_title, seo_description, seo_keyword } = detail;
        seo.setContent({
          title: seo_title.value,
          description: seo_description.value,
          keywords: seo_keyword.value,
        });
      })
      .catch(() => {
        setData({ notFound: true });
      });
    return () => {
      seo.back();
    };
  }, [id]);

  useEffect(() => {
    setId(urlParams.id);
  }, [urlParams.id]);

  return data.notFound ? (
    <NotFound title="没有找到页面" />
  ) : (
    <PageContent loaded={data.title} paddingTop paddingBotton>
      <Cms col={{ lg: { span: 8, offset: 2 } }} blocks={cmsBlocks}>
        <PageTitleBar title={data?.title?.value} />
      </Cms>
    </PageContent>
  );
}

export default memo(Page);
