import ReactDOM from 'react-dom/client';
import { isMobile, isDesktop } from 'react-device-detect';
import 'moment/locale/zh-cn';
import App from './App';
import utils from './utils';
import moment from 'moment';
import './index.scss';
// import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === 'production' && window.location.href.includes('http:')) {
  window.location.href = window.location.href.replace('http:', 'https:');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

const $html = document.querySelector('html');
$html.classList.add(`scrollbar-width--${utils.scrollbarWidth}`);
$html.classList.add(utils.cssBEM('device', { isMobile, isDesktop }, false));
utils.setCss($html, {
  '--scrollbar-width': utils.scrollbarWidth + 'px',
});

moment.locale('zh-cn', {
  weekdays: '周日_周一_周二_周三_周四_周五_周六'.split('_'),
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
