import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import IconButton from '../IconButton';

function InputTogglePassword(props) {
  let [visible, setVisible] = useState(false);

  function togglePassword() {
    setVisible(v => !v);
  }

  return (
    <Form.Floating>
      <IconButton icon={visible ? 'eye_on' : 'eye_off'} fontSize="24px" onClick={togglePassword} />
      <Form.Control
        {...props.register}
        autoComplete={props.autoComplete}
        className={props.className}
        type={visible ? 'text' : 'password'}
        placeholder=" "
      />
      <Form.Label required={props.required}>{props.label}</Form.Label>
      {props.children}
    </Form.Floating>
  );
}

export default InputTogglePassword;
