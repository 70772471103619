import { memo, useState, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import utils from '../../utils';
import seo from '../../utils/seo';
import api from '../../utils/request';
import NotFound from '../NotFound';
import PageContent from '../../components/PageContent';
import ClipImage from '../../components/ClipImage';
import OssImg from '../../components/OssImg';
import Cms from '../../components/Cms';
import Dots from '../../components/Dots';
import Relevant from '../../components/Relevant';
import FilterTypeNav from '../../components/FilterTypeNav';
import PropsList from '../../components/PropsList';
import SectsList from '../../components/SectsList';
import SelectProduct from '../../components/SelectProduct';
import PxyEntry from '../../components/PxyEntry';
import useGiveTicket from '../../utils/hooks/useGiveTicket';
import './Detail.scss';

function ActivityDetail(props) {
  const urlParams = useParams();
  let [id, setId] = useState(urlParams.id);
  let [data, setData] = useState({});
  const result = useGiveTicket(data);
  let [product, setProduct] = useState({});
  let [cmsBlocks, setCmsBlocks] = useState([]);
  let [relatedProps, setRelatedProps] = useState({ props: [] });
  let [propsListFilter, setPropsListFilter] = useState({});
  let [relatedSects, setRelatedSects] = useState([]);

  useEffect(() => {
    getdetail();
    api.activity.getRelatedProps({ data: { id } }).then(data => {
      data.props.forEach(item => {
        utils.formatKeyNoValue(item, '*');
      });
      setRelatedProps(data);
    });
    api.activity.getRelatedSects({ data: { id } }).then(data => {
      setRelatedSects(data);
    });
    return () => {
      seo.back();
    };
  }, [id]);
  useEffect(() => {
    if (result) {
      getdetail();
    }
  }, [result]);
  const getdetail = async () => {
    try {
      const detail = await api.activity.getDetail({ data: { id } });
      utils.formatKeyNoValue(detail.holding_tickets, '*');
      setData(detail);
      setCmsBlocks(JSON.parse(detail.field_image_text.value));
      let { seo_title, seo_description, seo_keyword } = detail;
      seo.setContent({
        title: seo_title.value,
        description: seo_description.value,
        keywords: seo_keyword.value,
      });
      const productDetail = await api.product.getDetail({ data: { id: detail?.ticket.value } });
      setProduct(productDetail);
    } catch (err) {
      setData({ notFound: true });
    }
  };
  useEffect(() => {
    setId(urlParams.id);
  }, [urlParams.id]);

  let startDatetime = useMemo(() => {
    if (!data.start_datetime) return '';
    else return moment(data.start_datetime.value * 1000).format('YYYY.MM.DD ddd HH:mm:ss');
  }, [data]);
  let endDatetime = useMemo(() => {
    if (!data.end_datetime) return '';
    else return moment(data.end_datetime.value * 1000).format('YYYY.MM.DD ddd HH:mm:ss');
  }, [data]);
  let showTimeDuration = useMemo(() => {
    if (!data.start_datetime || !data.end_datetime) return '';
    else {
      let start = moment(data.start_datetime.value * 1000);
      let end = moment(data.end_datetime.value * 1000);
      return end.diff(start, 'minutes') + '分钟';
    }
  }, [data]);
  let entryAndExittime = useMemo(() => {
    if (!data.entry_time || !data.exit_time) return '';
    else {
      let entry = moment(data.entry_time.value * 1000).format('HH:mm');
      let exit = moment(data.exit_time.value * 1000).format('HH:mm');
      return entry + ' / ' + exit;
    }
  }, [data]);

  const selectProduct = useRef();
  const holdingTickets = data.holding_tickets;
  const productVariations = product?.variations || [];
  // 服务端默认读取最后一个规格
  const lastVariation = productVariations[productVariations.length - 1] || {};
  return data.notFound ? (
    <NotFound title="没有找到活动" />
  ) : (
    <PageContent loaded={data.id}>
      <Row className="baseinfo-row">
        <Col lg={5}>
          <div className="baseinfo-row__poster">
            <ClipImage className="ratio ratio-3x4" noStyle>
              <OssImg src={data?.key_visual?.value} optionsName="lg" />
            </ClipImage>
          </div>
        </Col>
        <Col lg={{ span: 4, offset: 1 }}>
          <div className="baseinfo-row__info">
            <h3>{data?.title?.value}</h3>
            <table className="table--info">
              <tbody>
                <tr>
                  <th>参演信息：</th>
                  <td dangerouslySetInnerHTML={{ __html: data?.information?.value }}></td>
                </tr>
                <tr>
                  <th>演出平台：</th>
                  <td dangerouslySetInnerHTML={{ __html: data?.yanchupingtai?.value }}></td>
                </tr>
                <tr>
                  <th>演出开始时间：</th>
                  <td>{startDatetime}</td>
                </tr>
                <tr>
                  <th>演出结束时间：</th>
                  <td>{endDatetime}</td>
                </tr>
                <tr>
                  <th>演出时长：</th>
                  <td>{showTimeDuration}</td>
                </tr>
                <tr>
                  <th>进 / 退场时间：</th>
                  <td>{entryAndExittime}</td>
                </tr>
              </tbody>
            </table>
            {/*{data?.ticket?.value && (*/}
            {/*  <>*/}
            {/*    <Button*/}
            {/*      className="mt-7 mt-lg-5"*/}
            {/*      size="lg"*/}
            {/*      onClick={() => {*/}
            {/*        selectProduct.current.show();*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      购票*/}
            {/*    </Button>*/}
            {/*    <SelectProduct ref={selectProduct} product={product} />*/}
            {/*  </>*/}
            {/*)}*/}
            {data.holding_tickets ? (
              <div className="mt-7">
                <PxyEntry
                  btnVariant="primary"
                  btnClassName="w-100"
                  id={holdingTickets.id}
                  now={holdingTickets.current_time}
                  entryTime={holdingTickets.entry_time}
                  endTime={holdingTickets.end_time}
                  renderType={lastVariation.render_type}
                  appliId={lastVariation.appli_id}
                  serverAddress={lastVariation.queue_server_address}
                  renderAddress={lastVariation.render_server_address}
                />
              </div>
            ) : (
              <>
                <Button
                  className="mt-7 mt-lg-5"
                  size="lg"
                  onClick={() => {
                    selectProduct.current.show();
                  }}
                >
                  购票
                </Button>
                <SelectProduct ref={selectProduct} product={product} />
              </>
            )}
          </div>
        </Col>
      </Row>
      {!!cmsBlocks?.length && (
        <>
          <Dots />
          <Cms col={{ lg: { span: 8, offset: 2 } }} blocks={cmsBlocks} />
        </>
      )}
      <Relevant show={relatedProps.props.length || relatedSects.length}>
        <Relevant.Section show={relatedProps.props.length}>
          <Relevant.SectionHeader title="相关卡牌" />
          <Relevant.SectionBody>
            <FilterTypeNav nav={relatedProps.prop_types} setFilter={setPropsListFilter} />
            <PropsList list={relatedProps.props} filter={propsListFilter} />
          </Relevant.SectionBody>
        </Relevant.Section>
        <Relevant.Section show={relatedSects.length}>
          <Relevant.SectionHeader title="相关门派" />
          <Relevant.SectionBody>
            <SectsList list={relatedSects} />
          </Relevant.SectionBody>
        </Relevant.Section>
      </Relevant>
    </PageContent>
  );
}

export default memo(ActivityDetail);
