import { memo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import api from '../../utils/request';
import PageContent from '../../components/PageContent';
import ActivityListCom from '../../components/ActivityListCom';
import utils from '../../utils';

function ActivityList(props) {
  let [page, setPage] = useState(0);
  let [year, setYear] = useState();
  let [data, setData] = useState({});
  let [isMore, setIsMore] = useState(true);
  let [activityList, setList] = useState({});
  let [yearList, setYearFilter] = useState({});

  useEffect(() => {
    api.activity
      .getList({ data: { page, year } })
      .then(listObj => {
        if (listObj.years && !Array.isArray(listObj.years)) {
          listObj.years = Object.keys(listObj.years).map(key => listObj.years[key]);
        }
        console.log(listObj.years);
        setData(listObj);
        setIsMore(isMore => {
          const result =
            listObj.pager.total_pages - 1 === listObj.pager.current_page ? false : true;
          return result;
        });
        setList(activityList => {
          const newList = page === 0 ? listObj.rows : activityList.concat(listObj.rows);
          return newList;
        });
      })
      .catch(err => {
        setData({ notFound: true });
      });
    return () => {
      console.log('api.activity.getList return');
    };
  }, [page, year]);

  const loadMore = () => {
    if (isMore) {
      setPage(page + 1);
    }
  };

  const switchYear = key => {
    let years = data.years;
    let val = years[key];
    setYear(val);
  };

  return (
    <PageContent loaded={data.years}>
      {/* <h2 className="text-center my-4">活动列表</h2>
      <hr /> */}
      <div className="mb-15">
        <div className="mt-5">
          <Nav className="mb-0" variant="pills" defaultActiveKey="" onSelect={switchYear}>
            <Nav.Link eventKey="">全部</Nav.Link>
            {data.years &&
              (data.years || []).map((item, index) => (
                <Nav.Link key={index} eventKey={index}>
                  {item}
                </Nav.Link>
              ))}
          </Nav>
        </div>
        {activityList.length && <ActivityListCom list={activityList}></ActivityListCom>}
        {isMore && (
          <Row>
            <Col lg={3}>
              <div className="d-grid mt-10">
                <Button onClick={loadMore} variant="primary">
                  加载更多
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </PageContent>
  );
}

export default memo(ActivityList);
