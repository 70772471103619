import axios from 'axios';
import { baseURL, apiConfig } from '../_config/api';
import utils from './index';

const apiURL = (surfix = '') => baseURL.origin + baseURL.lang + baseURL.prefix + surfix;

const api = {};
for (let module in apiConfig) {
  api[module] = {};
  for (let name in apiConfig[module]) {
    api[module][name] = (params = {}) => {
      let config = apiConfig[module][name];
      if (!config.method) config.method = 'get';
      return request(Object.assign(params, { ...config }));
    };
  }
}

const instance = axios.create({
  baseURL: apiURL(),
  timeout: 4000,
  // withCredentials: true,
});

const request = params => {
  let { before, after, success } = params.on ?? {};
  if (params.on) delete params.on;
  if (typeof before == 'function') before();
  if (params.method === 'get' && params.data) {
    let queryParams = utils.queryParams(params.data);
    params.url += queryParams;
    delete params.data;
  } else if (params.method === 'post' && params.data) {
    if (!(params.data instanceof FormData)) {
      params.data = utils.data2FormData(params.data);
    }
  }
  return new Promise((resolve, reject) => {
    instance({
      ...params,
    })
      .then(res => {
        if (res.data.code >= 200 && res.data.code <= 299) {
          res.data.data ? resolve(res.data.data) : resolve(res.data);
          if (typeof success == 'function') success();
        } else {
          reject(res.data);
        }
        if (typeof after == 'function') after();
      })
      .catch(err => {
        console.log(err.message);
        if (err.message.includes('404')) err.message = '请求失败，状态代码404';
        reject(err);
        if (typeof after == 'function') after();
      });
  });
};

export default api;
