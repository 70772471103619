import RichText from './RichText';
import OssImg from '../OssImg';
import './ImageTextMixed.scss';

function ImageTextMixed(props) {
  return (
    <>
      {props.title !== '' && <h4>{props.title}</h4>}
      {props.contents.map((content, index) => (
        <div className="cms-item cms-item-mixed" key={index}>
          <div
            className={`pull-media pull-media--${props.imageOn} pull-media--${content.data.field_image_size}`}
          >
            <figure className="media-image">
              <OssImg src={content.data.field_image} optionsName="md" />
            </figure>
          </div>
          <RichText content={content.data.field_ckeditor} />
        </div>
      ))}
    </>
  );
}

export default ImageTextMixed;
