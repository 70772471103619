import { memo } from 'react';
import styled from 'styled-components';
import './Dots.scss';

const Div = styled.div`
  color: ${props => props.color};
`;

const Dots = memo(props => (
  <Div className="dots container-xxl" {...props}>
    <span className="dot dot--left"></span>
    <span className="dot dot--right"></span>
  </Div>
));

export default Dots;
