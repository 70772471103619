import { memo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import ClipImage from './ClipImage';
import OssImg from './OssImg';
import ListItemText from './ListItemText';
import LogoList from './LogoList';
import utils from '../utils';
import './SectMainList.scss';

const SectMainList = memo(props => {
  return (
    <Row className="sect-list">
      {props.list.map(item => {
        utils.formatKeyNoValue(item, '*');
        const { id } = item;
        return <SectMainListItem key={id} {...item}></SectMainListItem>;
      })}
    </Row>
  );
});

function SectMainListItem(props) {
  utils.formatKeyNoValue(props, '*');
  const nav = useNavigate();
  const { id, title, description, logo, architecture, key_visual } = props;
  return (
    <Col md={6} className="sect-list-item">
      <Row className="baseinfo-row">
        <Col lg={6} className="sect-list-item__kv">
          <div className="baseinfo-row__poster">
            <ClipImage noStyle onClick={() => nav('/sect/' + id)}>
              <OssImg src={key_visual} optionsName="md" options={{ format: 'png' }} />
            </ClipImage>
          </div>
        </Col>
        <Col lg={6}>
          <div className="sect-list-item__info">
            <div className="top">
              <ListItemText {...{ title, description, linkTo: '/sect/' + id }} />
            </div>
            <div className="bottom">
              <LogoList logo={logo} architecture={architecture}></LogoList>
            </div>
          </div>
        </Col>
      </Row>
    </Col>
  );
}

export default SectMainList;
