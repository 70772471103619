import { memo, useState, useRef } from 'react';
import Icon from './Icon';
import './ClipVideo.scss';

const ClipVideo = memo(({ src }) => {
  const [isPlay, setIsPlay] = useState(false);
  const videoRef = useRef();

  function handleClick() {
    videoRef?.current?.play();
  }

  return (
    <div
      onClick={handleClick}
      className={`clip-video ${isPlay ? 'clip-video--playing' : 'clip-video--pause'}`}
    >
      <Icon icon={isPlay ? 'pause' : 'play'} fontSize="48px" />
      <video
        src={src}
        controls={isPlay}
        ref={videoRef}
        onPlaying={e => {
          setIsPlay(true);
        }}
        onPause={e => {
          setIsPlay(false);
        }}
        onContextMenu={e => e.preventDefault()}
      ></video>
    </div>
  );
});

export default ClipVideo;
