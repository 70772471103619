import { memo, useState, createRef } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Row, Col, Button } from 'react-bootstrap';
import api from '../utils/request';
import alert from './alert';
import InvalidFeedback from './form/InvalidFeedback';
import LoadButton from './LoadButton';
import Cascader from './Cascader';

function formatDefaultValues(defaultValues) {
  let dv = defaultValues;
  if (dv && dv.id) {
    let locationGeo = {};
    for (let i = 1; i < 5; i++) {
      if (dv[`location_geo_level_${i}`])
        locationGeo[`location_geo_level_${i}`] = dv[`location_geo_level_${i}`];
    }
    let { locationGeoText, locationGeoTextIds } = formatLocationGeo(locationGeo);
    return {
      ...dv,
      ...locationGeoTextIds,
      _location_geo: locationGeoText.join(' '),
      cascaderDefaultValues:
        Object.values(locationGeo).map(item => {
          return { tid: item.id, label: item.label };
        }) || [],
    };
  }
}

function formatLocationGeo(locationGeo) {
  let locationGeoTextIds = {};
  let locationGeoText = [
    ...new Set(
      Object.entries(locationGeo).map(([key, val]) => {
        locationGeoTextIds[key] = val.id;
        return val.label;
      }),
    ),
  ];
  return { locationGeoText, locationGeoTextIds };
}

function AddressForm({ formType = 'add', onSubmitAfter, onCancel, defaultValues = {} }) {
  defaultValues = formatDefaultValues(defaultValues);
  let [cascaderDefaultValues] = useState(
    formType === 'add' ? [] : defaultValues.cascaderDefaultValues,
  );
  let [submitLoading, setSubmitLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
  });
  let cascaderRef = createRef();

  const cNameIsInvalid = key => (errors[key] ? 'is-invalid' : '');

  function cascaderInputOnFocus() {
    cascaderRef?.current.show();
  }

  function handleCascaderSelectEnd(vals) {
    const texts = [...new Set(vals.map(v => v.label))].join(' ');
    const tids = vals.map(v => v.tid);
    tids.forEach((tid, i) => {
      setValue('location_geo_level_' + ++i, tid);
    });
    setValue('_location_geo', texts);
    trigger('_location_geo', { shouldFocus: true });
  }

  function onSubmit(data) {
    if (data._location_geo) delete data._location_geo;
    if (data.locationGeo) delete data.locationGeo;
    if (data.is_default !== undefined) {
      data.is_default = Number(data.is_default);
    }
    setSubmitLoading(true);
    api.addressBook[formType]({ data })
      .then(data => {
        setSubmitLoading(false);
        alert.success(formType === 'add' ? '新增收件地址成功' : '收件地址更新成功');
        onSubmitAfter && onSubmitAfter();
      })
      .catch(err => {
        requestError(err);
        setSubmitLoading(false);
      });
  }

  function requestError(error) {
    if (error.data) {
      error.data.error_keys.forEach(key => {
        setError(key);
        alert.danger(error.message, key);
      });
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className={submitLoading ? 'pe-none' : ''}>
      <div className="d-grid gap-5">
        <Form.Floating>
          <Form.Control
            {...register('to_name', { required: '请填写收件人' })}
            className={cNameIsInvalid('to_name')}
            placeholder=" "
          />
          <Form.Label>收件人</Form.Label>
          <InvalidFeedback message={errors?.to_name?.message} />
        </Form.Floating>
        <Form.Floating>
          <Form.Control
            {...register('mobile', {
              required: '请填写手机号',
              pattern: {
                value: /^1[3456789]\d{9}$/,
                message: '手机号错误',
              },
            })}
            className={cNameIsInvalid('mobile')}
            placeholder=" "
          />
          <Form.Label>手机号</Form.Label>
          <InvalidFeedback message={errors?.mobile?.message} />
        </Form.Floating>
        <Form.Floating className="form-floating--geo-select">
          <input
            onFocus={cascaderInputOnFocus}
            style={{
              position: 'absolute',
              opacity: 0,
              pointerEvents: 'none',
            }}
          />
          <Cascader
            onRef={cascaderRef}
            onSelectEnd={handleCascaderSelectEnd}
            defaultValue={cascaderDefaultValues}
          />
          <Form.Control
            {...register('_location_geo', { required: '请选择所在地区' })}
            className={cNameIsInvalid('location_geo') + ' pe-none'}
            readOnly
            placeholder=" "
            tabIndex="-1"
          />
          <Form.Label>所在地区</Form.Label>
          <InvalidFeedback message={errors?._location_geo?.message} />
          <input type="hidden" {...register('location_geo_level_1', { required: true })} />
          <input type="hidden" {...register('location_geo_level_2', { required: true })} />
          <input type="hidden" {...register('location_geo_level_3', { required: true })} />
          <input type="hidden" {...register('location_geo_level_4', { required: true })} />
        </Form.Floating>
        <Form.Floating>
          <Form.Control
            {...register('location_address', { required: '请填写详细地址' })}
            className={cNameIsInvalid('location_address')}
            placeholder=" "
          />
          <Form.Label>详细地址</Form.Label>
          <InvalidFeedback message={errors?.location_address?.message} />
        </Form.Floating>
      </div>
      <Form.Check
        {...register('is_default', {
          disabled: formType === 'add' ? false : !!defaultValues.is_default,
        })}
        className="my-10"
        type="checkbox"
        id="setDefault"
        label="设为默认地址"
      />
      <Row>
        <Col sm={6}>
          <div className="d-grid gap-4">
            <LoadButton loading={submitLoading} size="lg" type="submit">
              保存
            </LoadButton>
            <Button
              variant="outline-primary"
              size="lg"
              onClick={() => {
                onCancel && onCancel();
              }}
            >
              取消
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default memo(AddressForm);
