import { memo, useState, useEffect } from 'react';
import utils from '../../utils';
import api from '../../utils/request';
import { Row, Col } from 'react-bootstrap';
import PageContent from '../../components/PageContent';
import FilterTypeNav from '../../components/FilterTypeNav';
import InfiniteScroll from 'react-infinite-scroller';
import PropsList from '../../components/PropsList';

function PropList() {
  let [page, setPage] = useState(0);
  let [prop_type, setCategory] = useState('');
  let [data, setData] = useState({});
  let [isMore, setIsMore] = useState(true);
  let [prop_list, setList] = useState({});
  let [propsListFilter, setPropsListFilter] = useState({});

  useEffect(() => {
    api.props
      .getList({ data: { prop_type, category: 'market', page } })
      .then(detail => {
        detail.rows.forEach(item => {
          utils.formatKeyNoValue(item, '*');
        });
        setData(detail);
        setIsMore(isMore => {
          const result = detail.rows.length ? true : false;
          return result;
        });
        setList(prop_list => {
          const newList = page === 0 ? detail.rows : prop_list.concat(detail.rows);
          return newList;
        });
      })
      .catch(err => {
        setData({ notFound: true });
      });
  }, [prop_type, page]);

  const switchCategory = category => {
    category === '*' ? (category = '') : (category = category);
    setPage(0);
    setCategory(category);
  };

  const loadFunc = () => {
    if (isMore) {
      setPage(page + 1);
      setIsMore(false);
    }
  };

  return (
    <PageContent loaded={data.rows}>
      <div className="py-5">
        <FilterTypeNav
          className="mb-0 nav-has-border"
          nav={data.prop_types}
          switchCategory={switchCategory}
          setFilter={setPropsListFilter}
        />
      </div>
      <InfiniteScroll
        pageStart={0}
        loadMore={loadFunc}
        hasMore={isMore}
        loader={
          <div className="loader" key={0}>
            Loading ...
          </div>
        }
      >
        <PropsList list={prop_list} filter={propsListFilter} />
      </InfiniteScroll>
    </PageContent>
  );
}

export default memo(PropList);
