import Dots from './Dots';
import './Relevant.scss';

function Relevant(props) {
  if (props.show) return <div className="relevant-sections">{props.children}</div>;
}

function Section(props) {
  if (props.show)
    return (
      <>
        <Dots />
        <section className="relevant-section">{props.children}</section>
      </>
    );
}

function SectionHeader(props) {
  return (
    <header className="relevant-section-header">{props.title && <h4>{props.title}</h4>}</header>
  );
}

function SectionBody(props) {
  return <div className="relevant-section-body">{props.children}</div>;
}

export default Object.assign(Relevant, { Section, SectionHeader, SectionBody });
