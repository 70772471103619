import React, { forwardRef, useState, useEffect, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Offcanvas } from 'react-bootstrap';
import api from '../utils/request';
import alert from './alert';
import LoadButton from './LoadButton';
import Badge from './Badge';

function Products({ list, selectId, setSelectId }) {
  function handleChange({ target: { value } }) {
    setSelectId(value);
  }

  return (
    <div className="select-product d-grid">
      {list.map(item => {
        let disabled = !item.available;
        let onlyCode = item.must_promotion_used;
        return (
          <Form.Check
            disabled={disabled}
            className="form-check--label-flex"
            onChange={handleChange}
            name="variation"
            type="radio"
            id={item.id}
            value={onlyCode ? 'must_promotion_used' : item.id}
            label={
              <>
                <>
                  <span>
                    {item.label}{' '}
                    {disabled ? (
                      <Badge color="black" text="停售" />
                    ) : onlyCode ? (
                      <Badge color="black" text="仅兑换" />
                    ) : (
                      ''
                    )}
                  </span>
                  {!onlyCode && <span>¥{item.price.number}</span>}
                </>
              </>
            }
            key={item.id}
            defaultChecked={selectId === item.id}
          />
        );
      })}
    </div>
  );
}

const SelectProduct = forwardRef(
  (
    {
      product,
      placement = 'end',
      headerTitle = '购票',
      bodyTitle = '购票说明',
      productsTitle = '选择票种',
    },
    ref,
  ) => {
    let [show, setShow] = useState(false);
    let [submitLoading, setSubmitLoading] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const nav = useNavigate();

    useImperativeHandle(ref, () => {
      return {
        close: handleClose,
        show: handleShow,
      };
    });
    const [selectId, setSelectId] = useState('');
    const [onlyCodeId, setOnlyCodeId] = useState('');
    useEffect(() => {
      fetchProductInfo();
    }, [product]);

    const fetchProductInfo = productId => {
      if (product?.variations?.length > 0) {
        const findItem = product.variations.find(item => item.available);
        if (findItem) {
          const { id, must_promotion_used } = findItem;
          setSelectId(id ?? undefined);
          setOnlyCodeId(must_promotion_used ?? undefined);
        }
      }
    };

    function submit() {
      if (selectId === 'must_promotion_used' || selectId === onlyCodeId) {
        nav('/user/ecode');
      } else {
        setSubmitLoading(true);
        api.order
          .add({
            data: {
              pv_id: selectId,
            },
          })
          .then(data => {
            setSubmitLoading(false);
            nav('/buy/checkout', {
              state: data,
            });
          })
          .catch(err => {
            setSubmitLoading(false);
            alert.danger(err.message, 'api.order.add' + selectId);
          });
      }
    }

    if (product.id) {
      return (
        <Offcanvas show={show} onHide={handleClose} placement={placement}>
          <Offcanvas.Header closeButton>
            <div className="offcanvas-title h4">{headerTitle}</div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <h5>{product.label || bodyTitle}</h5>
            <div dangerouslySetInnerHTML={{ __html: product.body }} />
            <hr />
            <h5>{productsTitle}</h5>
            {product.variations?.length ? (
              <Products list={product.variations} selectId={selectId} setSelectId={setSelectId} />
            ) : (
              '暂无可售商品'
            )}
          </Offcanvas.Body>
          {product.variations?.length > 0 && (
            <div className="offcanvas__buttons d-grid">
              <LoadButton loginRequired={true} loading={submitLoading} size="lg" onClick={submit}>
                确定
              </LoadButton>
            </div>
          )}
        </Offcanvas>
      );
    }
  },
);

export default SelectProduct;
