import { memo, useState, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate, useNavigationType } from 'react-router';
import { Row, Col, Form, Button, InputGroup, Ratio, Modal } from 'react-bootstrap';
import moment from 'moment';
import api from '../../utils/request';
import alert from '../../components/alert';
import PageContent from '../../components/PageContent';
import PageTitleBar from '../../components/PageTitleBar';
import LoadButton from '../../components/LoadButton';
import AngleDecoration from '../../components/AngleDecoration';
import LocationText from '../../components/LocationText';
import AddressForm from '../../components/AddressForm';
import './Checkout.scss';

const ShippingAddress = memo(({ addressBookOptions, show, submitState }) => {
  let [showAddModal, setShowAddModal] = useState(false);
  if (show) {
    // let [submitData, setSubmitData] = submitState;
    const addBtnStyle = {
      marginLeft: 'calc(0px - var(--bs-btn-padding-x))',
      marginBottom: 'calc(0px - var(--bs-btn-padding-y))',
      fontWeight: 'normal',
    };
    const handleSubmitAfter = () => {
      setShowAddModal(false);
    };

    return (
      <section>
        <h6>配送地址</h6>
        {addressBookOptions?.length && (
          <div className="card">
            <h6 className="mb-0">{addressBookOptions[0].to_name.value}</h6>
            <div>{addressBookOptions[0].mobile.value}</div>
            <LocationText {...addressBookOptions[0].location.value} />
          </div>
        )}
        <Button variant="link" style={addBtnStyle} onClick={() => setShowAddModal(true)}>
          新建地址
        </Button>
        <Modal show={showAddModal} size="lg">
          <Modal.Header>
            <Modal.Title>新建收货地址</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddressForm
              formType="add"
              onSubmitAfter={handleSubmitAfter}
              onCancel={handleSubmitAfter}
            />
          </Modal.Body>
        </Modal>
      </section>
    );
  }
});

const Discount = memo(({ cartState, submitState }) => {
  let [cartInfo, setCartInfo] = cartState;
  let [submitData, setSubmitData] = submitState;
  let [enterable, setEnterable] = useState(false);
  let [modifiable, setModifiable] = useState(true);
  let input = useRef();
  let [total_price] = useState(cartInfo.total_price);
  const buttonStyle = { width: '8rem', minWidth: '33%', paddingLeft: '0', paddingRight: '0' };

  function resetPrice() {
    setCartInfo(cartInfo => {
      delete cartInfo.promotion;
      return { ...cartInfo, total_price };
    });
    setSubmitData(submitData => {
      delete submitData.coupon_code;
      return submitData;
    });
  }

  const handleSubmit = () => {
    let coupon_code = input.current.value;
    if (coupon_code) {
      api.order
        .checkCoupon({
          data: { cart_id: submitData.cart_id, coupon_code },
        })
        .then(data => {
          setCartInfo(cartInfo => {
            return { ...cartInfo, ...data };
          });
          setSubmitData(submitData => {
            return { ...submitData, coupon_code };
          });
          setModifiable(false);
          alert.success('兑换码使用成功', 'checkCoupon');
        })
        .catch(err => {
          alert.danger(err.message, 'checkCoupon');
        });
    }
  };

  const handleChange = () => {
    resetPrice();
    setModifiable(true);
    input.current.value = '';
    input.current.focus();
  };

  return (
    <section>
      <h6>折扣</h6>
      {enterable ? (
        <InputGroup className={modifiable ? '' : 'input-group--lock'}>
          <Form.Group className="form-floating">
            <Form.Control
              type="text"
              placeholder=" "
              autoComplete="off"
              ref={input}
              // defaultValue="TEST3SvymrMM"
            />
            <Form.Label>兑换码</Form.Label>
          </Form.Group>
          {modifiable ? (
            <Button
              onClick={handleSubmit}
              style={buttonStyle}
              className="ms-3"
              variant="outline-primary"
              size="lg"
            >
              兑换
            </Button>
          ) : (
            <Button onClick={handleChange} variant="link">
              更换
            </Button>
          )}
        </InputGroup>
      ) : (
        <Button
          style={buttonStyle}
          variant="outline-primary"
          size="lg"
          onClick={() => setEnterable(true)}
        >
          折扣兑换
        </Button>
      )}
    </section>
  );
});

const PaymentMethod = memo(({ cartState, submitState }) => {
  let [cartInfo] = cartState;
  let [submitData, setSubmitData] = submitState;

  function handleChange({ target: { value } }) {
    setSubmitData(submitData => {
      return { ...submitData, payment_method: value };
    });
  }

  if (cartInfo.payment_options) {
    return (
      <section>
        <h6>支付方式</h6>
        <div className="select-payment d-grid">
          {cartInfo.payment_options.map(item => (
            <Form.Check
              className="form-check--label-flex form-check--bg"
              onChange={handleChange}
              name="variation"
              type="radio"
              id={item.key}
              value={item.key}
              label={item.label}
              key={item.key}
              defaultChecked={submitData.payment_method === item.key}
            />
          ))}
        </div>
      </section>
    );
  }
});

function Checkout(props) {
  const location = useLocation();
  const nav = useNavigate();
  const navType = useNavigationType();
  let [cartInfo, setCardInfo] = useState({});
  let [submitData, setSubmitData] = useState({});
  let [submitLoading, setSubmitLoading] = useState(false);
  const cart_id = location?.state?.order;

  useEffect(() => {
    if (cart_id) {
      api.order
        .getCartInfo({ data: { cart_id } })
        .then(data => {
          setCardInfo(data);
          let payment_method = data?.payment_options[0]?.key;
          setSubmitData({ cart_id, payment_method });
        })
        .catch(err => {
          console.log(err);
          nav(-1);
        });
    } else {
      navType === 'PUSH' ? nav(-1) : nav('/');
    }
  }, [location.state]); // eslint-disable-line react-hooks/exhaustive-deps

  function toRMB(price) {
    if (price !== undefined) {
      price = price * 1;
      return price < 0 ? `-¥${Math.abs(price)}` : `¥${price}`;
    }
  }

  function toQuantity(quantity) {
    if (quantity) {
      return '×' + quantity;
    }
  }

  const subtotal = useMemo(() => {
    if (cartInfo.items?.length) {
      return cartInfo.items.reduce((prev, cur) => prev + cur.price, 0);
    }
  }, [cartInfo.items]);

  const submitCart = function () {
    setSubmitLoading(true);
    api.order
      .submitCart({
        data: submitData,
      })
      .then(() => {
        alert.clear();
        setSubmitLoading(false);
        nav('/buy/pay', { state: { order_id: submitData.cart_id }, replace: true });
      })
      .catch(err => {
        setSubmitLoading(false);
      });
  };

  return (
    <PageContent loaded={cartInfo.items} paddingTop paddingBotton>
      <Row>
        <Col lg={8} xl={{ span: 8, offset: 2 }}>
          <PageTitleBar title="确认订单" />
        </Col>
      </Row>
      <Row>
        <Col lg={8} xl={{ span: 6, offset: 2 }}>
          <div className="d-grid gap-10">
            <ShippingAddress
              addressBookOptions={cartInfo.address_book_options}
              show={cartInfo.need_billing_info}
              submitState={[submitData, setSubmitData]}
            />
            <Discount
              cartState={[cartInfo, setCardInfo]}
              submitState={[submitData, setSubmitData]}
            />
            <PaymentMethod
              cartState={[cartInfo, setCardInfo]}
              submitState={[submitData, setSubmitData]}
            />
          </div>
        </Col>
        <Col lg={4} xl={{ span: 3, offset: 1 }}>
          <aside className="checkout-total-list">
            <h6>订单信息</h6>
            {cartInfo?.items?.map((item, index) => (
              <div className="checkout-total-list-item" key={index}>
                <div className="checkout-total-list-item__info">
                  <div className="checkout-total-list-item__info__img">
                    <AngleDecoration size="medium" />
                    <Ratio aspectRatio="3x4">
                      {item.key_visual ? <img src={item.key_visual} alt="" /> : <></>}
                    </Ratio>
                  </div>
                  <div>
                    <div>{item.label}</div>
                    {item.created && (
                      <>
                        <div>{moment(item.created * 1000).format('YYYY.MM.DD')}</div>
                        <div>{moment(item.created * 1000).format('HH:mm')}</div>
                      </>
                    )}
                  </div>
                </div>
                <div className="text-end">
                  {toRMB(item.price)} <br /> {toQuantity(item.quantity)}
                </div>
              </div>
            ))}
            <div className="checkout-total-list-item">
              <span>小计</span>
              <span>{toRMB(subtotal)}</span>
            </div>
            {cartInfo.promotion &&
              cartInfo.promotion.map((item, i1) => {
                return item.adjustments
                  ? item.adjustments.map((adjustment, i2) => {
                      return (
                        <div className="checkout-total-list-item" key={i1 * 100 + i2}>
                          <span>{adjustment.label}</span>
                          <span>{toRMB(adjustment.amount)}</span>
                        </div>
                      );
                    })
                  : '';
              })}
            <div className="checkout-total-list-item">
              <strong>总计</strong>
              <h5 className="m-0">{toRMB(cartInfo.total_price)}</h5>
            </div>
          </aside>
        </Col>
      </Row>
      <Row>
        <Col lg={8} xl={{ span: 8, offset: 2 }}>
          <Row>
            <Col className="d-grid mt-10" sm={6}>
              <LoadButton loading={submitLoading} onClick={submitCart} size="lg">
                提交订单
              </LoadButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </PageContent>
  );
}

export default memo(Checkout);
