import { memo, lazy, Suspense, useContext } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import KeepAlive from 'react-activation';
import AuthContext from '../store/AuthContext';

import Home from '../pages/Home';
import ActivityList from '../pages/activity/List';
import ActivityDetail from '../pages/activity/Detail';
import SectList from '../pages/sect/List';
import SectDetail from '../pages/sect/Detail';
import PropList from '../pages/prop/List';
import PropDetail from '../pages/prop/Detail';
import UserLayout from '../pages/user/Layout';
import Login from '../pages/user/Login';
import ForgotPassword from '../pages/user/ForgotPassword';
import Register from '../pages/user/Register';
import Warehouse from '../pages/user/Warehouse';
import OrderList from '../pages/user/OrderList';
import OrderDetail from '../pages/user/OrderDetail';
import Address from '../pages/user/address/List';
import AddAddress from '../pages/user/address/Add';
import UpdateAddress from '../pages/user/address/Update';
import ECode from '../pages/user/ECode';
import Profile from '../pages/user/Profile';
import Checkout from '../pages/buy/Checkout';
import Pay from '../pages/buy/Pay';
import Page from '../pages/Page';
import NotFound from '../pages/NotFound';
import LandingPage from '../pages/LandingPage';
const Play = lazy(() => import('../pages/Play'));

function lazyLoad(children, cacheKey) {
  if (children) {
    return cacheKey ? (
      <KeepAlive cacheKey={cacheKey} name={cacheKey}>
        {children}
      </KeepAlive>
    ) : (
      children
    );
  }
}

function AppUseRouter() {
  const { isLoggedIn } = useContext(AuthContext);
  const index = true;
  function setAllowRoute({ loggedIn = [], loggedOut = [] }) {
    return isLoggedIn ? loggedIn : loggedOut;
  }
  let element = useRoutes([
    {
      index,
      element: lazyLoad(<Home />, 'Home'),
    },
    { path: 'activitys', element: lazyLoad(<ActivityList />, 'ActivityList') },
    {
      path: 'activity',
      children: [
        { index, element: lazyLoad(<ActivityList />, 'ActivityList') },
        { path: ':id', element: lazyLoad(<ActivityDetail />) },
      ],
    },
    { path: 'sects', element: lazyLoad(<SectList />, 'SectList') },
    { path: 'landing', element: lazyLoad(<LandingPage />, 'Landing') },
    {
      path: 'sect',
      children: [
        { index, element: lazyLoad(<SectList />, 'SectList') },
        { path: ':id', element: lazyLoad(<SectDetail />) },
      ],
    },
    { path: 'props', element: lazyLoad(<PropList />, 'PropList') },
    {
      path: 'prop',
      children: [
        { index, element: lazyLoad(<PropList />, 'PropList') },
        { path: ':id', element: lazyLoad(<PropDetail />) },
      ],
    },
    {
      path: 'page/:id',
      element: <Page />,
    },
    {
      path: 'buy',
      children: setAllowRoute({
        loggedOut: [{ path: '*', element: <Navigate to="/user/login" /> }],
        loggedIn: [
          { path: 'checkout', element: <Checkout /> },
          { path: 'pay', element: <Pay /> },
          { path: 'pay/:orderId', element: <Pay /> },
        ],
      }),
    },
    {
      path: 'user',
      element: lazyLoad(<UserLayout />),
      children: setAllowRoute({
        loggedOut: [
          { index, element: <Navigate to="login" replace={true} /> },
          { path: 'login', element: lazyLoad(<Login />) },
          { path: 'register', element: lazyLoad(<Register />) },
          { path: 'forgot-password', element: lazyLoad(<ForgotPassword />) },
          { path: '*', element: <Navigate to="login" replace={true} /> },
        ],
        loggedIn: [
          { index, element: <Navigate to="warehouse" replace={true} /> },
          { path: 'warehouse', element: <Warehouse /> },
          { path: 'orders', element: <OrderList /> },
          { path: 'order/:id', element: <OrderDetail /> },
          {
            path: 'address',
            children: [
              { index, element: lazyLoad(<Address />, 'Address') },
              { path: 'add', element: <AddAddress /> },
              { path: ':id', element: <UpdateAddress /> },
            ],
          },
          { path: 'profile', element: <Profile /> },
          { path: 'ecode', element: <ECode /> },
          { path: '*', element: <Navigate to="warehouse" replace={true} /> },
        ],
      }),
    },
    {
      path: 'play',
      children: setAllowRoute({
        loggedOut: [{ path: '*', element: <Navigate to="/user/login" /> }],
        loggedIn: [
          {
            path: '',
            element: (
              <Suspense fallback={<div>Loading...</div>}>
                <Play />
              </Suspense>
            ),
          },
        ],
      }),
    },
    { path: '*', element: lazyLoad(<NotFound />) },
  ]);

  return element;
}

export default memo(AppUseRouter);
