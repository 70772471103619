import { useState } from 'react';
import ReactDOM from 'react-dom/client';
import styled from 'styled-components';
import Alert from 'react-bootstrap/Alert';
import Icon from './Icon';
import IconButton from './IconButton';
import utils from '../utils';

let alert = {};
const alerts = document.createElement('div');
document.body.appendChild(alerts);
const alertsRoot = ReactDOM.createRoot(alerts);

alertsRoot.render(<Alerts />);

const AlertsContainer = styled.div`
  position: fixed;
  top: var(--app-header-height);
  left: 0;
  right: 0;
  z-index: 1200;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8px;
  pointer-events: none;
  .alert {
    pointer-events: auto;
  }
`;

function Alerts() {
  let [messages, setMessages] = useState([]);
  alert.messages = messages;
  alert.setMessages = setMessages;

  return (
    <AlertsContainer className="container-xxl">
      {messages.map(item => (
        <Alert className={item.className ?? ''} variant={item.type} key={item.uuid}>
          <div className="d-flex">
            {item.icon && <Icon icon={item.icon} />}
            <div>{item.content}</div>
            <IconButton
              icon="close"
              onClick={() => {
                alert.remove(item.uuid);
              }}
            />
          </div>
        </Alert>
      ))}
    </AlertsContainer>
  );
}

alert.add = args => {
  if (args.content && args.type) {
    if (args.duration) {
      args.timer = setTimeout(() => {
        alert.remove(args.uuid);
      }, args.duration * 1000);
    }
    alert.setMessages(arr => [...arr, args]);
  }
};

alert.remove = uuid => {
  if (alert.messages.length === 0) return;
  let index = alert.messages.findIndex(item => item.uuid === uuid);
  if (index >= 0) {
    clearInterval(alert.messages[index].timer);
    let timer = setTimeout(() => {
      alert.messages.splice(
        alert.messages.findIndex(item => item.uuid === uuid),
        1,
      );
      alert.setMessages([...alert.messages]);
    }, 200);
    alert.update(
      {
        className: 'alert--hide',
        timer,
      },
      index,
    );
  }
};

alert.update = (data, updateIndex) => {
  let updateItem = alert.messages[updateIndex];
  if (updateItem.content !== data.content) {
    clearInterval(updateItem.timer);
    alert.messages[updateIndex] = { ...updateItem, ...data };
    if (updateItem.duration) {
      alert.messages[updateIndex].timer = setTimeout(() => {
        alert.remove(updateItem.uuid);
      }, updateItem.duration * 1000);
    }
    alert.setMessages(arr => [...arr]);
  } else if (!data.content) {
    alert.messages[updateIndex] = { ...updateItem, ...data };
    alert.setMessages(arr => [...arr]);
  }
};

let api = {
  clear() {
    alert.setMessages([]);
  },
};

let typeIcon = {
  info: 'info',
  success: 'success',
  warn: 'alert',
  danger: 'error',
};

Object.entries(typeIcon).forEach(([type, icon]) => {
  api[type] = (content, uuid, duration = 4) => {
    if (!uuid) uuid = utils.uuid();
    let updateIndex = alert.messages.findIndex(item => item.uuid === uuid);
    if (updateIndex >= 0) {
      alert.update({ content, type, icon }, updateIndex);
    } else {
      alert.add({ uuid, content, type, icon, duration });
    }
  };
});

api.remove = alert.remove;

export default api;
