import utils from '../utils';
import AngleDecoration from './AngleDecoration';
import './ImageCode.scss';

function ImageCode({
  className,
  type,
  src,
  asImg,
  isInvalid = false,
  invalidHtml = '二维码失效',
  invalidOnClick,
}) {
  let outClassName = '';
  if (className) outClassName = ` ${className}`;

  return (
    <div className={utils.cssBEM('image-code', [type]) + outClassName}>
      <AngleDecoration />
      {asImg ? asImg : src && <img src={src} alt="" />}
      {isInvalid && (
        <div
          onClick={invalidOnClick}
          className="image-code__invalid"
          dangerouslySetInnerHTML={{ __html: invalidHtml }}
        ></div>
      )}
    </div>
  );
}

export default ImageCode;
