import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AuthContext from './AuthContext';
import api from '../utils/request';
import alert from '../components/alert';

const isWechat = () =>
  String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) === 'micromessenger';

const WechatContext = React.createContext({
  isWechat: isWechat(),
});

const WechatContextProvider = ({ wxOpenPlatformAppId, wxOfficialAccountAppId, children }) => {
  const [urlSearchParams, setSearchParams] = useSearchParams();
  const [wxOpenId, setWxOpenId] = useState(localStorage.getItem('wxOpenId') || '');
  const [wxUnionId, setWxUnionId] = useState(localStorage.getItem('wxUnionId') || '');
  const authCtx = useContext(AuthContext);
  const nav = useNavigate();

  const handleWxAuthorizeCode = (opt = { refetch: false }) => {
    const code = urlSearchParams.get('code');
    if (!code) return;
    if (isWechat()) {
      if (wxUnionId && opt.refetch === false) {
        wxUnionIdLogin(wxUnionId);
      } else {
        // 微信端，未缓存openid和unionid则请求获取
        api.wechat
          .getUserInfo({ data: { code } })
          .then(data => {
            const { openid, unionid } = data;
            if (openid) {
              localStorage.setItem('wxOpenId', openid);
              setWxOpenId(openid);
            }
            if (unionid) {
              localStorage.setItem('wxUnionId', unionid);
              setWxUnionId(unionid);
              wxUnionIdLogin(unionid);
            }
            authCtx.facthLoginInfo();
            setSearchParams({}); //清除浏览器地址栏上的code
          })
          .catch(err => {});
      }
    } else {
      // 非微信端，请求checkLoginOpenPlatform
      api.wechat
        .checkLoginOpenPlatform({ data: { code } })
        .then(data => {
          setSearchParams({}); //清除浏览器地址栏上的code
          authCtx.facthLoginInfo();
        })
        .catch(err => {
          setSearchParams({}); //清除浏览器地址栏上的code
          nav('/user/login');
          alert.danger('当前微信并没有绑定任何账户，注册或登录后在个人信息中选择绑定微信');
        });
    }
  };

  const wxUnionIdLogin = (union_id = wxUnionId) => {
    return api.wechat
      .checkLogin({ data: { union_id } })
      .then(() => {
        authCtx.facthLoginInfo();
      })
      .catch(err => {
        console.log({ err });
        nav('/user/login');
        alert.danger('当前微信并没有绑定任何账户，注册或登录后在个人信息中选择绑定微信');
        return Promise.reject(err);
      });
  };

  // const getCode = (appId, opt = { replace: false }) => {
  //   const code = urlSearchParams.get('code');
  //   const local = window.location.href;
  //   if ((code == null || code === '') && isWechat()) {
  //     // 微信端，无code静默授权拿code
  //     let newHref = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(
  //       local,
  //     )}&response_type=code&scope=snsapi_userinfo&state=#wechat_redirect`;
  //     window.location.href = newHref;
  //   } else if (code) {
  //     // 有code;
  //     if (isWechat()) {
  //       // 微信端，未缓存openid则请求获取openid
  //       // if (wxOpenId && opt.replace === false) return;
  //       api.wechat.getUserInfo({ data: { code } }).then(data => {
  //         localStorage.setItem('wxOpenId', data.openid);
  //         setWxOpenId(data.openid);
  //       });
  //     } else {
  //       // 非微信端，请求checkLoginOpenPlatform
  //       api.wechat
  //         .checkLoginOpenPlatform({ data: { code } })
  //         .then(data => {
  //           console.log(data);
  //           setSearchParams({}); //清除浏览器地址栏上的code
  //           authCtx.facthLoginInfo();
  //         })
  //         .catch(err => {});
  //     }
  //   }
  // };

  useEffect(() => {
    // wxOfficialAccountAppId && getCode(wxOfficialAccountAppId);
    handleWxAuthorizeCode();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const contextValue = {
    isWechat: isWechat(),
    wxOpenPlatformAppId,
    wxOfficialAccountAppId,
    wxOpenId,
    wxUnionId,
    handleWxAuthorizeCode,
    wxUnionIdLogin,
  };

  return <WechatContext.Provider value={contextValue}>{children}</WechatContext.Provider>;
};

export default WechatContext;
export { WechatContextProvider };
