import { memo, useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form, Row, Col } from 'react-bootstrap';
import api from '../../utils/request';
import AuthContext from '../../store/AuthContext';
import alert from '../../components/alert';
import PageTitleBar from '../../components/PageTitleBar';
import InputTogglePassword from '../../components/form/InputTogglePassword';
import InputMessageCode from '../../components/form/InputMessageCode';
import LoadButton from '../../components/LoadButton';
import InvalidFeedback from '../../components/form/InvalidFeedback';

function UserRegister() {
  const location = useLocation();
  const nav = useNavigate();
  const navType = useNavigationType();
  const authCtx = useContext(AuthContext);
  let [submitLoading, setSubmitLoading] = useState(false);
  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    setFocus,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (location?.state?.phoneNumber) setValue('user_login_id', location.state.phoneNumber);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getSmsCode() {
    const phoneNumberIsValid = await trigger('user_login_id', { shouldFocus: true });
    if (phoneNumberIsValid) {
      const phoneNumber = getValues('user_login_id');
      const trigger_element_id = getValues('trigger_element_id');
      const canRegister = await api.user
        .checkUnique({
          data: {
            type: 'mobile',
            user_login_id: phoneNumber,
          },
        })
        .catch(err => {
          requestError(err);
          return false;
        });
      if (canRegister) {
        const msg = await api.common
          .sendVerificationCode({
            data: {
              message_gateway_type: 'sms',
              recipient: `+86 ${phoneNumber}`,
              trigger_element_id,
            },
          })
          .catch(err => {
            return err.data;
          });
        setTimeout(() => setFocus('code'), 100);
        return msg;
      }
    }
  }

  async function onSubmit(data) {
    setSubmitLoading(true);
    await api.user
      .register({
        data,
      })
      .then(data => {
        authCtx.facthLoginInfo();
        authCtx.setIsInputAuth(true);
        setTimeout(() => alert.success(data.message), 200);
        if (navType === 'PUSH' || navType === 'REPLACE') nav(-1);
        else nav('/');
      })
      .catch(err => {
        requestError(err);
      });
    setSubmitLoading(false);
  }

  function requestError(error) {
    if (error.data) {
      error.data.error_keys.forEach(key => {
        setError(key);
        alert.danger(error.message, key);
      });
    }
  }

  const repeatPasswordValidate = v => {
    let password = getValues('password');
    return password === v || '两次密码输入不一致';
  };

  const cNameIsInvalid = key => (errors[key] ? 'is-invalid' : '');

  function toLogin(e) {
    e.preventDefault();
    const phoneNumber = getValues('user_login_id');
    nav('/user/login', { state: { phoneNumber }, replace: true });
  }

  return (
    <>
      <PageTitleBar title="注册" />
      <Form onSubmit={handleSubmit(onSubmit)} className="mb-3" autoComplete="off">
        <div className="d-grid gap-5">
          <input {...register('type', { value: 'mobile' })} type="hidden" />
          <Form.Floating>
            <Form.Control
              {...register('user_login_id', {
                required: '请填写手机号',
                pattern: {
                  value: /^1[3456789]\d{9}$/,
                  message: '手机号错误',
                },
              })}
              className={cNameIsInvalid('user_login_id')}
              type="tel"
              placeholder=" "
            />
            <Form.Label required>手机号</Form.Label>
            <InvalidFeedback message={errors?.user_login_id?.message} />
          </Form.Floating>
          <InputMessageCode
            register={register('code', { required: '请填写验证码' })}
            className={cNameIsInvalid('code')}
            label="验证码"
            buttonText="获取验证码"
            phoneNumber=""
            countDown="60"
            buttonOnClick={getSmsCode}
            required
          />
          <input
            {...register('trigger_element_id', { value: 'register_get_sms_code' })}
            type="hidden"
          />
          <InputTogglePassword
            register={register('password', {
              required: '请设置密码',
              minLength: {
                value: 8,
                message: '密码长度至少8位',
              },
              pattern: {
                value: /^(?![\d]+$)(?![a-zA-Z]+$)(?![-=+_.,#*@!]+$)[\da-zA-Z-=+_.,#*@!]{8,32}$/,
                message: '数字、字母、特殊符号至少包含两种',
              },
            })}
            className={cNameIsInvalid('password')}
            label="设置密码"
            required
          >
            <InvalidFeedback message={errors?.password?.message} />
            <Form.Text>要求长度至少8位，数字、字母、特殊符号至少包含两种</Form.Text>
          </InputTogglePassword>
          <InputTogglePassword
            register={register('repeatPassword', {
              required: '请校验重复密码',
              validate: repeatPasswordValidate,
            })}
            className={cNameIsInvalid('repeatPassword')}
            label="重复密码"
            required
          >
            <InvalidFeedback message={errors?.repeatPassword?.message} />
          </InputTogglePassword>
          <Form.Floating>
            <Form.Control
              {...register('nickname', { required: '请填写昵称', minLength: 1, maxLength: 30 })}
              className={cNameIsInvalid('nickname')}
              placeholder=" "
            />
            <Form.Label required>昵称</Form.Label>
            <InvalidFeedback message={errors?.nickname?.message} />
          </Form.Floating>
          <div className="grid gap-5" style={{ '--bs-columns': 2 }}>
            <Form.Floating>
              <Form.Control
                {...register('last_name', { minLength: 1, maxLength: 30 })}
                className={cNameIsInvalid('last_name')}
                name="last_name"
                placeholder=" "
              />
              <Form.Label>姓</Form.Label>
              <InvalidFeedback message={errors?.last_name?.message} />
            </Form.Floating>
            <Form.Floating>
              <Form.Control
                {...register('first_name', { minLength: 1, maxLength: 30 })}
                className={cNameIsInvalid('first_name')}
                name="first_name"
                placeholder=" "
              />
              <Form.Label>名</Form.Label>
              <InvalidFeedback message={errors?.first_name?.message} />
            </Form.Floating>
          </div>
          <Form.Floating>
            <Form.Control
              {...register('email', {
                pattern: {
                  value: /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/,
                  message: '邮箱格式错误',
                },
              })}
              className={cNameIsInvalid('email')}
              type="email"
              placeholder=" "
              autoComplete="on"
            />
            <Form.Label>邮箱</Form.Label>
            <InvalidFeedback message={errors?.email?.message} />
          </Form.Floating>
        </div>
        <Row className="mt-10">
          <Col md={6}>
            <div className="d-grid">
              <LoadButton loading={submitLoading} size="lg" type="submit">
                完成注册
              </LoadButton>
            </div>
          </Col>
        </Row>
      </Form>
      <div>
        我已是会员{' '}
        <Link to="/user/login" onClick={toLogin}>
          立即登录
        </Link>
      </div>
    </>
  );
}

export default memo(UserRegister);
