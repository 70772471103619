import { memo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import alert from '../components/alert';
import utils from '../utils';
import CountDown from './CountDown';

const PxyEntry = memo(
  ({
    id,
    now = Date.now(),
    entryTime,
    endTime,
    btnText = '现在入场',
    btnVariant = 'outline-primary',
    btnClassName,
    endText,
    countDownClassName = 'btn btn-link pe-none',
    renderType,
    appliId,
    serverAddress,
    renderAddress,
    ...props
  }) => {
    const nav = useNavigate();
    const toPlay = e => {
      e.preventDefault();
      if (!id || !renderType || !appliId || !serverAddress || !renderAddress) {
        alert.danger('参数错误');
        return;
      }
      // 去除协议名
      serverAddress = serverAddress.replace(/^(http|https):\/\//, '');
      renderAddress = renderAddress.replace(/^(http|https):\/\//, '');
      nav('/play', { state: { ticket_id: id, renderType, appliId, serverAddress, renderAddress } });
    };
    if (!id || !entryTime || !endTime) return;
    now = utils.transformDate(now);
    entryTime = utils.transformDate(entryTime);
    endTime = utils.transformDate(endTime);
    if (now.getTime() > endTime.getTime()) {
      // 入口关闭，当前时间超过入口结束时间
      return endText;
    } else {
      // 入口未开启或正开启
      const countDownTime = (entryTime.getTime() - now.getTime()) / 1000;

      return (
        <CountDown countDownTime={countDownTime} className={countDownClassName}>
          <Link
            to={{
              pathname: '/play',
            }}
            onClick={e => toPlay(e)}
          >
            <Button
              className="mt-7 mt-lg-5"
              size="lg"
              variant={btnVariant}
              className={btnClassName}
            >
              {btnText}
            </Button>
          </Link>
        </CountDown>
      );
    }
  },
);

export default PxyEntry;
